import CloseModalSettings, { CloseModalProps } from "./CloseModalSettings";
import LastSearchParams, { LastSearchStateProps } from "./LastSearchParams";
import ThemeOptions, { ThemeOptionsStateProps } from "./ThemeOptions";

export type StateProps = {
  ThemeOptions: ThemeOptionsStateProps;
  LastSearchParams: LastSearchStateProps;
  CloseModalSettings: CloseModalProps;
};

const reducer = {
  ThemeOptions,
  LastSearchParams,
  CloseModalSettings
};

export default reducer;
