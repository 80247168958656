import { getDashboardStatisticsListInfo } from "api/dashboard_list";
import { DashboardStatisticsData } from "models";
import queryString from "query-string";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import { setDashboardStatisticsLastSearchParams } from "reducers/LastSearchParams";
import { SelectField } from "ui/Select";
import { TableLoad } from "ui/TableLoad";
import useOrdAccountsList from "utils/useOrdAccountsList";

export const DashboardStatisticsTable = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const memoizedSearch = useMemo(
    () => queryString.parse(search) as { ord_account?: string },
    [search]
  );

  const { control, watch, setValue } = useForm({
    defaultValues: {
      ord_account: undefined as undefined | number
    }
  });

  const {
    isLoading: dashboardLoading,
    error: dashboardError,
    data: dashboardInfoList
  } = useQuery({
    queryKey: ["dashboard_statistics_list", watch("ord_account")],
    queryFn: () => getDashboardStatisticsListInfo(watch("ord_account"))
  });

  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  useEffect(() => {
    setValue("ord_account", memoizedSearch.ord_account ? +memoizedSearch.ord_account : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboardData = dashboardInfoList?.data as DashboardStatisticsData[];
  const dashboardInfo = dashboardData?.[0] || undefined;

  if (dashboardError) {
    return <div>Error</div>;
  }

  return (
    <>
      <SelectField
        name="ord_account"
        control={control}
        placeholder="Аккаунт в ОРД"
        options={ordAccountsTitlesOptions}
        disabled={ordAccountsLoading || ordAccountsError}
        onChange={(id) => {
          if (id && (typeof id === "string" || typeof id === "number")) {
            navigate(`/dashboard/statistics?ord_account=${id}`);
            dispatch(setDashboardStatisticsLastSearchParams(`?ord_account=${id}`));
          } else {
            navigate(`/dashboard/statistics`);
            dispatch(setDashboardStatisticsLastSearchParams(""));
          }
        }}
      />
      <Table hover className="mb-0">
        <thead>
          <tr>
            <th>Общее количество контрагентов</th>
            <th>Общее количество креативов</th>
            <th>Общее количество договоров</th>
            <th>Общее количество платформ</th>
            <th>Общее количество актов</th>
          </tr>
        </thead>
        <tbody>
          {dashboardLoading ? <TableLoad numberOfRows={1} /> : null}
          {dashboardInfo ? (
            <tr>
              <td>
                <a
                  href={`#/participant${
                    watch("ord_account") ? `?ord_account=${watch("ord_account")}` : ""
                  }`}
                >
                  {dashboardInfo.participant_count}
                </a>
              </td>
              <td>
                <a
                  href={`#/creative${
                    watch("ord_account") ? `?ord_account=${watch("ord_account")}` : ""
                  }`}
                >
                  {dashboardInfo.creative_count}
                </a>
              </td>
              <td>
                <a
                  href={`#/contract${
                    watch("ord_account") ? `?ord_account=${watch("ord_account")}` : ""
                  }`}
                >
                  {dashboardInfo.contract_count}
                </a>
              </td>
              <td>
                <a
                  href={`#/platform${
                    watch("ord_account") ? `?ord_account=${watch("ord_account")}` : ""
                  }`}
                >
                  {dashboardInfo.platform_count}
                </a>
              </td>
              <td>
                <a
                  href={`#/invoice${
                    watch("ord_account") ? `?ord_account=${watch("ord_account")}` : ""
                  }`}
                >
                  {dashboardInfo.invoice_count}
                </a>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  );
};
