/* eslint-disable no-unused-vars */
import { format } from "date-fns";
import syncStatusDict from "dictionaries/sync_status.json";
import { ID, SyncStatuses } from "models";
import { Badge } from "reactstrap";
import Tooltip from "ui/Tooltip";
import getTitleFromDict from "utils/getTitleFromDict";
import TStatusDisplay from "./type";

const statusBadgeColors: { [k in SyncStatuses]: string } = {
  SYNC_NOT_NEEDED: "bg-primary",
  READY_TO_SYNC: "bg-primary",
  IS_SYNCHRONIZING: "bg-info",
  SYNCHRONIZED: "bg-success",
  SYNC_ERROR: "bg-danger",
  TEMPORARY_SYNC_ERROR: "bg-warning",
  REGISTERED: "bg-success",
  PARTIALLY_REGISTERED: "bg-success"
};

export const statusBadgeText: {
  [k in SyncStatuses]: (
    _ordAccount?: string,
    _itemId?: ID,
    _error_message?: string,
    _synced_at?: string
  ) => string;
} = {
  SYNC_NOT_NEEDED: () => syncStatusDict["SYNC_NOT_NEEDED"],
  READY_TO_SYNC: () => syncStatusDict["READY_TO_SYNC"],
  IS_SYNCHRONIZING: (ordAccount) =>
    `${syncStatusDict["IS_SYNCHRONIZING"]} на аккаунт ${ordAccount}.`,
  SYNCHRONIZED: (ordAccount) => `${syncStatusDict["SYNCHRONIZED"]} на аккаунт ${ordAccount}.`,
  SYNC_ERROR: (ordAccount, itemId, error_message, synced_at) =>
    `${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }${syncStatusDict["SYNC_ERROR"]} ${itemId} в аккаунте ${ordAccount}: ${error_message}.`,
  TEMPORARY_SYNC_ERROR: (ordAccount, itemId, error_message, synced_at) =>
    `${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }${syncStatusDict["TEMPORARY_SYNC_ERROR"]} ${itemId} в аккаунте ${ordAccount}: ${error_message}.`,
  REGISTERED: (ordAccount) => `${syncStatusDict["REGISTERED"]} на аккаунт ${ordAccount}.`,
  PARTIALLY_REGISTERED: (ordAccount) =>
    `${syncStatusDict["PARTIALLY_REGISTERED"]} на аккаунт ${ordAccount}.`
};

const StatusInline = ({ data, dictionary }: TStatusDisplay) => {
  if (data?.extra_fields?.sync_status?.length) {
    const { sync_status: rowSyncStatus } = data.extra_fields;
    const { id: rowId } = data;
    return (
      <div className="d-inline-flex flex-wrap gap-1">
        {rowSyncStatus.map((syncStatus) => {
          if (
            !syncStatus ||
            !syncStatus?.status ||
            !Object.keys(statusBadgeText).includes(syncStatus.status)
          ) {
            console.log(syncStatus, syncStatus.status);
            return <></>;
          }

          const itemId = `${syncStatus.ord}-${new Date(syncStatus.synced_at).getTime()}-${rowId}`;

          return (
            <Tooltip
              key={itemId}
              id={itemId}
              maxWidth="10em"
              target={
                <Badge className={statusBadgeColors[syncStatus.status]} pill id={itemId}>
                  {syncStatus.ord.charAt(0).toUpperCase()}
                </Badge>
              }
            >
              {statusBadgeText[syncStatus.status](
                syncStatus.ord,
                rowId,
                syncStatus.error_message,
                syncStatus.synced_at
              )}
            </Tooltip>
          );
        })}
      </div>
    );
  }

  return <>{data?.status ? getTitleFromDict(data.status, dictionary) : ""}</>;
};

export default StatusInline;
