import { Contract, Creative, ID, Invoice, Participant, Platform } from "models";
import { createPortal } from "react-dom";
import { PiDotsThreeOutlineLight, PiDotsThreeOutlineVerticalLight } from "react-icons/pi";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { TListingItem } from "types/TListingItem";
import useIsOpenControl from "utils/useIsOpenControl";

export type ActionSelectorProps = {
  title: string;
  onClick?: (_id: ID, _item?: TListingItem) => void;
  calcHref?: (
    _id?: ID,
    _item?: Creative | Invoice | Participant | Contract | Platform
  ) => {
    href: string;
    download?: boolean;
  } | null;
  calcIsDisabled?: (_item: TListingItem) => boolean;
};

export const ActionSelector = ({
  actions,
  id,
  item,
  isDotsVertical
}: {
  actions: (ActionSelectorProps | null)[];
  id?: ID;
  item?: TListingItem;
  isDotsVertical?: boolean;
}) => {
  const { isOpen: dropdownOpen, toggle: dropdownOpenToggle } = useIsOpenControl();

  // const filteredActions = actions.filter((action) => action !== null) as ActionSelectorProps[];

  return (
    <td className="text-center">
      <Dropdown isOpen={dropdownOpen} toggle={dropdownOpenToggle}>
        <DropdownToggle color="" size="sm" disabled={actions.length === 0}>
          {isDotsVertical ? (
            <PiDotsThreeOutlineVerticalLight size={24} />
          ) : (
            <PiDotsThreeOutlineLight size={24} />
          )}
        </DropdownToggle>
        {createPortal(
          <DropdownMenu>
            {actions.map((action) => {
              if (!action) return null;

              const { title, onClick, calcHref, calcIsDisabled } = action;
              const isDisabled = calcIsDisabled && item && calcIsDisabled(item);

              if (calcHref) {
                const linkProps = calcHref(id);
                return (
                  <DropdownItem
                    key={title}
                    tag="a"
                    href={linkProps?.href}
                    target="_blank"
                    rel="noreferrer"
                    download={linkProps?.download}
                    disabled={!linkProps || isDisabled}
                  >
                    {title}
                  </DropdownItem>
                );
              }

              return (
                <DropdownItem
                  key={title}
                  tag="button"
                  disabled={isDisabled}
                  onClick={() => (id && onClick ? onClick(id, item) : null)}
                >
                  {title}
                </DropdownItem>
              );
            })}
          </DropdownMenu>,
          document.body
        )}
      </Dropdown>
    </td>
  );
};
