import { TPlatformFilter } from "types/TPlatformFilter";
import { TSearchParamsRequired } from "types/TSearchParams";

export const defaultSearchParams: TSearchParamsRequired<TPlatformFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: "-id",
  filter: {
    platform_type: [],
    ord_account: [],
    combined_status: [],
    owner: []
  }
};

export const defaultExportLink = "/api/v1/platform/export/xls";
