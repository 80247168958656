import Cookies from "js-cookie";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { setInvoiceLastSearchParams } from "reducers/LastSearchParams";
import { Dispatch, UnknownAction } from "redux";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getStringFromSearchParam from "utils/getStringFromSearchParam";
import { defaultSearchParams } from "../constants";

const useInvoicesSerchParams = ({
  searchProperties,
  dispatch
}: {
  searchProperties: string;
  dispatch: Dispatch<UnknownAction>;
}) => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeInvoice");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const start_date = newSearchParams.start_date
      ? getStringFromSearchParam(newSearchParams.start_date)
      : defaultSearchParams.filter?.start_date;
    const invoice_number = newSearchParams.invoice_number
      ? getStringFromSearchParam(newSearchParams.invoice_number)
      : defaultSearchParams.filter?.invoice_number;
    const end_date = newSearchParams.end_date
      ? getStringFromSearchParam(newSearchParams.end_date)
      : defaultSearchParams.filter?.end_date;
    const contract = newSearchParams.contract
      ? getNumberArrayFromSearchParam(newSearchParams.contract)
      : defaultSearchParams.filter?.contract;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        ord_account,
        combined_status,
        start_date,
        end_date,
        invoice_number,
        contract
      }
    });
    dispatch(setInvoiceLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  return searchParams;
};

export default useInvoicesSerchParams;
