import {
  IMAGE_EXTENSIONS,
  MISC_EXTENSIONS,
  SOUND_EXTENSIONS,
  VIDEO_EXTENSIONS
} from "constants/FileExtensions";
import { TFieldVariants } from "types/CreativesTypes";

export const FILE_EXTENSIONS: {
  [_: number]: string[] | undefined;
} = {
  // Иное
  1: [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS, ...SOUND_EXTENSIONS, ...MISC_EXTENSIONS],
  // Баннер
  2: IMAGE_EXTENSIONS,
  // Текстовый блок
  3: undefined,
  // Текстово-графический блок
  4: IMAGE_EXTENSIONS,
  // Видеоролик
  5: VIDEO_EXTENSIONS,
  // Аудиозапись
  6: SOUND_EXTENSIONS,
  // Аудиотрансляция в прямом эфире
  7: [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS, ...SOUND_EXTENSIONS, ...MISC_EXTENSIONS],
  // Видеотрансляция в прямом эфире
  8: [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS, ...SOUND_EXTENSIONS, ...MISC_EXTENSIONS]
};

export const DISPLAY_FIELDS: {
  [_: number]: TFieldVariants[];
} = {
  // Иное
  1: ["media_description", "text_data", "attachments"],
  // Баннер
  2: ["media_description", "creative_per_file_word", "attachments"],
  // Текстовый блок
  3: ["media_description", "text_data"],
  // Текстово-графический блок
  4: ["text_data", "attachments"],
  // Видеоролик
  5: ["media_description", "creative_per_file_word", "attachments"],
  // Аудиозапись
  6: ["media_description", "creative_per_file_word", "attachments"],
  // Аудиотрансляция в прямом эфире
  7: ["media_description", "creative_per_file_word", "attachments"],
  // Видеотрансляция в прямом эфире
  8: ["media_description", "creative_per_file_word", "attachments"]
};

export const TOKEN_VARIANTS_SUBTITLES = {
  false:
    "Вы можете выбрать и загрузить один или несколько файлов с Вашего компьютера, удерживая клавишу Ctrl (или Shift). При этом каждому загруженному файлу будет назначен один (единый) токен. Например, Вы можете загрузить баннер и пакет ресайзов для него.",
  true: "Вы можете выбрать и загрузить один или несколько файлов с Вашего компьютера, удерживая клавишу Ctrl (или Shift). При этом каждому загруженному файлу будет назначен отдельный токен. Например, Вы можете загрузить несколько разных баннеров для одного товара/услуги, каждому изображению будет присвоен свой уникальный токен."
};
