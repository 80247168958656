import { TNotVisibleFields } from "utils/onErrorMutation";

export const notVisibleFieldsInvoice: TNotVisibleFields = [
  {
    name: "allocations_file",
    title: "Загрузка разаллокации по изначальным договорам"
  },
  {
    name: "creative_statistics_file",
    title: "Загрузка статистики по креативам агентства"
  }
];
