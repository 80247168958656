import get from "lodash/get";
import { FieldErrors, FieldValues } from "react-hook-form";

type Props = {
  name: string;
  errors?: FieldErrors<FieldValues>;
  errorText?: string;
};

const FieldFeedback = ({ name, errors, errorText }: Props) => {
  if (!!errors && !!get(errors, name))
    return (
      <p className="invalid-feedback" style={{ display: "block" }}>
        {(get(errors, name)?.message as string) || errorText}
      </p>
    );

  return null;
};

export default FieldFeedback;
