import participant_roles from "dictionaries/participant_roles.json";
import participant_status from "dictionaries/participant_status.json";
import { Participant } from "models";
import { Table } from "reactstrap";
import { AttributesSyncStatus } from "ui/AttributesDropdown/helpers/syncStatus";
import getTitleFromDict from "utils/getTitleFromDict";

export const ParticipantAttributesBody = ({ data }: { data: Participant | undefined }) => {
  if (!data) {
    return (<></>);
  }

  const contracts_count = data.extra_fields?.contracts_count;
  const contracts_roles = data.extra_fields?.contracts_roles;
  const sync_status = data.extra_fields?.sync_status;

  return (
    <Table borderless hover className="mb-0">
      <tbody>
        <tr>
          <td style={{ width: "100px" }}>Статус</td>
          <td>{getTitleFromDict(data.status, participant_status)}</td>
          <td></td>
        </tr>
        <AttributesSyncStatus syncStatus={sync_status} />
        <tr>
          <td style={{ width: "100px" }}>Роли</td>
          <td>
            {contracts_roles && contracts_roles.length
              ? contracts_roles.map((role) =>
                  role !== null ? (
                    <span key={role} className="d-block">
                      {participant_roles[role]}
                    </span>
                  ) : null
                )
              : "-"}
          </td>
          <td></td>
        </tr>
        <tr>
          <td style={{ width: "100px" }}>Договоры</td>
          <td>
            {contracts_count ? (
              <a href={`#contract/?participant=${data.id}`}>{contracts_count}</a>
            ) : (
              "-"
            )}
          </td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
};
