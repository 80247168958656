import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { Button } from "reactstrap";

type Props = {
  currentFilter: any;
  currentPage: number;
  resetFilter?: () => void;
  resetPage: () => void;
};

export const WrongPageOrFilter = ({
  resetFilter,
  resetPage,
  currentFilter,
  currentPage
}: Props) => {
  const isNotFirstPage = currentPage !== 0;
  const isFilterSet = !isEmpty(omitBy(currentFilter, isNil));

  return (
    <>
      <p className="fs-2 text-center font-weight-bold mt-5">Упс! Вы забрели куда-то не туда</p>
      {isNotFirstPage || isFilterSet ? (
        <>
          <p className="fs-3 text-center font-weight-bold">Попробуйте</p>
          {isFilterSet && resetFilter && (
            <Button color="primary" className="w-auto m-auto" onClick={resetFilter}>
              Сбросить фильтры
            </Button>
          )}
          {isNotFirstPage && (
            <Button color="primary" className="w-auto m-auto" onClick={resetPage}>
              Перейти на первую страницу
            </Button>
          )}
        </>
      ) : (
        <p className="fs-3 text-center font-weight-bold">Похоже тут пока ничего нет</p>
      )}
    </>
  );
};
