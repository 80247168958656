import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { FaCog } from "react-icons/fa";
import { Dropdown, DropdownToggle } from "reactstrap";
import useIsOpenControl from "utils/useIsOpenControl";

type Props = {
  actionsDropdownMenu: ReactNode;
};

export const ListingActions = ({ actionsDropdownMenu }: Props) => {
  const { isOpen: dropdownOpen, toggle: dropdownOpenToggle } = useIsOpenControl();

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={dropdownOpenToggle}>
        <DropdownToggle color="secondary" outline size="l" className="w-100">
          <FaCog />
        </DropdownToggle>
        {createPortal(actionsDropdownMenu, document.body)}
      </Dropdown>
    </>
  );
};
