import { useDispatch, useSelector } from "react-redux";

import Nav from "../AppNav/VerticalNavWrapper";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../AppLogo";

import { StateProps } from "reducers";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";

const AppSidebar = () => {
  const dispatch = useDispatch();

  const enableMobileMenu = useSelector((state: StateProps) => state.ThemeOptions.enableMobileMenu);

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  return (
    <>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <TransitionGroup>
        <CSSTransition
          component="div"
          className="app-sidebar bg-dark sidebar-text-light sidebar-shadow"
          appear={true}
          enter={false}
          exit={false}
          timeout={500}
        >
          <div>
            <HeaderLogo />
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <Nav />
              </div>
            </PerfectScrollbar>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default AppSidebar;
