import FieldLabel from "ui/FieldLabel";

type Props = {
  title: string;
  texts: string[];
  isStared?: boolean;
  infoText?: {
    text: string;
    id: string;
  };
  className?: string;
};

const FakeInput = ({ title, texts, isStared, infoText, className }: Props) => {
  const paragraphs = texts.length > 1 ? texts.map((t) => `<p>${t}</p>`).join("") : texts[0];
  return (
    <div className={className}>
      <FieldLabel title={title} isRequired={isStared} info={infoText} />
      <div className="fake-input" dangerouslySetInnerHTML={{ __html: paragraphs }}></div>
    </div>
  );
};

export default FakeInput;
