import { useUserInfo } from "api/user_info";
import { Fragment } from "react";
import { FaAddressCard } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa6";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledButtonDropdown
} from "reactstrap";

export default function UserBox() {
  const user_info = useUserInfo();
  const bGroup = user_info.data?.data.user.business_group
    ? user_info.data.data.user.business_group.title
    : "Компания не указана";
  return (
    <Fragment>
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  {/* <img width={42} className="rounded-circle" src={avatar} alt="" /> */}
                  <FaAddressCard size="36" style={{ color: "var(--bs-gray)" }} />
                  <FaAngleDown className="ms-2 opacity-8 d-none d-md-inline" />
                </DropdownToggle>
                <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-secondary">
                      <div className="menu-header-content text-start">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left me-3">
                              {/* <img width={42} className="rounded-circle" src={avatar} alt="" /> */}
                              <FaAddressCard size="36" style={{ color: "var(--bs-light)" }} />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {user_info.data?.data.user.smart_name}
                              </div>
                              <div className="widget-subheading opacity-8">{bGroup}</div>
                            </div>
                            <div className="widget-content-right me-2">
                              <Button
                                className="btn-pill"
                                color="light"
                                style={{ fontWeight: 400 }}
                              >
                                Выход
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {user_info.data?.data.user.is_staff && (
                    <Nav vertical>
                      <NavItem className="nav-item-btn text-center">
                        <Button
                          href="/admin/"
                          tag="a"
                          size="sm"
                          className="btn-wide"
                          color="primary"
                        >
                          {" "}
                          Администрирование{" "}
                        </Button>
                      </NavItem>
                    </Nav>
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ms-3 header-user-info">
              <div className="widget-heading">{user_info.data?.data.user.smart_name}</div>
              <div className="widget-subheading">{bGroup}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
