import { ID } from "models";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Loader from "react-loaders";
import { Button } from "reactstrap";
import { TFilesStates } from "types/AttachmentsTypes";
import Tooltip from "ui/Tooltip";

const RemoveCell = ({
  tempId,
  fileState,
  onDelete,
  extraAction,
  isNotDraftCreative,
  isAnythingLoading
}: {
  tempId: string;
  fileState?: TFilesStates;
  onDelete: (_id: ID) => void;
  extraAction?: () => void;
  isNotDraftCreative?: boolean;
  isAnythingLoading?: boolean;
}) => {
  const onDeleteClick = () => {
    onDelete(tempId);
    if (extraAction) extraAction();
  };

  const disabled = isNotDraftCreative || isAnythingLoading;

  return (
    <div className="d-flex justify-content-center">
      {fileState === "LOADING" ? <Loader type="ball-beat" active /> : null}

      {fileState === "SUCCESS" ? (
        <Button
          onClick={disabled ? () => {} : onDeleteClick}
          outline
          color={disabled ? "secondary" : "danger"}
          className="p-0 border-0 rounded-circle"
          disabled={disabled}
        >
          <IoMdCloseCircleOutline size={25} />
        </Button>
      ) : null}

      {fileState === "ERROR" ? (
        <Tooltip
          id={tempId}
          maxWidth="15em"
          target={
            <Button
              id={tempId}
              onClick={disabled ? () => {} : onDeleteClick}
              outline
              color="secondary"
              className="p-0 border-0 rounded-circle d-block"
              disabled={disabled}
            >
              <IoMdCloseCircleOutline size={25} />
            </Button>
          }
        >
          {tempId}
          Во время загрузки файла произошла ошибка. Нажмите, чтобы удалить из списка
        </Tooltip>
      ) : null}
    </div>
  );
};

export default RemoveCell;
