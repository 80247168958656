import { CSSProperties, FC } from "react";
import { Button, ButtonGroup } from "reactstrap";

interface Props {
  tabs: TabProps[];
  setActiveTab: (_: string) => void;
  activeTab?: string | null;
  style?: CSSProperties;
}

export interface TabProps {
  id: number;
  slug: string;
  name: string;
}

export const TabsBlock: FC<Props> = ({ tabs, setActiveTab, activeTab, style }) => {
  return (
    <ButtonGroup style={style} size="lg">
      {tabs.map((tab) => (
        <Button
          key={tab.id}
          onClick={() => setActiveTab(tab.slug)}
          color="primary"
          outline={activeTab !== tab.slug}
        >
          {tab.name}
        </Button>
      ))}
    </ButtonGroup>
  );
};
