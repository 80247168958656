import isArray from "lodash/isArray";
import { TContractFilter } from "types/TContractFilter";
import { TCreativeFilter } from "types/TCreativeFilter";
import { TCreativeGroupFilter } from "types/TCreativeGroupFilter";
import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TParticipantFilter } from "types/TParticipantFilter";
import { TPlatformFilter } from "types/TPlatformFilter";
import { TSearchParamsProps } from "types/TSearchParams";

export const getApiFilter = (
  obj?:
    | TContractFilter
    | TCreativeFilter
    | TInvoiceFilter
    | TParticipantFilter
    | TPlatformFilter
    | TCreativeGroupFilter
) => {
  if (!obj) return "";
  const str: string[] = [];
  for (const p in obj) {
    const key = p as keyof typeof obj;
    if (obj[key]) {
      const itemIsArray = isArray(obj[key]);
      const keyLastTwoCharsIsIN = (key as string).slice(-2) !== "in";
      const urlKey: string = p + (itemIsArray && keyLastTwoCharsIsIN ? "__in" : "");
      const uncutValue = obj[key] as any;
      const value = Array.isArray(uncutValue) ? uncutValue.join(",") : `${uncutValue || ""}`;

      str.push(encodeURIComponent(urlKey) + "=" + encodeURIComponent(value));
    }
  }
  return str.join("&");
};

export const getSearchParams = ({ page, size, search, filter, ordering }: TSearchParamsProps) => {
  const r = {
    page: page ? (page + 1)?.toString() : "1",
    page_size: size?.toString() || "",
    search: search?.toString() || "",
    ordering: ordering?.toString() || ""
  };
  if (filter) {
    const filterString = getApiFilter(filter);
    return new URLSearchParams(r).toString() + "&" + filterString;
  } else {
    return new URLSearchParams(r).toString();
  }
};

export default getSearchParams;
