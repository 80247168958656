import get from "lodash/get";
import { useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormWatch,
  Validate
} from "react-hook-form";
import { TAttachment, TFilesStates } from "types/AttachmentsTypes";
import { TFileUploadFailResponse, TFileUploadResponse } from "types/ResponseTypes";
import createNotification from "ui/Notification";
import getDropzoneAcceptFromArray from "utils/getDropzoneAcceptFromArray";
import { v4 as uuidv4 } from "uuid";
import AttachmentArea from "./components/AttachmentArea";

type Props = {
  name: string;
  title: string;
  message?: string;
  control: Control<FieldValues, any>;
  watch?: UseFormWatch<FieldValues>;
  errors?: FieldErrors<FieldValues>;
  fileExtensions?: string | string[];
  maxFiles?: number;
  validate?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>>;
  exampleLink?: string;
  uploadFunction: (
    _file: FormData
  ) => Promise<void | TFileUploadResponse | TFileUploadFailResponse>;
  disabled?: boolean;
  isRequired?: boolean;
  isStared?: boolean;
  defaultDescription?: string;
  extraAction?: (_?: TAttachment[]) => void;
};

const errorDecode = {
  "file-invalid-type": "Не верный тип файла",
  "file-too-large": "Файл слишком большой",
  "file-too-small": "Файл слишком маленький",
  "too-many-files": "Слишком много файлов"
};

export const DropZoneMultipleField = ({
  name,
  title,
  message,
  control,
  watch,
  errors,
  fileExtensions,
  maxFiles = 100,
  validate,
  exampleLink,
  uploadFunction,
  disabled,
  isRequired,
  isStared,
  defaultDescription,
  extraAction
}: Props) => {
  const [currentlyDisabled, setCurrentlyDisabled] = useState(disabled);

  if (!fileExtensions || fileExtensions.length === 0) return null;

  const errorMessage = !!errors && !!get(errors, name) ? get(errors, name)?.message : undefined;

  return (
    <>
      <p className="fs-4 text-center font-weight-bold">
        {title}
        {isRequired || isStared ? <span style={{ color: "var(--bs-red)" }}> *</span> : ""}
      </p>
      <div className="row align-items-center mb-2" style={{ marginInline: 0 }}>
        {exampleLink ? (
          <a href={exampleLink} className="w-25 text-center" target="_blank" rel="noreferrer">
            Шаблон
          </a>
        ) : null}
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired,
            validate
          }}
          render={({
            field: { value, onChange, onBlur }
          }: {
            field: {
              value: TAttachment[] | undefined;
              onChange: (_: TAttachment[]) => void;
              onBlur: () => void;
            };
          }) => {
            const updateValue = (newValue: TAttachment[], index: number, filePath?: string) => {
              const updatedValue = [...newValue];
              updatedValue.at(index)!.filePath = filePath;
              if (updatedValue.at(index)!.fileState !== "SUCCESS")
                updatedValue.at(index)!.fileState = filePath ? "SUCCESS" : "ERROR";
              onChange(updatedValue);

              if (!updatedValue.map((v) => v.fileState).includes("LOADING"))
                setCurrentlyDisabled(false);
            };

            return (
              <Dropzone
                onDropAccepted={(allAddedFiles: File[]) => {
                  const valuePickedFromWatch = watch ? (watch(name) as TAttachment[]) : undefined;
                  const oldValue = valuePickedFromWatch || value;
                  const amountOfAlreadyAttachedFiles = oldValue?.length || 0;
                  const amountOfRecentlyAttachedFiles = allAddedFiles.length;

                  let availableAmountOfFilesToAttach = maxFiles;

                  if (amountOfAlreadyAttachedFiles + amountOfRecentlyAttachedFiles > maxFiles) {
                    createNotification("info", `Нельзя прикрепить больше ${maxFiles} файлов`);
                    availableAmountOfFilesToAttach = maxFiles - amountOfAlreadyAttachedFiles;
                  }

                  if (availableAmountOfFilesToAttach <= 0) return;

                  const files = allAddedFiles.slice(0, availableAmountOfFilesToAttach);

                  const newAttachedFiles: TAttachment[] = [...files].map((file) => ({
                    tempId: uuidv4(),
                    type: "file",
                    file,
                    fileState: "LOADING" as TFilesStates,
                    description: defaultDescription
                  }));

                  const newValue = oldValue ? [...oldValue, ...newAttachedFiles] : newAttachedFiles;

                  onChange(newValue);
                  if (extraAction) extraAction(newValue);

                  setCurrentlyDisabled(true);

                  newValue.forEach(({ file }, index) => {
                    if (file) {
                      const formData = new FormData();
                      formData.append("file", file);
                      uploadFunction(formData)
                        .then((res) => {
                          if (res && res.status && res.data.file_path) {
                            updateValue(newValue, index, res.data.file_path);
                          }

                          if ((res && !res.status) || (res && res.status && !res.data.file_path)) {
                            updateValue(newValue, index);
                          }
                        })
                        .catch(() => {
                          updateValue(newValue, index);
                        });
                    } else {
                      updateValue(newValue, index);
                    }
                  });

                  onBlur();
                }}
                onDropRejected={(err: FileRejection[]) => {
                  err.forEach(({ errors }) => {
                    createNotification(
                      "error",
                      errors
                        .map(({ code }) => errorDecode[code as keyof typeof errorDecode])
                        .join(". ")
                    );
                  });
                  onBlur();
                }}
                accept={getDropzoneAcceptFromArray(fileExtensions)}
                disabled={currentlyDisabled || (value && value.length >= maxFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <AttachmentArea
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    message={message}
                    disabled={currentlyDisabled || (value && value.length >= maxFiles)}
                    isErrors={!!errors && !!get(errors, name)}
                    isExampleLink={!!exampleLink}
                  />
                )}
              </Dropzone>
            );
          }}
        />
      </div>
      {!!errorMessage && typeof errorMessage === "string" ? (
        <p className="text-danger text-center">{errorMessage}</p>
      ) : null}
    </>
  );
};
