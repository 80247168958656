import axios from "axios";
import Cookies from "js-cookie";
import { ID, NEW_Creative } from "models";

export const patchCreativeNew = ({ id, creative }: { id: ID; creative: NEW_Creative }) => {
  return axios
    .patch(`/api/v1/creative_new/${id}/`, creative, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      }
    })
    .then((res) => {
      return res;
    });
};
