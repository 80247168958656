import { userLogin } from "api/user_login";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";

const LoginBoxed = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { mutate: login, isLoading } = useMutation({
    mutationFn: userLogin,
    onSuccess: (data) => {
      if (!data) {
        setErrorMessage(null);
        setEmailError(null);
        setPasswordError(null);
        return queryClient.invalidateQueries(["user_info"]);
      }

      if (data.error_message) setErrorMessage(data.error_message);
      if (data.form_errors.email) setEmailError(data.form_errors.email);
      if (data.form_errors.password) setPasswordError(data.form_errors.password);
      return;
    }
  });

  return (
    <Fragment>
      <div className="h-100 bg-plum-plate bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            {/* <div className="app-logo-inverse mx-auto mb-3" /> */}
            <div className="app-logo" />
            <div className="modal-dialog w-100 mx-auto">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="h5 modal-title text-center">
                    <h4 className="mt-2">
                      <div>Добро пожаловать,</div>
                      <span>Пожалуйста войдите в свой аккаунт.</span>
                    </h4>
                  </div>
                  <Form>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="email"
                            name="email"
                            id="exampleEmail"
                            placeholder="Email здесь..."
                            value={email}
                            invalid={!!emailError}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailError && <FormFeedback>{emailError}</FormFeedback>}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="password"
                            name="password"
                            id="examplePassword"
                            placeholder="Пароль здесь..."
                            value={password}
                            invalid={!!passwordError || !!errorMessage}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {passwordError && <FormFeedback>{passwordError}</FormFeedback>}
                          {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/*<FormGroup check>*/}
                    {/*  <Input type="checkbox" name="check" id="exampleCheck" />*/}
                    {/*  <Label for="exampleCheck" check>*/}
                    {/*    Keep me logged in*/}
                    {/*  </Label>*/}
                    {/*</FormGroup>*/}
                  </Form>
                  <div className="divider" />
                  <h6 className="mb-0">
                    Есть доступ в КИТ?{" "}
                    <a href="/login_by_kit/" className="text-primary">
                      войдите через КИТ
                    </a>
                  </h6>
                </div>
                <div className="modal-footer clearfix">
                  <div className="float-start">
                    <a
                      href="https://colorlib.com/"
                      onClick={(e) => e.preventDefault()}
                      className="btn-lg btn btn-link"
                    >
                      Восстановить пароль
                    </a>
                  </div>
                  <div className="float-end">
                    <Button
                      color="primary"
                      size="lg"
                      disabled={isLoading}
                      onClick={() => login({ email, password })}
                    >
                      Войти
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center text-white opacity-8 mt-3">
              Copyright &copy; KokocGroup 2024
            </div>
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginBoxed;
