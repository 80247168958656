import isArray from "lodash/isArray";
import { components, OptionProps } from "react-select";

const MultiFieldOption = (
  props: OptionProps<any>,
  optionKey: string | string[],
  optionKeyLabels?: string[]
) => {
  const parsed = JSON.parse(props.data.label);
  return (
    <components.Option {...props}>
      {isArray(optionKey) &&
        optionKey.map((op, index) => {
          const label = optionKeyLabels?.[index];

          if (optionKeyLabels && label === "hidden") return null;

          if (optionKeyLabels && label?.includes("(link)"))
            return (
              <p key={op} className="mb-0">
                <span className="text-secondary">{label.replace("(link)", "")}: </span>
                <span>{props.data.value}</span>
              </p>
            );

          return (
            <p key={op} className="mb-0">
              {optionKeyLabels ? <span className="text-secondary">{label}: </span> : null}
              <span>{parsed[op]}</span>
            </p>
          );
        })}
    </components.Option>
  );
};

export default MultiFieldOption;
