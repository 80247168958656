import { FaChevronRight } from "react-icons/fa";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import useIsOpenControl from "utils/useIsOpenControl";

type Props = {
  onExport: () => void;
  exportLink: string;
};

export const CreativeListingActions = ({ onExport, exportLink }: Props) => {
  const { isOpen: exportDropdownOpen, toggle: exportDropdownToggle } = useIsOpenControl();

  return (
    <DropdownMenu>
      <Dropdown direction="end" isOpen={exportDropdownOpen} toggle={exportDropdownToggle}>
        <DropdownToggle
          onClick={onExport}
          tag="button"
          type="button"
          className="dropdown-item d-flex justify-content-between align-items-center"
        >
          <span>Экспорт</span>
          <FaChevronRight />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="a" href={exportLink || ""}>
            Скачать
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </DropdownMenu>
  );
};
