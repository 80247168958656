import EditorFooter from "Layout/EditorFooter";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "reactstrap";
import TFormAddPossibleStatuses from "types/TFormAddPossibleStatuses";
import { scrollToError } from "utils/scrollToError";
import { StatisticsForm } from "./components/form";

const StatisticsAddForm = () => {
  const [status, setStatus] = useState<TFormAddPossibleStatuses>("ACTIVE");
  // const navigate = useNavigate();
  const [amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number>();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    // setError,
    setValue
  } = useForm();

  // const { mutate: add } = useMutation({
  //   mutationFn: addInvoice,
  //   onSuccess: (p) => {},
  //   onError: (err) => {
  //     onErrorMutation(err as AxiosError, setError);
  //   }
  // });

  const onSubmit = handleSubmit(
    (_, event) => {
      // TODO: добавить запрос создания
      console.log(event, amountFromAllocationsFile);
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  return (
    <div>
      <h1>Загрузить статистику без акта</h1>
      <StatisticsForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={
          <EditorFooter
            left={
              <Button type="submit" color="primary" onClick={() => setStatus("DRAFT")}>
                Сохранить черновик
              </Button>
            }
            right={
              <>
                <Button href="#/invoice" tag="a" color="primary" outline>
                  Отменить
                </Button>
                <Button type="submit" color="danger" onClick={() => setStatus("ACTIVE")}>
                  Отправить в ЕРИР
                </Button>
              </>
            }
          />
        }
        watch={watch}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        setValue={setValue}
        isDraft={status === "DRAFT"}
      />
    </div>
  );
};

export default StatisticsAddForm;
