import { Participant } from "models";
import { UseMutateFunction } from "react-query";
import { TAPIResponseError } from "types/ResponseTypes";
import { ActionSelectorProps } from "ui/ActionSelector";
import isIncludesInBothArrays from "utils/isIncludesInBothArrays";

const getActions = (
  participant: Participant,
  del: UseMutateFunction<"success" | TAPIResponseError, unknown, string | number, unknown>
): (ActionSelectorProps | null)[] => {
  const syncStatuses = participant.extra_fields?.sync_status.map((st) => st.status) || [];
  return [
    syncStatuses.length && !syncStatuses.includes("SYNC_ERROR")
      ? {
          title: "Просмотр контрагента",
          calcHref: (id) => ({ href: `#/participant/${id}` })
        }
      : null,
    {
      title: "Редактировать контрагента",
      calcHref: (id) => ({ href: `#/participant/${id}` }),
      calcIsDisabled: () =>
        isIncludesInBothArrays(syncStatuses, ["IS_SYNCHRONIZING", "SYNCHRONIZED"])
    },
    {
      title: "Удалить контрагента",
      onClick: () => (participant.id ? del(participant.id) : null),
      calcIsDisabled: () =>
        isIncludesInBothArrays(syncStatuses, ["IS_SYNCHRONIZING", "SYNCHRONIZED", "SYNC_ERROR"]) ||
        !!participant.extra_fields?.contracts_count
    }
  ];
};

export default getActions;
