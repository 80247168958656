import { TParticipantFilter } from "types/TParticipantFilter";
import { TSearchParamsRequired } from "types/TSearchParams";
import { OrderingVariant } from "ui/SortSelector";

export const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "name",
    title: "Название ↑"
  },
  {
    order: "-name",
    title: "Название ↓"
  }
];

export const defaultSearchParams: TSearchParamsRequired<TParticipantFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: orderVariants[1].order,
  filter: {
    legal_form: [],
    ord_account: [],
    combined_status: [],
    role: []
  }
};

export const defaultExportLink = "/api/v1/participant/export/xls";
