import { Dashboard, DashboardOrdData, DashboardStatisticsData, ID } from "models";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import getSearchParams from "utils/getSearchParams";

export interface GetDashbaordListProps extends TSearchParamsPropsNoFilter {
  tab?: "ord" | "statistics";
  id?: ID;
}

export const getDashboardStatisticsListInfo = (id?: number) => {
  return fetch(`/api/v1/dashboard/statistics/${id || ""}`, {
    mode: "cors",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  }).then((res) => {
    const result = res.json() as Promise<Dashboard<DashboardStatisticsData[]>>;
    return result;
  });
};

export const getDashboardOrdListInfo = (props?: TSearchParamsPropsNoFilter) => {
  return fetch(
    props ? `/api/v1/dashboard/ord/?${getSearchParams(props)}` : "/api/v1/dashboard/ord",
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  ).then((res) => {
    const result = res.json() as Promise<Dashboard<DashboardOrdData>>;
    return result;
  });
};
