import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import useIsOpenControl from "utils/useIsOpenControl";

export type OrderingVariant = {
  order: string;
  title: string;
};

type Props = {
  variants: OrderingVariant[];
  currentOrder: string;
  setCurrentOrder: (_: string) => void;
};

export const OrderingSelector = ({ variants, currentOrder, setCurrentOrder }: Props) => {
  const { isOpen: dropdownOpen, toggle: toggleDropdownModal } = useIsOpenControl();

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdownModal} direction="up">
      <DropdownToggle caret color="primary">
        {variants.find((v) => v.order === currentOrder)?.title}
      </DropdownToggle>
      <DropdownMenu>
        {variants.map(({ order, title }) => (
          <DropdownItem
            key={order}
            disabled={order === currentOrder}
            onClick={() => setCurrentOrder(order)}
          >
            {title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
