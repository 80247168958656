import contract_status from "dictionaries/contract_status.json";
import { Contract } from "models";
import { Table } from "reactstrap";
import { AttributesSyncStatus } from "ui/AttributesDropdown/helpers/syncStatus";
import getTitleFromDict from "utils/getTitleFromDict";

export const ContractAttributesBody = ({ data }: { data: Contract | undefined }) => {
  if (!data) return (<></>);

  const creatives_count = data.extra_fields?.creatives_count;
  const invoices_count = data.extra_fields?.invoices_count;
  const sync_status = data.extra_fields?.sync_status;

  return (
    <Table borderless hover className="mb-0">
      <tbody>
        <tr>
          <td style={{ width: "100px" }}>Статус</td>
          <td>{getTitleFromDict(data.status, contract_status)}</td>
          <td></td>
        </tr>
        <AttributesSyncStatus syncStatus={sync_status} />
        <tr>
          <td style={{ width: "100px" }}>Креативы</td>
          <td>
            {creatives_count ? (
              <a href={`#creative/?contract=${data.id}`}>{creatives_count}</a>
            ) : (
              "-"
            )}
          </td>
        </tr>
        <tr>
          <td style={{ width: "100px" }}>Акты</td>
          <td>
            {invoices_count ? <a href={`#invoice/?contract=${data.id}`}>{invoices_count}</a> : "-"}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
