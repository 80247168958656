import { CreativeGroupEditForm } from "Components/CreativeGroupForm";
import { useParams } from "react-router-dom";

const CreativeGroupFormPage = () => {
  const { id } = useParams();

  return id && +id ? <CreativeGroupEditForm id={+id} /> : null;
};

export default CreativeGroupFormPage;
