import { IMAGE_EXTENSIONS, SOUND_EXTENSIONS, VIDEO_EXTENSIONS } from "constants/FileExtensions";
import { ID } from "models";
import getFileExtensionFromPath from "utils/getFileExtensionFromPath";
import { TItem } from ".";

const Body = ({
  item,
  creativeId,
  onLoad
}: {
  item: TItem;
  creativeId?: ID;
  onLoad: () => void;
}) => {
  if (item) {
    if (item.type === "file") {
      const extension = getFileExtensionFromPath(item.data || "");
      if (extension && creativeId) {
        const src = `/api/v1/creative/${creativeId}/media/${item.id}/`;
        if (IMAGE_EXTENSIONS.includes(extension))
          return (
            <img
              src={src}
              alt={item.data}
              style={{ objectFit: "contain", height: "100%", width: "100%" }}
              onLoad={onLoad}
            />
          );

        if (VIDEO_EXTENSIONS.includes(extension)) {
          onLoad();
          return (
            <video controls style={{ objectFit: "contain", height: "100%", width: "100%" }}>
              <source src={src} />
            </video>
          );
        }

        if (SOUND_EXTENSIONS.includes(extension)) {
          onLoad();
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <audio controls src={src} style={{ width: "100%" }} />
            </div>
          );
        }
      }
    } else if (item.type === "text") {
      return <div style={{ whiteSpace: "pre-wrap" }}>{item.data}</div>;
    }
  }

  onLoad();

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
      <p>Предпросмотр недоступен</p>
    </div>
  );
};

export default Body;
