import { putCreativeNew } from "api/creative_new_put";
import { AxiosError } from "axios";
import { ID } from "models";
import { UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { NavigateFunction } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { TDefaultValuesCreativeEditor } from "../types";

export const useEditCreative = ({
  id,
  listingLink,
  navigate,
  setError
}: {
  id: ID;
  listingLink: string;
  navigate: NavigateFunction;
  setError: UseFormSetError<TDefaultValuesCreativeEditor>;
}) => {
  const { mutate: edit } = useMutation({
    mutationFn: putCreativeNew,
    onSuccess: (p) => onSuccessMutation(p, listingLink, navigate, false, `Креатив [${id}] изменен`),
    onError: (err) => onErrorMutation(err as AxiosError, setError)
  });

  return edit;
};
