import { editContract } from "api/contract_edit";
import { AxiosError } from "axios";
import { ID } from "models";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { notVisibleFieldsContract } from "../constants";

const useEditContract = (
  id: ID,
  contractLastSearch: string,
  contract_number: string | undefined,
  setError: UseFormSetError<FieldValues>
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: editContract,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/contract/${contractLastSearch}`,
        navigate,
        false,
        `Договор ${
          contract_number && contract_number !== "" ? `№${contract_number}` : `[${id}]`
        } изменен`
      ),
    onError: (err) => onErrorMutation(err as AxiosError, setError, notVisibleFieldsContract)
  });

  return mutate;
};

export default useEditContract;
