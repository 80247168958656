import { TPrefixWord } from "./types";

export const trimPrefix = (word: string, prefix: string): string => word.substring(prefix.length);

export const splitPrefixFromWord = (word: string, prefix: string[] | string): TPrefixWord => {
  if (prefix && typeof prefix === "string" && word && word.indexOf(prefix) === 0) {
    return { prefix, word: trimPrefix(word, prefix) };
  }

  if (prefix && typeof prefix !== "string" && word) {
    const indexOfCorrectPrefix = prefix
      .map((pr, i) => (word.indexOf(pr) === 0 ? i : null))
      .find((i) => i !== null);

    if (typeof indexOfCorrectPrefix === "number") {
      const correctPrefix = prefix[indexOfCorrectPrefix];
      return { prefix: correctPrefix, word: trimPrefix(word, correctPrefix) };
    }
  }

  return { prefix: undefined, word };
};
