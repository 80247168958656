import cn from "classnames";
import { FaFolder } from "react-icons/fa";
import { PiHeadsetLight, PiTelegramLogoLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { StateProps } from "reducers";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { MyNav } from "./NavItems";

const Nav = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const enableMobileMenu = useSelector((state: StateProps) => state.ThemeOptions.enableMobileMenu);

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  return (
    <>
      <h5 className="app-sidebar__heading">Menu</h5>
      <div className="vertical-nav-menu">
        {MyNav.map((navItem) => {
          const isActive = pathname.split("/")[1] === navItem.pathName;
          return (
            <a
              key={navItem.to}
              href={navItem.to}
              className={cn("vertical-nav-menu__item", {
                "vertical-nav-menu__item_active": isActive
              })}
              onClick={toggleMobileSidebar}
            >
              <FaFolder size={16} className="vertical-nav-menu__item__icon" />
              <span className="vertical-nav-menu__item__label">{navItem.label}</span>
            </a>
          );
        })}
      </div>
      <div className="vertical-nav-menu" style={{ marginTop: "auto" }}>
        <a
          href="http://t.me/advmarker"
          className="vertical-nav-menu__item"
          target="_blank"
          rel="noreferrer"
          onClick={toggleMobileSidebar}
        >
          <PiTelegramLogoLight size={16} className="vertical-nav-menu__item__icon" />
          <span className="vertical-nav-menu__item__label">Маркировочная</span>
        </a>
        <a
          href="mailto:support@advmarker.ru"
          className="vertical-nav-menu__item"
          target="_blank"
          rel="noreferrer"
          onClick={toggleMobileSidebar}
        >
          <PiHeadsetLight size={16} className="vertical-nav-menu__item__icon" />
          <span className="vertical-nav-menu__item__label">Служба поддержки</span>
        </a>
        {/* <button className="vertical-nav-menu__item" onClick={toggleFeedbackModal}>
          <PiHeadsetLight size={16} className="vertical-nav-menu__item__icon" />
          <span className="vertical-nav-menu__item__label">Служба поддержки</span>
        </button> */}
      </div>
      {/* <FeedbackFormModal isOpen={isFeedbackModalOpen} toggle={toggleFeedbackModal} /> */}
    </>
  );
};

export default Nav;
