import axios from "axios";
import { ID, Platform } from "models";
import { APIData } from "./types";

export const getPlatform = (id?: ID): Promise<APIData<Platform>> => {
  return axios
    .get(`/api/v1/platform/${id}/`, {
      headers: {
        // "X-CSRFToken": Cookies.get("csrftoken"),
        // "Content-Type": "application/json;charset=utf-8",
        "X-Requested-With": "XMLHttpRequest"
      },
      timeout: 1000
    })
    .then((res) => {
      return res.data as Promise<APIData<Platform>>;
    });
};
