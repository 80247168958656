import { StatisticsAddForm, StatisticsEditForm } from "Components/StatisticsForm";
import { useParams } from "react-router-dom";

const StatisticsFormPage = () => {
  const { id } = useParams();

  return (
    <>
      {id === "add" ? <StatisticsAddForm /> : null}
      {id && +id ? <StatisticsEditForm id={+id} /> : null}
    </>
  );
};

export default StatisticsFormPage;
