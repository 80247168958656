import { getCreativeGroupNewInfo } from "api/creative_group_new";
import uniqBy from "lodash/uniqBy";
import { ID } from "models";
import { useMutation } from "react-query";

const useSearchCreativeGroupMutation = ({
  searchCreativeGroup,
  currentGroupId,
  initialContractId,
  finalContractId,
  advertisementForm
}: {
  searchCreativeGroup?: string;
  currentGroupId?: ID;
  initialContractId?: ID;
  finalContractId?: ID;
  advertisementForm?: number;
}) =>
  useMutation({
    mutationFn: () => {
      const filterByDefaults = {
        initial_contract: initialContractId ? [initialContractId] : undefined,
        final_contract: finalContractId ? [finalContractId] : undefined,
        advertisement_form: advertisementForm ? [advertisementForm] : undefined
      };

      return Promise.all([
        getCreativeGroupNewInfo({ search: searchCreativeGroup, filter: filterByDefaults }),
        getCreativeGroupNewInfo({ filter: currentGroupId ? { id: [currentGroupId] } : {} })
      ])
        .then(([searchRes, byIdsRes]) => {
          const results = uniqBy([...searchRes.data.results, ...byIdsRes.data.results], "id");
          return {
            status: true,
            data: { count: results.length, next: "", previous: "", results }
          };
        })
        .catch(() => ({ status: false, data: { count: 0, next: "", previous: "", results: [] } }));
    }
  });

export default useSearchCreativeGroupMutation;
