import { DashboardTabs } from "constants/DashboardTabs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StateProps } from "reducers";
import { TabsBlock } from "ui/Tabs";
import { DashboardOrdTable } from "./components/tableOrd";
import { DashboardStatisticsTable } from "./components/tableStatistics";

type TPossibleTabs = "ord" | "statistics";

const possibleTabs = DashboardTabs.map(({ slug }) => slug);

export const DashboardList = () => {
  const lastSearch = {
    ord: useSelector((state: StateProps) => state.LastSearchParams.dashboard_ord),
    statistics: useSelector((state: StateProps) => state.LastSearchParams.dashboard_statistics)
  };
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const tab = pathname.split("/").at(-1);
    if (tab && possibleTabs.includes(tab)) setActiveTab(tab);
    else setActiveTab("ord");
  }, [pathname]);

  return (
    <>
      <div className="row mt-3">
        <TabsBlock
          setActiveTab={(slug) => {
            setActiveTab(slug);
            navigate(`/dashboard/${slug}${lastSearch[slug as TPossibleTabs]}`);
          }}
          activeTab={activeTab}
          tabs={DashboardTabs}
        />
      </div>
      <div className="row mt-3">
        <div className="d-block col-sm-12">
          {activeTab === "ord" ? <DashboardOrdTable /> : null}
          {activeTab === "statistics" ? <DashboardStatisticsTable /> : null}
        </div>
      </div>
    </>
  );
};
