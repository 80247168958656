import { ID } from "models";
import { Dispatch, SetStateAction } from "react";
import { FaFile } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import { Button } from "reactstrap";
import IconButton from "ui/IconButton";
import getTextShortened from "utils/getTextShortened";
import { TDisplayObj } from "./AttachmentPreviewModal";

type Props = {
  tempId: string;
  data?: string;
  text?: string;
  setDisplayObj: Dispatch<SetStateAction<TDisplayObj | null>>;
  toggle: () => void;
  onDelete: (_id: ID) => void;
  isAlreadyAttachedFile?: boolean;
  isRecentlyAttachedFile?: boolean;
  disabledRemove?: boolean;
};

const TextRow = ({
  tempId,
  data,
  text,
  setDisplayObj,
  toggle,
  onDelete,
  isAlreadyAttachedFile,
  isRecentlyAttachedFile,
  disabledRemove
}: Props) => {
  const displayObj: TDisplayObj = {};

  if (isRecentlyAttachedFile) {
    displayObj.text = text;
    displayObj.url = "data:text/plain;charset=utf-8," + encodeURIComponent(text!);
  }

  if (isAlreadyAttachedFile) {
    displayObj.text = data;
    displayObj.url = "data:text/plain;charset=utf-8," + encodeURIComponent(data!);
  }

  return (
    <tr key={tempId} className={isAlreadyAttachedFile ? "table-secondary" : ""}>
      <td style={{ verticalAlign: "middle" }}>
        <IconButton
          icon={<FaFile size={15} />}
          text={getTextShortened(displayObj.text, 100) || ""}
          onClick={() => {
            toggle();
            setDisplayObj(displayObj);
          }}
          outline
          className="border-0 text-start text-nowrap"
          dir="itt"
        />
      </td>

      <td style={{ verticalAlign: "middle" }}></td>

      <td style={{ verticalAlign: "middle" }}>
        <div className="d-flex justify-content-center">
          {displayObj.url ? (
            <a
              href={displayObj.url}
              download={getTextShortened(displayObj.text, 10) + ".txt"}
              className="p-0 border-0 d-inline-flex justify-content-center align-items-center btn btn-outline-secondary"
              style={{ width: "25px", height: "25px" }}
            >
              <MdOutlineFileDownload size={24} />
            </a>
          ) : null}
        </div>
      </td>

      <td style={{ verticalAlign: "middle" }}>
        <div className="d-flex justify-content-center">
          <Button
            onClick={disabledRemove ? () => {} : () => onDelete(tempId)}
            outline
            color={disabledRemove ? "secondary" : "danger"}
            className="p-0 border-0 rounded-circle"
            disabled={disabledRemove}
          >
            <IoMdCloseCircleOutline size={25} />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default TextRow;
