import ErrorComponent from "Components/ErrorComponent";
import ListingLayout from "Layout/ListingLayout";
import Cookies from "js-cookie";
import { ID } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCloseModalNotNeeded } from "reducers/CloseModalSettings";
import { TCreativeFilter } from "types/TCreativeFilter";
import createNotification from "ui/Notification";
import getLastPageNumber from "utils/getLastPageNumber";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import useIsOpenControl from "utils/useIsOpenControl";
import { CreativeFilter } from "./components/filter";
import { CreativeListingActions } from "./components/listingActions";
import { CreativeTable } from "./components/table";
import { defaultExportLink, defaultSearchParams, orderVariants } from "./constants";
import useCreativesSearchParams from "./hooks/useCreativesSearchParams";
import useLoadCreatives from "./hooks/useLoadCreatives";

export const CreativeList = () => {
  const navigate = useNavigate();
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();
  dispatch(setCloseModalNotNeeded());

  const { isOpen: isFilterOpen, toggle: toggleFilter } = useIsOpenControl();

  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const searchParams = useCreativesSearchParams({ searchProperties, dispatch });
  const { isLoading, error, data, creativesRefetch, count } = useLoadCreatives({ searchParams });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeCreative", page_size.toString());
    const lastPageNumber = getLastPageNumber(count, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onFilterClick = (filter: TCreativeFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onExport = () => {
    if (exportIds.length) {
      setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds } })}`);
    } else {
      createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    }
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (error) {
    return <ErrorComponent error={error.message} status={error.response?.status} isEmbedded />;
  }

  return (
    <ListingLayout
      onFilterToggle={toggleFilter}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onPageSizeSelect={onPageSizeSelect}
      onOrderSelect={onOrderSelect}
      onResetFilter={onResetFilter}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/creative/add"
      isDataLoading={isLoading}
      isFilterModalOpen={isFilterOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentFilter={searchParams.filter}
      currentOrder={searchParams.ordering}
      count={count}
      actionsDropdownMenu={<CreativeListingActions onExport={onExport} exportLink={exportLink} />}
      table={
        <CreativeTable
          creatives={data.creatives}
          contracts={data.contracts}
          refetch={creativesRefetch}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={data.creatives.filter(({ id }) => !!id).map(({ id }) => id as number)}
        />
      }
      orderVariants={orderVariants}
      filter={
        <CreativeFilter
          filter={searchParams.filter}
          clearFilter={defaultSearchParams.filter}
          onFilterClick={onFilterClick}
          onClose={toggleFilter}
        />
      }
      searchPlaceholder="Введите название/ИНН контрагента, номер договора или название группы"
    />
  );
};
