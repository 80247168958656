export const SET_LAST_SEARCH_PARAMS_PARTICIPANT = "LAST_SEARCH_PARAMS/PARTICIPANT";
export const SET_LAST_SEARCH_PARAMS_CONTRACT = "LAST_SEARCH_PARAMS/CONTRACT";
export const SET_LAST_SEARCH_PARAMS_INVOICE = "LAST_SEARCH_PARAMS/INVOICE";
export const SET_LAST_SEARCH_PARAMS_STATISTICS = "LAST_SEARCH_PARAMS/STATISTICS";
export const SET_LAST_SEARCH_PARAMS_CREATIVE = "LAST_SEARCH_PARAMS/CREATIVE";
export const SET_LAST_SEARCH_PARAMS_PLATFORM = "LAST_SEARCH_PARAMS/PLATFORM";
export const SET_LAST_SEARCH_PARAMS_DASHBOARD_ORD = "LAST_SEARCH_PARAMS/DASHBOARD_ORD";
export const SET_LAST_SEARCH_PARAMS_DASHBOARD_STATISTICS =
  "LAST_SEARCH_PARAMS/DASHBOARD_STATISTICS";

export interface LastSearchStateProps {
  participant: string;
  contract: string;
  invoice: string;
  statistics: string;
  creative: string;
  platform: string;
  dashboard_ord: string;
  dashboard_statistics: string;
}

interface ActionProps extends LastSearchStateProps {
  type: string;
}

const defaultValue: LastSearchStateProps = {
  participant: "",
  contract: "",
  invoice: "",
  statistics: "",
  creative: "",
  platform: "",
  dashboard_ord: "",
  dashboard_statistics: ""
};

export const setParticipantLastSearchParams = (
  participant: LastSearchStateProps["participant"]
) => ({
  type: SET_LAST_SEARCH_PARAMS_PARTICIPANT,
  participant
});

export const setContractLastSearchParams = (contract: LastSearchStateProps["contract"]) => ({
  type: SET_LAST_SEARCH_PARAMS_CONTRACT,
  contract
});

export const setInvoiceLastSearchParams = (invoice: LastSearchStateProps["invoice"]) => ({
  type: SET_LAST_SEARCH_PARAMS_INVOICE,
  invoice
});

export const setStatisticsLastSearchParams = (statistics: LastSearchStateProps["statistics"]) => ({
  type: SET_LAST_SEARCH_PARAMS_STATISTICS,
  statistics
});

export const setCreativeLastSearchParams = (creative: LastSearchStateProps["creative"]) => ({
  type: SET_LAST_SEARCH_PARAMS_CREATIVE,
  creative
});

export const setPlatformLastSearchParams = (platform: LastSearchStateProps["platform"]) => ({
  type: SET_LAST_SEARCH_PARAMS_PLATFORM,
  platform
});

export const setDashboardOrdLastSearchParams = (
  dashboard_ord: LastSearchStateProps["dashboard_ord"]
) => ({
  type: SET_LAST_SEARCH_PARAMS_DASHBOARD_ORD,
  dashboard_ord
});

export const setDashboardStatisticsLastSearchParams = (
  dashboard_statistics: LastSearchStateProps["dashboard_statistics"]
) => ({
  type: SET_LAST_SEARCH_PARAMS_DASHBOARD_STATISTICS,
  dashboard_statistics
});

export default function reducer(state = defaultValue, action: ActionProps) {
  switch (action.type) {
    case SET_LAST_SEARCH_PARAMS_PARTICIPANT:
      return {
        ...state,
        participant: action.participant
      };

    case SET_LAST_SEARCH_PARAMS_CONTRACT:
      return {
        ...state,
        contract: action.contract
      };

    case SET_LAST_SEARCH_PARAMS_INVOICE:
      return {
        ...state,
        invoice: action.invoice
      };

    case SET_LAST_SEARCH_PARAMS_STATISTICS:
      return {
        ...state,
        statistics: action.statistics
      };

    case SET_LAST_SEARCH_PARAMS_CREATIVE:
      return {
        ...state,
        creative: action.creative
      };

    case SET_LAST_SEARCH_PARAMS_PLATFORM:
      return {
        ...state,
        platform: action.platform
      };

    case SET_LAST_SEARCH_PARAMS_DASHBOARD_ORD:
      return {
        ...state,
        dashboard_ord: action.dashboard_ord
      };

    case SET_LAST_SEARCH_PARAMS_DASHBOARD_STATISTICS:
      return {
        ...state,
        dashboard_statistics: action.dashboard_statistics
      };

    default:
  }
  return state;
}
