import axios from "axios";
import Cookies from "js-cookie";
import { Contract } from "models";

export const addContract = ({ contract }: { contract: Contract }) => {
  return axios
    .post(`/api/v1/contract/`, contract, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      }
    })
    .then((res) => {
      return res;
    });
};
