import PlatformTypeOptions from "constants/selectOptions/PlatformTypeOptions";
import { ID } from "models";
import { BaseSyntheticEvent, ReactNode, useState } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { useMutation } from "react-query";
import { Form } from "reactstrap";
import { CheckBoxField, InputField, PredictiveSearchField, SelectField } from "ui/index";
import { searchMutationParticipant } from "ui/PredictiveSearch/helpers";
import getErrorText from "utils/getErrorText";
import useLoadAllUrls from "../hooks/useLoadAllUrls";

interface Props {
  id?: ID;
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  buttons: ReactNode;
  watch: UseFormWatch<FieldValues>;
  onSubmit: (_e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  isLoading?: boolean;
  isDraft?: boolean;
  searchParticipantsValueDefault?: string;
}

export const PlatformForm = ({
  id,
  control,
  errors,
  onSubmit,
  watch,
  buttons,
  setValue,
  isLoading,
  isDraft,
  searchParticipantsValueDefault
}: Props) => {
  /** type = (web || mobile_app) */
  const disableNoUrlCheck = [1, 2, undefined].includes(watch("platform_type") as number);
  const isOwner = watch("is_owner") as boolean;
  const isNoUrl = watch("no_url") as boolean;

  const {
    options: urlsOptions,
    setOptions: setUrlsOptions,
    defaultOptions: defaultUrlsOptions
  } = useLoadAllUrls(id);

  /** Картеж для поиска контрагентов в поле "Владелец площадки" */
  const [searchParticipantsValue, setSearchParticipantsValue] = useState<undefined | string>(
    undefined
  );

  const {
    mutate: searchParticipantsRequest,
    data: searchParticipantsResponse,
    isLoading: isParticipantsLoading
  } = useMutation({
    mutationFn: searchMutationParticipant(searchParticipantsValue, searchParticipantsValueDefault, {
      page: 0,
      size: 100
    })
  });

  return (
    <Form
      className="mt-3 mb-3"
      onSubmit={(event) => {
        void onSubmit(event);
      }}
    >
      <div className="row">
        <div className="col-sm-9">
          <SelectField
            name="platform_type"
            title="Тип платформы"
            errorText={getErrorText(errors, "platform_type", "Должно быть заполнено.")}
            options={PlatformTypeOptions}
            control={control}
            errors={errors}
            isRequired={!isDraft}
            isStared
            disabled={isLoading}
          />
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-sm-9">
          <SelectField
            name="platform_url"
            title="URL"
            errorText={getErrorText(errors, "platform_url", "Должно быть заполнено.")}
            options={urlsOptions}
            control={control}
            errors={errors}
            isRequired={!isDraft}
            isStared
            disabled={isLoading || isNoUrl}
            onInputChange={(newUrl) => {
              if (newUrl) {
                setUrlsOptions([
                  {
                    value: newUrl,
                    label: newUrl
                  },
                  ...defaultUrlsOptions
                ]);
                setValue("platform_url", newUrl);
              }
            }}
          />
        </div>

        <div className="col-sm-3 mt-4">
          <CheckBoxField
            name="no_url"
            title="нет URL"
            control={control}
            disabled={disableNoUrlCheck || isLoading}
            errorText={getErrorText(errors, "no_url", "Должно быть заполнено.")}
            isChecked={isNoUrl && !disableNoUrlCheck}
            extraAction={() => {
              setValue("platform_url", undefined);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <InputField
            name="platform_name"
            title="Название платформы"
            errorText={getErrorText(errors, "platform_name", "Должно быть заполнено.")}
            errors={errors}
            control={control}
            isRequired={!isNoUrl && !isDraft}
            isStared={!isNoUrl}
            disabled={isLoading}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <CheckBoxField
            name="is_owner"
            title="Я знаю владельца площадки"
            control={control}
            isChecked={isOwner}
            disabled={isLoading}
          />
        </div>
      </div>

      {isOwner ? (
        <div className="row">
          <div className="col-sm-9">
            <PredictiveSearchField
              name="owner"
              title="Владелец площадки"
              optionKeyLabels={["Название", "ИНН", "hidden"]}
              optionKey={["name", "inn", "status"]}
              inputValue={searchParticipantsValue}
              setInputValue={setSearchParticipantsValue}
              control={control}
              errors={errors}
              errorText={getErrorText(errors, "owner", "Должно быть заполнено.")}
              isLoading={isParticipantsLoading}
              request={searchParticipantsRequest}
              response={searchParticipantsResponse}
              isRequired={isOwner && !isDraft}
              isStared={isOwner}
              disabled={!isOwner || isLoading}
              optionDisableCondition={{ status: 2 }}
              infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
              clearable
            />
          </div>
        </div>
      ) : null}

      {buttons}
    </Form>
  );
};
