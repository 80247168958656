import cn from "classnames";
import { DISPLAY_FIELDS, FILE_EXTENSIONS } from "constants/CreativesConstants";
import { NEW_Creative } from "models";
import { BaseSyntheticEvent, useState } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { useSelector } from "react-redux";
import { Button, Form } from "reactstrap";
import { StateProps } from "reducers";
import { TAttachment } from "types/AttachmentsTypes";
import { TFieldVariants } from "types/CreativesTypes";
import getFileExtensionFromPath from "utils/getFileExtensionFromPath";
import { CreativeFormFirstStep } from "./components/1_step";
import { CreativeFormSecondStep } from "./components/2_step";
import { CreativeFormThirdStep } from "./components/3_step";

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  onSubmit: (_e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  buttons: {
    toDraft: JSX.Element;
    save: JSX.Element;
  };
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  creative?: NEW_Creative;
  isDraft: boolean;
  buttonsDisabled?: boolean;
};

export const CreativeForm = ({
  control,
  errors,
  onSubmit,
  buttons,
  watch,
  setValue,
  clearErrors,
  isDraft,
  buttonsDisabled
}: Props) => {
  const creativeLastSearch = useSelector((state: StateProps) => state.LastSearchParams.creative);
  const [step, setStep] = useState(1);

  const [selectedFileExtensions, setSelectedFileExtensions] = useState<string[] | undefined>();
  const [selectedFields, setSelectedFields] = useState<TFieldVariants[] | undefined>();

  const onAdvertismentFormChange = (newForm: string | number | (string | number)[] | undefined) => {
    if (!newForm) {
      setValue(`extra_fields.creatives`, []);
      setSelectedFileExtensions(undefined);
      setSelectedFields(undefined);
      return;
    }

    if (newForm && typeof newForm === "number") {
      const currentlySelectedFileExtensions = FILE_EXTENSIONS[newForm];
      setSelectedFileExtensions(currentlySelectedFileExtensions);

      const currentlySelectedFields = DISPLAY_FIELDS[newForm];
      setSelectedFields(currentlySelectedFields);

      const numberOfCreatives = watch(`extra_fields.creatives`)
        ? (watch(`extra_fields.creatives`) as any[]).length
        : 0;

      if (numberOfCreatives === 0) {
        setValue(`extra_fields.creatives`, [{}]);
      } else {
        for (let index = 0; index < numberOfCreatives; index++) {
          if (currentlySelectedFileExtensions) {
            const key = `extra_fields.creatives[${index}].files_paths`;

            const previousValue = watch(key) as TAttachment[] | undefined;

            const newValue = previousValue?.filter((item) => {
              const extension = item.file && getFileExtensionFromPath(item.file?.name);
              return extension && currentlySelectedFileExtensions.includes(extension);
            });

            setValue(key, newValue);
          }

          if (!currentlySelectedFields.includes("creative_per_file_word")) {
            setValue(`extra_fields.creatives[${index}].creative_per_file_word`, undefined);
          }
        }
      }

      return;
    }
  };

  return (
    <Form
      onSubmit={(event) => {
        void onSubmit(event);
      }}
      className="mt-3 mb-3"
    >
      <div className={cn({ "d-block": step === 1, "d-none": step !== 1 })}>
        <CreativeFormFirstStep
          control={control}
          errors={errors}
          isDraft={isDraft}
          setValue={setValue}
          watch={watch}
          clearErrors={clearErrors}
        />
      </div>

      <div className={cn({ "d-block": step === 2, "d-none": step !== 2 })}>
        <CreativeFormSecondStep
          watch={watch}
          control={control}
          errors={errors}
          isDraft={isDraft}
          onAdvertismentFormChange={onAdvertismentFormChange}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      </div>

      <div className={cn({ "d-block": step === 3, "d-none": step !== 3 })}>
        <CreativeFormThirdStep
          watch={watch}
          control={control}
          errors={errors}
          setValue={setValue}
          isDraft={isDraft}
          selectedFileExtensions={selectedFileExtensions}
          selectedFields={selectedFields}
          clearErrors={clearErrors}
        />
      </div>

      <div className="d-flex gap-3 justify-content-between">
        {step === 3 ? buttons.toDraft : <div />}
        <div className="d-flex gap-3">
          <Button
            href={`#/creative/${creativeLastSearch}`}
            tag="a"
            color="primary"
            outline
            disabled={buttonsDisabled}
          >
            Закрыть
          </Button>

          {step > 1 ? (
            <Button
              color="primary"
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            >
              Назад
            </Button>
          ) : null}

          {step < 3 ? (
            <Button
              color="primary"
              onClick={() => {
                setStep((prev) => prev + 1);
              }}
            >
              Далее
            </Button>
          ) : null}
          {step === 3 ? buttons.save : null}
        </div>
      </div>
    </Form>
  );
};
