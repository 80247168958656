import { FormEvent, ReactNode } from "react";
import { Button, ButtonGroup, Form, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
type Props = {
  children: ReactNode;
  onSubmit: (_: FormEvent) => void;
  onClose: () => void;
  onReset: () => void;
};

const FilterLayout = ({ children, onSubmit, onClose, onReset }: Props) => {
  return (
    <Form className="bg-white shadow-sm" onSubmit={onSubmit}>
      <ModalHeader toggle={onClose}>Фильтр</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <ButtonGroup className="w-100">
          <Button color="primary" type="submit" onClick={onClose}>
            Применить
          </Button>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              onReset();
              onClose();
            }}
          >
            Сбросить
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Form>
  );
};

export default FilterLayout;
