import omit from "lodash/omit";
import { NEW_Creative } from "models";
import { TEditCreativeFunctionProps } from "../types";

const editCreativeFunction = ({
  id,
  isDraft,
  watch,
  creative,
  groupInfo,
  edit
}: TEditCreativeFunctionProps) => {
  const status = isDraft ? 2 : 1;
  const group_id = watch("group_id");
  const advertiser_urls = watch("advertiser_urls");
  const newAttachments = (watch("attachments") || []).filter((attachment) => !attachment.data);
  const oldAttachments = (watch("attachments") || []).filter((attachment) => attachment.data);

  const isGroupNotEdited =
    creative?.data.extra_fields?.group?.final_contract === groupInfo?.final_contract ||
    creative?.data.extra_fields?.group?.initial_contract === groupInfo?.initial_contract;

  const groupParams = isGroupNotEdited
    ? { group_id }
    : { group: groupInfo ? omit(groupInfo, ["id"]) : undefined };

  const newTexts = newAttachments
    ? newAttachments
        .filter((attachment) => attachment.type === "text")
        .map((attachment) => ({ data: attachment.text }))
    : [];

  const oldTexts = oldAttachments
    ? oldAttachments
        .filter((attachment) => attachment.type === "text")
        .map((attachment) => ({ id: attachment.id, data: attachment.data }))
    : [];

  const newMedia = newAttachments
    ? newAttachments
        .filter((attachment) => attachment.type === "file")
        .map((attachment) => ({
          data: attachment.filePath,
          description: attachment.description
        }))
    : [];

  const oldMedia = oldAttachments
    ? oldAttachments
        .filter((attachment) => attachment.type === "file")
        .map((attachment) => ({
          id: attachment.id,
          data: attachment.data,
          description: attachment.description
        }))
    : [];

  const result: NEW_Creative = {
    status,
    // Нужно ли это преобразование? Все равно не сохраняются данные...
    advertiser_urls: advertiser_urls?.map(({ advertiser_url }) => advertiser_url),
    ...groupParams,
    texts: [...oldTexts, ...newTexts],
    media: [...oldMedia, ...newMedia]
  };

  edit({ id, creative: result });
};

export default editCreativeFunction;
