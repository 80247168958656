import { Platform } from "models";
import { UseMutateFunction } from "react-query";
import { TAPIResponseError } from "types/ResponseTypes";
import { ActionSelectorProps } from "ui/ActionSelector";
import isIncludesInBothArrays from "utils/isIncludesInBothArrays";

const getActions = (
  platform: Platform,
  del: UseMutateFunction<"success" | TAPIResponseError, unknown, string | number, unknown>
): (ActionSelectorProps | null)[] => {
  const syncStatuses = platform.extra_fields?.sync_status.map((st) => st.status) || [];
  return [
    syncStatuses.length && !syncStatuses.includes("SYNC_ERROR")
      ? {
          title: "Просмотр площадки",
          calcHref: (id) => ({ href: `#/platform/${id}` })
        }
      : null,
    {
      title: "Редактировать",
      calcHref: (id) => ({ href: `#/platform/${id}` }),
      calcIsDisabled: () =>
        isIncludesInBothArrays(syncStatuses, ["IS_SYNCHRONIZING", "SYNCHRONIZED"])
    },
    {
      title: "Удалить",
      onClick: (id) => del(id),
      calcIsDisabled: () =>
        isIncludesInBothArrays(syncStatuses, ["IS_SYNCHRONIZING", "SYNCHRONIZED", "SYNC_ERROR"])
    }
  ];
};

export default getActions;
