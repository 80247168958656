import ActionTypeField from "./ActionTypeField";
import AmountField from "./AmountField";
import ClientField from "./ClientField";
import CommentField from "./CommentField";
import ContractDateField from "./ContractDateField";
import ContractNumberField from "./ContractNumberField";
import ContractorField from "./ContractorField";
import CreativesRegistrarField from "./CreativesRegistrarField";
import InitialContractsField from "./InitialContractsField";
import IsAgentActingForPublisherField from "./IsAgentActingForPublisherField";
import ParentContractField from "./ParentContractField";
import PreferredOrdAccountField from "./PreferredOrdAccountField";
import SelfAdParticipantField from "./SelfAdParticipantField";
import SubjectTypeField from "./SubjectTypeField";
import VatIncludedField from "./VatIncludedField";

const fieldsComponents = {
  subject_type: SubjectTypeField,
  action_type: ActionTypeField,
  contract_number: ContractNumberField,
  vat_included: VatIncludedField,
  amount: AmountField,
  contract_date: ContractDateField,
  creatives_registrar: CreativesRegistrarField,
  client: ClientField,
  contractor: ContractorField,
  preferred_ord_account: PreferredOrdAccountField,
  self_ad_participant: SelfAdParticipantField,
  parent_contract: ParentContractField,
  initial_contracts: InitialContractsField,
  is_agent_acting_for_publisher: IsAgentActingForPublisherField,
  comment: CommentField
};

export default fieldsComponents;
