import { APIOrdAccounts } from "./types";

const getOrdAccounts = (): Promise<APIOrdAccounts> => {
  return fetch(`/api/v1/dictionaries/ord_accounts/`, {
    mode: "cors",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  }).then((res) => {
    const result = res.json() as Promise<APIOrdAccounts>;
    return result;
  });
};

export default getOrdAccounts;
