import Loader from "react-loaders";
import { Table } from "reactstrap";

type Props = {
  numberOfRows: number;
};

export const TableLoad = ({ numberOfRows }: Props) => {
  return (
    <Table className="mb-0">
      <thead>
        <tr>
          <th>
            <Loader type="ball-beat" active innerClassName="loader-for-table" />
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: numberOfRows }, (_, i) => (
          <tr key={i}>
            <td>
              <Loader type="ball-beat" active innerClassName="loader-for-table" />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
