import { DatePickerField } from "ui/DatePicker";
import { FieldProps } from "./types";

const ContractDateField = ({
  control,
  errors,
  contractLoading,
  isShown,
  isRequired
}: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <DatePickerField
          name="contract_date"
          title="Дата договора"
          control={control}
          errors={errors}
          disabled={contractLoading}
          returnFormat="yyyy-MM-dd"
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default ContractDateField;
