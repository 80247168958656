import { deletePlatform } from "api/platform_delete";
import { ID } from "models";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onDeleteMutation from "utils/onDeleteMutation";

const useDeletePlatform = (
  id: ID,
  platformLastSearch: string,
  platformName: string | undefined
) => {
  const navigate = useNavigate();

  const { mutate: del } = useMutation({
    mutationFn: deletePlatform,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        successMessage: `Платформа ${platformName || `[${id}]`} удалена`,
        href: `/platform/${platformLastSearch}`,
        navigate
      })
  });

  return del;
};

export default useDeletePlatform;
