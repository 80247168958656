import { FieldKeys } from "./fields/types";

type Props = {
  1: { fields: FieldKeys[]; required: FieldKeys[] };
  2: { fields: FieldKeys[]; required: FieldKeys[] };
  3: { fields: FieldKeys[]; required: FieldKeys[] };
  4: { fields: FieldKeys[]; required: FieldKeys[] };
};

const fieldsByType: Props = {
  // Договор оказания услуг
  1: {
    fields: [
      "subject_type",
      "contract_number",
      "vat_included",
      "amount",
      "contract_date",
      "creatives_registrar",
      "client",
      "contractor",
      "preferred_ord_account",
      "initial_contracts",
      "comment"
    ],
    required: [
      "subject_type",
      "contract_number",
      "contract_date",
      "client",
      "contractor",
      "initial_contracts"
    ]
  },
  // Посреднический договор
  2: {
    fields: [
      "subject_type",
      "action_type",
      "contract_number",
      "vat_included",
      "amount",
      "contract_date",
      "creatives_registrar",
      "client",
      "contractor",
      "preferred_ord_account",
      "initial_contracts",
      "is_agent_acting_for_publisher",
      "comment"
    ],
    required: [
      "subject_type",
      "action_type",
      "contract_number",
      "contract_date",
      "client",
      "contractor",
      "initial_contracts",
      "is_agent_acting_for_publisher"
    ]
  },
  // Дополнительное соглашение к имеющемуся договору
  3: {
    fields: [
      "contract_number",
      "vat_included",
      "amount",
      "contract_date",
      "creatives_registrar",
      "client",
      "contractor",
      "preferred_ord_account",
      "parent_contract",
      "initial_contracts",
      "comment"
    ],
    required: ["initial_contracts", "contract_date", "parent_contract", "client", "contractor"]
  },
  // Договор саморекламы
  4: { fields: ["self_ad_participant", "comment"], required: ["self_ad_participant"] }
};

export default fieldsByType;
