import { ParticipantFormDV } from "constants/defaultValues/ParticipantFormDV";
import EditorFooter from "Layout/EditorFooter";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import TFormAddPossibleStatuses from "types/TFormAddPossibleStatuses";
import { scrollToError } from "utils/scrollToError";
import { ParticipantForm } from "./components/form";
import useAddParticipant from "./hooks/useAddParticipant";

const ParticipantAddForm = () => {
  const [status, setStatus] = useState<TFormAddPossibleStatuses>("ACTIVE");
  const participantLastSearch = useSelector(
    (state: StateProps) => state.LastSearchParams.participant
  );
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError
  } = useForm({ defaultValues: ParticipantFormDV });

  const add = useAddParticipant(participantLastSearch, watch, setError);

  const onSubmit = handleSubmit(
    () => {
      add({
        participant: {
          ...watch(),
          status: status === "DRAFT" ? 2 : 1
        }
      });
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  return (
    <div>
      <h1>Добавление контрагента</h1>
      <ParticipantForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={
          <EditorFooter
            left={
              <Button type="submit" color="primary" outline onClick={() => setStatus("DRAFT")}>
                Сохранить черновик
              </Button>
            }
            right={
              <>
                <Button
                  href={`#/participant/${participantLastSearch}`}
                  tag="a"
                  color="primary"
                  outline
                >
                  Отменить
                </Button>
                <Button type="submit" color="primary" onClick={() => setStatus("ACTIVE")}>
                  Добавить
                </Button>
              </>
            }
          />
        }
        watch={watch}
        isDraft={status === "DRAFT"}
      />
    </div>
  );
};

export default ParticipantAddForm;
