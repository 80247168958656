import { PiTelegramLogoLight } from "react-icons/pi";
import { Card, CardBody } from "reactstrap";

const TGChanel = () => {
  return (
    <div className="col-12 col-md-4 col-lg-3">
      <Card className="he-100">
        <CardBody className="d-flex gap-2 align-items-center flex-column p-lg-4">
          <PiTelegramLogoLight size={40} />
          <p className="m-0 fs-5 fw-bold">Телеграм-канал</p>
          <p className="text-center">
            Подпишитесь на&nbsp;канал и следите за&nbsp;обновлениями и новостями маркировки рекламы
          </p>
          <a
            href="http://t.me/advmarker"
            className="btn btn-outline-primary d-block w-100"
            target="_blank"
            rel="noreferrer"
            style={{ marginTop: "auto" }}
          >
            Подписаться
          </a>
        </CardBody>
      </Card>
    </div>
  );
};

export default TGChanel;
