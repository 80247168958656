import { useState } from "react";

const useIsOpenControl = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);
  const setOpen = () => setIsOpen(true);
  const setClose = () => setIsOpen(false);
  return { isOpen, setIsOpen, toggle, setOpen, setClose };
};

export default useIsOpenControl;
