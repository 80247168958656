import { addPlatform } from "api/platform_add";
import { AxiosError } from "axios";
import { FieldValues, UseFormSetError, UseFormWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { notVisibleFieldsPlatform } from "../constants";

const useAddPlatform = (
  platformLastSearch: string,
  watch: UseFormWatch<FieldValues>,
  setError: UseFormSetError<FieldValues>
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: addPlatform,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/platform/${platformLastSearch}`,
        navigate,
        false,
        `Платформа ${
          watch("platform_name") ? `"${watch("platform_name")}"` : `[${p.data.data.id}]`
        } добавлена`
      ),
    onError: (p) => onErrorMutation(p as AxiosError, setError, notVisibleFieldsPlatform)
  });

  return mutate;
};

export default useAddPlatform;
