import { INotification } from "models";
import { Slide, toast } from "react-toastify";

const createNotification = (
  tag: INotification["tags"],
  message: INotification["message"],
  autoClose?: number
) => {
  switch (tag) {
    case "info":
    case "success":
      toast[tag](message, {
        position: "bottom-right",
        theme: "colored",
        autoClose: autoClose || 5000,
        transition: Slide
      });
      break;
    case "warning":
    case "error":
      toast[tag](message, {
        position: "bottom-right",
        theme: "colored",
        autoClose: autoClose || false,
        transition: Slide
      });
      break;
    default:
      toast(message, {
        position: "bottom-right",
        theme: "colored",
        autoClose: autoClose || 5000,
        transition: Slide
      });
      break;
  }
};

export default createNotification;
