import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TSearchParamsRequired } from "types/TSearchParams";
import { OrderingVariant } from "ui/SortSelector";

export const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "invoice_number",
    title: "№ акта ↑"
  },
  {
    order: "-invoice_number",
    title: "№ акта ↓"
  },
  {
    order: "invoice_date",
    title: "Дата акта ↑"
  },
  {
    order: "-invoice_date",
    title: "Дата акта ↓"
  }
];

export const defaultSearchParams: TSearchParamsRequired<TInvoiceFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: orderVariants[1].order,
  filter: {
    ord_account: [],
    combined_status: [],
    start_date: undefined,
    end_date: undefined,
    invoice_number: undefined,
    contract: []
  }
};

export const defaultExportLink = "/api/v1/invoice/export/xls";
