import axios from "axios";
import Cookies from "js-cookie";
import { Contract, ID } from "models";
import { APIData } from "./types";

export const getContract = (id?: ID): Promise<APIData<Contract>> => {
  // if (!id) return undefined;
  return axios
    .get(`/api/v1/contract/${id}/`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      },
      timeout: 1000
    })
    .then((res) => {
      return res.data as Promise<APIData<Contract>>;
    });
};
