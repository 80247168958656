import { addContract } from "api/contract_add";
import { AxiosError } from "axios";
import { FieldValues, UseFormSetError, UseFormWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { notVisibleFieldsContract } from "../constants";

const useAddContract = (
  contractLastSearch: string,
  watch: UseFormWatch<FieldValues>,
  setError: UseFormSetError<FieldValues>
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: addContract,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/contract/${contractLastSearch}`,
        navigate,
        false,
        `Договор ${
          watch("contract_number") && watch("contract_number") !== ""
            ? `№${watch("contract_number")}`
            : `[${p.data.data.id}]`
        } добавлен`
      ),
    onError: (err) => onErrorMutation(err as AxiosError, setError, notVisibleFieldsContract)
  });

  return mutate;
};

export default useAddContract;
