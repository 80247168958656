import { ContractNdsTitlesOptions } from "constants/ContractNdsTitles";
import { SelectField } from "ui/Select";
import { FieldProps } from "./types";

const VatIncludedField = ({
  control,
  errors,
  contractLoading,
  isShown,
  isRequired
}: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <SelectField
          name="vat_included"
          title="Наличие НДС"
          control={control}
          errors={errors}
          options={ContractNdsTitlesOptions}
          disabled={contractLoading}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default VatIncludedField;
