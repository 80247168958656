import { getParticipantList } from "api/participant_list";
import {
  combinedStatusOptions,
  onlyErirOptions
} from "constants/selectOptions/CombinedStatusOptions";
import PlatformTypeOptions from "constants/selectOptions/PlatformTypeOptions";
import FilterLayout from "Layout/FilterLayout";
import { FormEvent, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { TPlatformFilter } from "types/TPlatformFilter";
import { CheckBoxField, PredictiveSearchField, selectColorSchemes, SelectField } from "ui";
import useOrdAccountsList from "utils/useOrdAccountsList";

type Props = {
  filter: TPlatformFilter;
  clearFilter: TPlatformFilter;
  onFilterClick: (_f: TPlatformFilter) => void;
  onClose: () => void;
};

export const PlatformFilter = ({ filter, clearFilter, onFilterClick, onClose }: Props) => {
  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  const [searchParticipantsValue, setSearchParticipantsValue] = useState<string>();
  const [statusTitlesOptions, setStatusTitlesOptions] = useState(
    filter.ord_account?.length ? onlyErirOptions : combinedStatusOptions
  );

  const { control, watch, reset, setValue } = useForm({
    defaultValues: filter
  });

  const {
    mutate: participantsRequest,
    isLoading: participantsLoading,
    data: participantsResponse
  } = useMutation({
    mutationFn: () =>
      getParticipantList(
        searchParticipantsValue
          ? { search: searchParticipantsValue }
          : { filter: { id: watch("owner") } }
      )
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onFilterClick(watch());
  };

  const onReset = () => {
    reset(clearFilter);
    onFilterClick(clearFilter);
  };

  return (
    <FilterLayout onSubmit={onSubmit} onReset={onReset} onClose={onClose}>
      <SelectField
        name="platform_type"
        control={control as unknown as Control<FieldValues, any>}
        title="Тип"
        isMulti
        options={PlatformTypeOptions}
      />
      <SelectField
        name="ord_account"
        control={control as unknown as Control<FieldValues, any>}
        title="Аккаунт в ОРД"
        isMulti
        options={ordAccountsTitlesOptions}
        disabled={ordAccountsLoading || ordAccountsError}
        onChange={(newVal) => {
          if (newVal && (newVal as (string | number)[]).length) {
            setValue(
              "combined_status",
              watch("combined_status")?.filter((st) => st === 4 || st === 5 || st === 6)
            );
            setStatusTitlesOptions(onlyErirOptions);
          } else {
            setStatusTitlesOptions(combinedStatusOptions);
          }
        }}
      />
      <SelectField
        name="combined_status"
        control={control as unknown as Control<FieldValues, any>}
        title="Статус"
        isMulti
        options={statusTitlesOptions}
        colorSchemes={{
          4: selectColorSchemes.yellow,
          5: selectColorSchemes.green,
          6: selectColorSchemes.red
        }}
      />
      <PredictiveSearchField
        name="owner"
        title="Владелец площадки"
        optionKey={["name", "inn"]}
        optionKeyLabels={["Название", "ИНН"]}
        inputValue={searchParticipantsValue}
        setInputValue={setSearchParticipantsValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={participantsLoading}
        request={participantsRequest}
        response={participantsResponse}
        clearable
      />
      <div className="col-sm-9">
        <CheckBoxField
          name="platform_url__isempty"
          title="Нет URL"
          control={control}
          isChecked={watch("platform_url__isempty") ?? true}
        />
      </div>
    </FilterLayout>
  );
};
