import { BsSearch } from "react-icons/bs";
import { components } from "react-select";

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <BsSearch />
      </components.DropdownIndicator>
    )
  );
};

export default DropdownIndicator;
