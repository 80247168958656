import cn from "classnames";
import { CSSProperties, HTMLAttributeAnchorTarget } from "react";
import { IconType } from "react-icons/lib";

type TProps = {
  href: string;
  target?: HTMLAttributeAnchorTarget;
  text?: string;
  icon: IconType;
  direction?: "ltr" | "rtl";
  size?: "sm" | "lg";
  className?: string;
  style?: CSSProperties;
  textClassName?: string;
};

const LinkWithIcon = ({
  href,
  target,
  text,
  icon,
  direction = "ltr",
  size = "sm",
  className,
  style,
  textClassName
}: TProps) => (
  <a
    href={href}
    target={target}
    rel={target === "_blank" ? "noreferrer" : undefined}
    className={cn(
      "d-flex",
      "align-items-center",
      "gap-2",
      {
        "fs-5": size === "lg",
        "flex-row-reverse": direction === "rtl",
        "flex-row": direction === "ltr"
      },
      className
    )}
    style={style}
  >
    {icon({ size: size === "lg" ? 24 : 16 })}
    <span className={textClassName}>{text || href}</span>
  </a>
);

export default LinkWithIcon;
