import { UserInfo } from "models";
import { useQuery } from "react-query";
import { APIData } from "./types";

export const useUserInfo = () => {
  return useQuery<APIData<UserInfo>, Error>(["user_info"], () =>
    fetch("/api/v1/user_info/", {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }).then((res) => res.json())
  );
};
