import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { FormGroup } from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";
import FieldLabel from "ui/FieldLabel";

type Props = {
  name: string;
  title: string;
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  isRequired?: boolean;
  isStared?: boolean;
  errorText?: string;
  disabled?: boolean;
};

export const PhoneNumberField = ({
  name,
  title,
  control,
  errors,
  isRequired,
  isStared,
  errorText,
  disabled
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormGroup>
          <FieldLabel
            title={title}
            isRequired={isRequired || isStared}
            style={{ marginBottom: 0 }}
          />
          <PhoneInput
            placeholder={title}
            specialLabel=""
            value={`${value}`}
            onChange={onChange}
            inputProps={{ required: isRequired }}
            inputStyle={{ marginTop: ".5rem" }}
            disabled={disabled}
            onBlur={onBlur}
          />
          <FieldFeedback name={name} errors={errors} errorText={errorText} />
        </FormGroup>
      )}
    />
  );
};
