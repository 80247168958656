import { ID } from "models";
import { CSSProperties, ReactElement } from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Tooltip from "ui/Tooltip";

const QuestionTooltip = ({
  text,
  id,
  style,
  tooltipMaxWidth
}: {
  text: string | ReactElement;
  id: ID;
  style: CSSProperties;
  tooltipMaxWidth?: string;
}) => (
  <Tooltip
    id={id}
    target={
      <BsFillQuestionCircleFill id={id.toString()} style={{ color: "var(--bs-gray)", ...style }} />
    }
    alignLeft
    maxWidth={tooltipMaxWidth || "20em"}
  >
    {text}
  </Tooltip>
);

export default QuestionTooltip;
