import { getPlatformList } from "api/platform_list";
import { APIListData } from "api/types";
import { AxiosError } from "axios";
import { Platform } from "models";
import { useState } from "react";
import { useQuery } from "react-query";
import { TParticipantFilter } from "types/TParticipantFilter";
import { TSearchParamsRequired } from "types/TSearchParams";

const useLoadPlatforms = ({
  searchParams
}: {
  searchParams: TSearchParamsRequired<TParticipantFilter>;
}) => {
  const [count, setCount] = useState(0);

  const { isLoading, error, data, refetch } = useQuery<APIListData<Platform>, AxiosError>({
    queryKey: [
      "platform_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    queryFn: () =>
      getPlatformList({
        page: searchParams.page,
        size: searchParams.page_size,
        search: searchParams.search,
        filter: searchParams.filter,
        ordering: searchParams.ordering
      }),
    onSuccess: ({ data }) => {
      if (data.count !== count) setCount(data.count);
    }
  });

  return {
    isLoading,
    error,
    refetch,
    count,
    platforms: data?.data?.results || ([] as Platform[])
  };
};

export default useLoadPlatforms;
