import ErrorComponent from "Components/ErrorComponent";
import EditorFooter from "Layout/EditorFooter";
import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { TEditorFooters } from "types/TEditorFooters";
import { scrollToError } from "utils/scrollToError";
import { PlatformForm } from "./components/form";
import useDeletePlatform from "./hooks/useDeletePlatform";
import useEditPlatform from "./hooks/useEditPlatform";
import useLoadPlatform from "./hooks/useLoadPlatform";
import { PosiblePlatformStatuses } from "./types";

type Props = {
  id: ID;
};

const PlatformEditForm = ({ id }: Props) => {
  const platformLastSearch = useSelector((state: StateProps) => state.LastSearchParams.platform);
  const [status, setStatus] = useState<PosiblePlatformStatuses>("LOADING");

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
    setError
  } = useForm();

  const { isLoading, error, searchParticipantsValueDefault } = useLoadPlatform(
    id,
    reset,
    setStatus
  );
  const edit = useEditPlatform(id, platformLastSearch, watch("platform_name"), setError);
  const del = useDeletePlatform(id, platformLastSearch, watch("platform_name"));

  const onSubmit = handleSubmit(
    () => {
      edit({
        id,
        platform: {
          platform_url: watch("no_url") ? "" : (watch("platform_url") as string),
          platform_type: watch("platform_type") as number,
          platform_name: watch("platform_name") as string,
          is_owner: watch("is_owner") as boolean,
          owner: (watch("owner") as number) ?? undefined,
          status: status === "DRAFT" ? 2 : 1
        }
      });
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  if (error) {
    return <ErrorComponent error={error.message} status={error.response?.status} isEmbedded />;
  }

  const buttons: TEditorFooters<PosiblePlatformStatuses> = {
    DRAFT: (
      <EditorFooter
        left={
          <>
            <Button
              type="submit"
              color="danger"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                del(id);
              }}
            >
              Удалить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("DRAFT")}
            >
              Сохранить черновик
            </Button>
          </>
        }
        right={
          <>
            <Button
              href={`#/platform/${platformLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Добавить
            </Button>
          </>
        }
      />
    ),
    ACTIVE: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/platform/${platformLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Сохранить
            </Button>
          </>
        }
      />
    ),
    SYNCHRONIZED_OR_SYNCHRONIZING: (
      <EditorFooter
        right={
          <Button
            href={`#/platform/${platformLastSearch}`}
            tag="a"
            color="primary"
            outline
            disabled={isLoading}
          >
            Закрыть
          </Button>
        }
      />
    ),
    SYNC_ERROR: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/platform/${platformLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Сохранить
            </Button>
          </>
        }
      />
    ),
    LOADING: <EditorFooter />
  };

  return (
    <div>
      <h1>Редактирование платформы {watch("platform_name")}</h1>

      <PlatformForm
        id={id}
        setValue={setValue}
        control={control}
        watch={watch}
        errors={errors}
        buttons={buttons[status]}
        onSubmit={onSubmit}
        isLoading={isLoading}
        isDraft={status === "DRAFT"}
        searchParticipantsValueDefault={searchParticipantsValueDefault}
      />
    </div>
  );
};

export default PlatformEditForm;
