import creative_status from "dictionaries/creative_status.json";
import chunk from "lodash/chunk";
import { NEW_Creative } from "models";
import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Pagination } from "ui";
import StatusCell from "ui/StatusDisplay/StatusCell";
import getLastPageNumber from "utils/getLastPageNumber";
import getTextShortened from "utils/getTextShortened";
type Props = {
  isOpen: boolean;
  toggle: () => void;
  creatives: NEW_Creative[] | undefined;
  label: string | number | undefined;
};

const CreativesModal = ({ isOpen, toggle, creatives, label }: Props) => {
  const creativesChunks = chunk(creatives, 10);
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="p-0 modal-shadow-none">
      <ModalHeader toggle={toggle}>Креативы группы {label}</ModalHeader>
      <ModalBody>
        {creatives && creatives.length ? (
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Наименование креатива</th>
                <th>Токен креатива</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              {creativesChunks[currentPage].map((creative) => {
                const creativeTitle = getTextShortened(
                  creative.title ||
                    creative.media?.[0]?.data?.split("/").at(-1) ||
                    creative.texts?.[0]?.data ||
                    "",
                  25
                );

                return (
                  <tr key={creative.id}>
                    <td>{creative.id}</td>
                    <td>{creativeTitle}</td>
                    <td>{creative.marker}</td>
                    <StatusCell data={creative} dictionary={creative_status} />
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p>Нет креативов</p>
        )}
      </ModalBody>
      {
        getLastPageNumber(creatives?.length || 0, 10) > 1 &&
        <ModalFooter className="d-flex justify-content-center">
            <Pagination
              currentPage={currentPage}
              lastPageNumber={getLastPageNumber(creatives?.length || 0, 10)}
              setCurrentPage={setCurrentPage}
            />
        </ModalFooter>
      }
    </Modal>
  );
};

export default CreativesModal;
