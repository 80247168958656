const FILE_SIZES_NAMES = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const KILO = 1024;

const getFormatBytes = (bytes: number | string, decimals?: number): string => {
  bytes = +bytes;

  if (bytes == 0) return "0 Bytes";

  const dm = decimals || 2;
  const i = Math.floor(Math.log(bytes) / Math.log(KILO));

  return parseFloat((bytes / Math.pow(KILO, i)).toFixed(dm)) + " " + FILE_SIZES_NAMES[i];
};

export default getFormatBytes;
