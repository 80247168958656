import { editInvoice } from "api/invoice_edit";
import { AxiosError } from "axios";
import { ID } from "models";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { notVisibleFieldsInvoice } from "../constants";

const useEditInvoice = (
  id: ID,
  invoiceLastSearch: string,
  n: string | undefined,
  setError: UseFormSetError<FieldValues>
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: editInvoice,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/invoice/${invoiceLastSearch}`,
        navigate,
        false,
        `Акт ${n ? `№${n}` : `[${id}]`} изменен`
      ),
    onError: (err) => onErrorMutation(err as AxiosError, setError, notVisibleFieldsInvoice)
  });

  return mutate;
};

export default useEditInvoice;
