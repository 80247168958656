import axios from "axios";
import Cookies from "js-cookie";
import { TFileUploadFailResponse, TFileUploadResponse } from "types/ResponseTypes";

export type ImportVariant = "participant" | "contract" | "invoice" | "platform";

export const importFunction = (file: FormData, variant: ImportVariant) => {
  return axios
    .post(`/api/v1/${variant}/import/xls`, file, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "attachment"
      }
    })
    .then(({ data }: { data: TFileUploadResponse }) => data)
    .catch(({ response: { data } }: { response: { data: TFileUploadFailResponse } }) => {
      throw typeof data === "string"
        ? { error_message: "Произошла ошибка. Повторите позже." }
        : data;
    });
};
