import EditorFooter from "Layout/EditorFooter";
import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { TEditorFooters } from "types/TEditorFooters";
import { scrollToError } from "utils/scrollToError";
import { StatisticsForm } from "./components/form";

type Props = {
  id: ID;
};

type PosibleStatuses = "DRAFT" | "ACTIVE" | "LOADING";

const StatisticsEditForm = ({ id }: Props) => {
  const statisticsLastSearch = useSelector(
    (state: StateProps) => state.LastSearchParams.statistics
  );
  const [amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number>();
  const [status, setStatus] = useState<PosibleStatuses>("LOADING");

  // const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue
  } = useForm();

  // const { mutate: edit } = useMutation({
  //   mutationFn: editStatistics,
  //   onSuccess: (p) => {},
  //   onError: (err) => onErrorMutation(err as AxiosError, setError)
  // });

  const onSubmit = handleSubmit(
    (_, event) => {
      // TODO: добавить запрос редактирования
      console.log(event, amountFromAllocationsFile, id);
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  const buttons: TEditorFooters<PosibleStatuses> = {
    DRAFT: (
      <EditorFooter
        left={
          <>
            <Button
              type="submit"
              color="danger"
              // disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                // del(id);
              }}
            >
              Удалить
            </Button>
            <Button
              type="submit"
              color="primary"
              // disabled={isLoading}
              onClick={() => setStatus("DRAFT")}
            >
              Сохранить черновик
            </Button>
          </>
        }
        right={
          <>
            <Button
              href={`#/statistics/${statisticsLastSearch}`}
              tag="a"
              color="primary"
              outline
              // disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              // disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Отправить в ЕРИР
            </Button>
          </>
        }
      />
    ),
    ACTIVE: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/statistics/${statisticsLastSearch}`}
              tag="a"
              color="primary"
              outline
              // disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              // disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Отправить в ЕРИР
            </Button>
          </>
        }
      />
    ),
    LOADING: <EditorFooter />
  };

  return (
    <div>
      <h1>Редактирование статистики без акта</h1>
      <StatisticsForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons[status]}
        watch={watch}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        isDraft={status === "DRAFT"}
        setValue={setValue}
      />
    </div>
  );
};

export default StatisticsEditForm;
