import { InputField } from "ui/Input";
import { FieldProps } from "./types";

const AmountField = ({ control, errors, contractLoading, isShown, isRequired }: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <InputField
          name="amount"
          title="Сумма договора в рублях"
          pattern={/^[\d ]*[,.]?\d*$/}
          control={control}
          errors={errors}
          errorText="Только числа"
          disabled={contractLoading}
          isNumber
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};
export default AmountField;
