import { InvoiceAddForm, InvoiceEditForm } from "Components/InvoiceForm";
import { useParams } from "react-router-dom";

const InvoiceFormPage = () => {
  const { id } = useParams();

  return (
    <>
      {id === "add" ? <InvoiceAddForm /> : null}
      {id && +id ? <InvoiceEditForm id={+id} /> : null}
    </>
  );
};

export default InvoiceFormPage;
