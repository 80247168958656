// import { IPropsContract } from "api/contract_list";
import { getContractList, IPropsContract } from "api/contract_list";
import { getCreativeList, IPropsCreative } from "api/creative_list";
import { getInvoiceList, IPropsInvoice } from "api/invoice_list";
import { getParticipantList, IPropsParticipant } from "api/participant_list";
import { getPlatformList, IPropsPlatform } from "api/platform_list";

export const searchMutationContract =
  (currentSearch?: string | null, onLoadSearch?: string | null, rest?: IPropsContract) => () =>
    getContractList({
      search: onLoadSearch
        ? currentSearch === undefined
          ? onLoadSearch
          : currentSearch || ""
        : currentSearch || undefined,
      ...rest
    });

export const searchMutationCreative =
  (currentSearch?: string | null, onLoadSearch?: string | null, rest?: IPropsCreative) => () =>
    getCreativeList({
      search: onLoadSearch
        ? currentSearch === undefined
          ? onLoadSearch
          : currentSearch || ""
        : currentSearch || undefined,
      ...rest
    });

export const searchMutationInvoice =
  (currentSearch?: string | null, onLoadSearch?: string | null, rest?: IPropsInvoice) => () =>
    getInvoiceList({
      search: onLoadSearch
        ? currentSearch === undefined
          ? onLoadSearch
          : currentSearch || ""
        : currentSearch || undefined,
      ...rest
    });

export const searchMutationParticipant =
  (currentSearch?: string | null, onLoadSearch?: string | null, rest?: IPropsParticipant) => () =>
    getParticipantList({
      search: onLoadSearch
        ? currentSearch === undefined
          ? onLoadSearch
          : currentSearch || ""
        : currentSearch || undefined,
      ...rest
    });

export const searchMutationPlatform =
  (currentSearch?: string | null, onLoadSearch?: string | null, rest?: IPropsPlatform) => () =>
    getPlatformList({
      search: onLoadSearch
        ? currentSearch === undefined
          ? onLoadSearch
          : currentSearch || ""
        : currentSearch || undefined,
      ...rest
    });
