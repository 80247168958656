import CountryCodeOptions from "constants/selectOptions/CountryCodeOptions";
import LegalParticipantFormOptions from "constants/selectOptions/LegalParticipantFormOptions";
import { BaseSyntheticEvent, ReactNode } from "react";
import { Control, FieldErrors, FieldValues, UseFormWatch } from "react-hook-form/dist/types";
import { Form } from "reactstrap";
import { InputField, PhoneNumberField, SelectField } from "ui/index";
import getErrorText from "utils/getErrorText";

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  onSubmit: (_e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  buttons: ReactNode;
  watch: UseFormWatch<FieldValues>;
  isLoading?: boolean;
  isDraft?: boolean;
};

export const ParticipantForm = ({
  control,
  errors,
  onSubmit,
  buttons,
  watch,
  isLoading,
  isDraft
}: Props) => {
  const isForeignRegistration = watch("legal_form") === 4 || watch("legal_form") === 5;

  return (
    <Form
      onSubmit={(event) => {
        void onSubmit(event);
      }}
      className="mt-3 mb-3"
    >
      <div className="row">
        <div className="col-sm-12">
          <SelectField
            name="legal_form"
            title="Тип налогового статуса"
            control={control}
            errors={errors}
            options={LegalParticipantFormOptions}
            errorText={getErrorText(errors, "legal_form", "Должно быть выбрано")}
            isRequired={!isDraft}
            isStared
            disabled={isLoading || watch("status") === 1} // статус Активный
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <InputField
            control={control}
            name="inn"
            title={isForeignRegistration ? "ИНН, либо его аналог в стране регистрации" : "ИНН"}
            errors={errors}
            pattern={/^[0-9]*$/}
            maxLength={isForeignRegistration ? undefined : 12}
            minLength={isForeignRegistration ? undefined : 10}
            errorText={getErrorText(
              errors,
              "inn",
              `Должно быть число.${isForeignRegistration ? "" : " Длина от 10 до 12 символов."}`
            )}
            isRequired={!isDraft}
            isStared
            disabled={isLoading || watch("status") === 1} // статус Активный
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <InputField
            control={control}
            name="name"
            title="Название контрагента"
            errors={errors}
            errorText={getErrorText(errors, "name", "Должно быть заполнено.")}
            isRequired
            disabled={isLoading}
          />
        </div>
      </div>
      {watch("legal_form") === 4 ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <InputField
                control={control}
                name="registration_number"
                title="Регистрационный номер, либо его аналог"
                errors={errors}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <SelectField
                name="registration_country_code"
                title="Код страны (ОКСМ)"
                control={control}
                errors={errors}
                options={CountryCodeOptions}
                errorText={getErrorText(errors, "registration_country_code", "Три цифры.")}
                isRequired={!isDraft}
                isStared
                disabled={isLoading}
              />
            </div>
          </div>
        </>
      ) : null}
      {watch("legal_form") === 5 ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <PhoneNumberField
                control={control}
                name="mobile_phone"
                title="Номер телефона"
                errors={errors}
                errorText={getErrorText(errors, "mobile_phone")}
                isRequired={!isDraft}
                isStared
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <InputField
                control={control}
                name="epay_number"
                title="Номер электронного средства платежа"
                errors={errors}
                pattern={/^[0-9]*$/}
                errorText={getErrorText(errors, "epay_number", "Должно быть число.")}
                isRequired={!isDraft}
                isStared
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <SelectField
                name="registration_country_code"
                title="Код страны (ОКСМ)"
                control={control}
                errors={errors}
                options={CountryCodeOptions}
                errorText={getErrorText(errors, "registration_country_code", "Три цифры.")}
                isRequired={!isDraft}
                isStared
                disabled={isLoading}
              />
            </div>
          </div>
        </>
      ) : null}
      {buttons}
    </Form>
  );
};
