import { TNumberTitle } from "types/TNumberTitle";
import createOptions from "utils/createOptions";

export const ContractNdsTitles: TNumberTitle = {
  0: "Без НДС",
  1: "С НДС"
};

export const getContractNdsTitle = (n: string | number): string => ContractNdsTitles[+n];

export const ContractNdsTitlesOptions = createOptions(ContractNdsTitles);
