import { FaCheck } from "react-icons/fa6";
import Tooltip from "ui/Tooltip";

type Props = {
  ord: string[] | undefined;
  id: string;
  customText?: string;
};

const OrdAccountCheckIcon = ({ ord, id, customText }: Props) =>
  ord ? (
    <Tooltip
      id={id}
      maxWidth="15em"
      target={
        <div className="d-inline-block" style={{ marginLeft: "0.25rem" }}>
          <FaCheck id={id} />
        </div>
      }
    >
      {customText ? customText : "Можно отправлять данные через:"} {ord.join(", ")}
    </Tooltip>
  ) : null;

export default OrdAccountCheckIcon;
