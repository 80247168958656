import { editParticipant } from "api/participant_edit";
import { AxiosError } from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { notVisibleFieldsParticipant } from "../constants";

const useEditParticipant = (
  participantLastSearch: string,
  name: string | undefined,
  setError: UseFormSetError<FieldValues>
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: editParticipant,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/participant/${participantLastSearch}`,
        navigate,
        false,
        `Контрагент ${name ? `"${name}" ` : ""}изменен`
      ),
    onError: (err) => onErrorMutation(err as AxiosError, setError, notVisibleFieldsParticipant)
  });

  return mutate;
};

export default useEditParticipant;
