import Cookies from "js-cookie";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { setParticipantLastSearchParams } from "reducers/LastSearchParams";
import { Dispatch, UnknownAction } from "redux";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import { defaultSearchParams } from "../constants";

const useParticipantsSerchParams = ({
  searchProperties,
  dispatch
}: {
  searchProperties: string;
  dispatch: Dispatch<UnknownAction>;
}) => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeParticipant");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.legal_form;
    const legal_form = newSearchParams.legal_form
      ? getNumberArrayFromSearchParam(newSearchParams.legal_form)
      : defaultSearchParams.filter?.legal_form;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const role = newSearchParams.role
      ? getNumberArrayFromSearchParam(newSearchParams.role)
      : defaultSearchParams.filter?.role;
    setSearchParams({
      page,
      search,
      page_size,
      ordering,
      filter: {
        legal_form,
        ord_account,
        combined_status,
        role
      }
    });
    dispatch(setParticipantLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  return searchParams;
};

export default useParticipantsSerchParams;
