import ContractTypeOptions from "constants/selectOptions/ContractTypeOptions";
import { Contract } from "models";
import { Fragment, ReactNode, useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { Form } from "reactstrap";
import { SelectField } from "ui";
import fieldsByType from "./constants";
import fieldsComponents from "./fields";
import { FieldKeys } from "./fields/types";

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  onSubmit: () => Promise<void>;
  buttons: ReactNode;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  resetField: UseFormResetField<FieldValues>;
  contractLoading?: boolean;
  InitialContractsValues?: Contract[];
  isDraft?: boolean;
};

export const ContractForm = ({
  control,
  errors,
  onSubmit,
  buttons,
  watch,
  setValue,
  resetField,
  contractLoading,
  InitialContractsValues,
  isDraft
}: Props) => {
  const [currentFields, setCurrentFields] = useState<{
    fields: FieldKeys[];
    required: FieldKeys[];
  }>({ fields: [], required: [] });

  const contract_type = watch("contract_type") as 1 | 2 | 3 | 4 | undefined;

  useEffect(() => {
    if (contract_type) setCurrentFields(fieldsByType[contract_type]);
  }, [contract_type]);

  return (
    <Form onSubmit={onSubmit} className="mt-3 mb-3">
      <div className="row">
        <div className="col-sm-9">
          <SelectField
            name="contract_type"
            title="Тип договора"
            control={control}
            errors={errors}
            options={ContractTypeOptions}
            disabled={contractLoading}
            isRequired={!isDraft}
            isStared
          />
        </div>
      </div>

      {(Object.keys(fieldsComponents) as FieldKeys[]).map((key) => {
        const isInArray = !!watch("contract_type") && currentFields.fields.includes(key);
        const isCorrectClientRoleForInitialContracts =
          (watch("client_role") !== 1 && key === "initial_contracts") ||
          key !== "initial_contracts";
        const isShown = isInArray && isCorrectClientRoleForInitialContracts;
        const isRequired = currentFields.required.includes(key) ? !isDraft : null;

        return (
          <Fragment key={key}>
            {fieldsComponents[key]({
              control,
              errors,
              watch,
              setValue,
              resetField,
              contractLoading,
              InitialContractsValues,
              isRequired,
              isShown
            })}
          </Fragment>
        );
      })}
      {buttons}
    </Form>
  );
};
