export type EditorFooterProps = {
  left?: JSX.Element;
  center?: JSX.Element;
  right?: JSX.Element;
};

const EditorFooter = ({ left, center, right }: EditorFooterProps) => (
  <div className="d-flex gap-3 justify-content-between">
    <div className="d-flex gap-3">{left}</div>
    {center ? <div className="d-flex gap-3">{center}</div> : null}
    <div className="d-flex gap-3">{right}</div>
  </div>
);

export default EditorFooter;
