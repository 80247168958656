import { ReactNode } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import useIsOpenControl from "utils/useIsOpenControl";

export const AttributesDropdown = ({
  title,
  children
}: {
  title: string | ReactNode;
  children: ReactNode;
}) => {
  const { isOpen, toggle } = useIsOpenControl();

  return (
    <>
      <Button
        color="secondary"
        onClick={toggle}
        className="w-100 d-flex justify-content-between align-items-center"
        style={{
          marginBlock: "1rem"
        }}
      >
        <span>{title}</span>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className="shadow-none">
          <CardBody>{children}</CardBody>
        </Card>
      </Collapse>
    </>
  );
};
