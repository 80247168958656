import axios from "axios";
import Cookies from "js-cookie";
import { NEW_CreativeGroup } from "models";

export const addCreativeGroupNew = (creativeGroup: NEW_CreativeGroup) => {
  return axios
    .post(`/api/v1/creative_group_new/`, creativeGroup, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json"
      }
    })
    .then((res) => {
      return res;
    });
};
