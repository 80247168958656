import { getParticipantList } from "api/participant_list";
import {
  combinedStatusOptions,
  onlyErirOptions
} from "constants/selectOptions/CombinedStatusOptions";
import ContractTypeOptions from "constants/selectOptions/ContractTypeOptions";
import ParticipantClientRolesOptions from "constants/selectOptions/ParticipantClientRolesOptions";
import ParticipantContractorRolesOptions from "constants/selectOptions/ParticipantContractorRolesOptions";
import FilterLayout from "Layout/FilterLayout";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { FormEvent, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { TContractFilter } from "types/TContractFilter";
import { CheckBoxField, PredictiveSearchField, selectColorSchemes, SelectField } from "ui";
import useOrdAccountsList from "utils/useOrdAccountsList";

type Props = {
  filter: TContractFilter;
  filterClear: TContractFilter;
  onFilterClick: (_f: TContractFilter) => void;
  onClose: () => void;
};

export const ContractFilter = ({ filter, filterClear, onFilterClick, onClose }: Props) => {
  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  const [searchClientValue, setSearchClientValue] = useState<string>();
  const [searchContractorValue, setSearchContractorValue] = useState<string>();
  const [statusTitlesOptions, setStatusTitlesOptions] = useState(
    filter.ord_account?.length ? onlyErirOptions : combinedStatusOptions
  );

  const formDefaultValues: TContractFilter & { onlyInitials?: boolean } = {
    ...filter,
    onlyInitials: isEqual(filter.client_role, [1])
  };

  const { control, watch, reset, setValue } = useForm({
    defaultValues: formDefaultValues
  });

  const {
    mutate: clientsRequest,
    isLoading: clientsLoading,
    data: clientsResponse
  } = useMutation({
    mutationFn: () => {
      const client = watch("client");
      const clientArray = [];
      if (Array.isArray(client)) {
        clientArray.push(...client);
      } else if (client) {
        clientArray.push(+client);
      }
      return getParticipantList(
        searchClientValue
          ? { search: searchClientValue }
          : { filter: client ? { id: clientArray } : {} }
      );
    }
  });

  const {
    mutate: contractorsRequest,
    isLoading: contractorsLoading,
    data: contractorsResponse
  } = useMutation({
    mutationFn: () => {
      const contractor = watch("contractor");
      const contractorArray = [];
      if (Array.isArray(contractor)) {
        contractorArray.push(...contractor);
      } else if (contractor) {
        contractorArray.push(+contractor);
      }
      return getParticipantList(
        searchContractorValue
          ? { search: searchContractorValue }
          : { filter: contractor ? { id: contractorArray } : {} }
      );
    }
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formValues: TContractFilter = omit(
      {
        ...watch(),
        client_role: watch("onlyInitials") ? [1] : (watch("client_role") as number[])
      },
      "onlyInitials"
    );
    onFilterClick(formValues);
  };

  const onReset = () => {
    reset(filterClear);
    onFilterClick(filterClear);
  };

  return (
    <FilterLayout onSubmit={onSubmit} onReset={onReset} onClose={onClose}>
      <SelectField
        name="contract_type"
        control={control as unknown as Control<FieldValues, any>}
        title="Тип"
        isMulti
        options={ContractTypeOptions}
      />
      <SelectField
        name="ord_account"
        control={control as unknown as Control<FieldValues, any>}
        title="Аккаунт в ОРД"
        isMulti
        options={ordAccountsTitlesOptions}
        disabled={ordAccountsLoading || ordAccountsError}
        onChange={(newVal) => {
          if (newVal && (newVal as (string | number)[]).length) {
            setValue(
              "combined_status",
              watch("combined_status")?.filter((st) => st === 4 || st === 5 || st === 6)
            );
            setStatusTitlesOptions(onlyErirOptions);
          } else {
            setStatusTitlesOptions(combinedStatusOptions);
          }
        }}
      />
      <SelectField
        name="combined_status"
        control={control as unknown as Control<FieldValues, any>}
        title="Статус"
        isMulti
        options={statusTitlesOptions}
        colorSchemes={{
          4: selectColorSchemes.yellow,
          5: selectColorSchemes.green,
          6: selectColorSchemes.red
        }}
      />
      <SelectField
        name="client_role"
        control={control as unknown as Control<FieldValues, any>}
        title="Выбрать роль заказчика"
        options={ParticipantClientRolesOptions}
        disabled={watch("onlyInitials") as boolean}
        isMulti
        hideArrow
      />
      <SelectField
        name="contractor_role"
        control={control as unknown as Control<FieldValues, any>}
        title="Выбрать роль исполнителя"
        options={ParticipantContractorRolesOptions}
        isMulti
        hideArrow
      />
      <PredictiveSearchField
        name="client"
        title="Заказчик"
        optionKey={["name", "inn"]}
        optionKeyLabels={["Название", "ИНН"]}
        inputValue={searchClientValue}
        setInputValue={setSearchClientValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={clientsLoading}
        request={clientsRequest}
        response={clientsResponse}
        clearable
      />
      <PredictiveSearchField
        name="contractor"
        title="Исполнитель"
        optionKey={["name", "inn"]}
        optionKeyLabels={["Название", "ИНН"]}
        inputValue={searchContractorValue}
        setInputValue={setSearchContractorValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={contractorsLoading}
        request={contractorsRequest}
        response={contractorsResponse}
        clearable
      />
      <div className="col-sm-9">
        <CheckBoxField
          name="onlyInitials"
          title="Только изначальные"
          control={control}
          isChecked={watch("onlyInitials") as boolean}
          extraAction={() => setValue("client_role", [1])}
        />
      </div>
    </FilterLayout>
  );
};
