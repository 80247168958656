import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";

type Props = {
  name: string;
  title: string;
  control: Control<FieldValues, any>;
  errors?: FieldErrors<FieldValues>;
  isRequired?: boolean;
  errorText?: string;
  isChecked: boolean;
  disabled?: boolean;
  extraAction?: (_?: boolean) => void;
};

export const CheckBoxField = ({
  name,
  title,
  control,
  errors,
  isRequired,
  errorText,
  isChecked = false,
  disabled,
  extraAction
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange, onBlur } }) => (
        <FormGroup>
          <Input
            type="checkbox"
            id={name}
            name={name}
            checked={isChecked}
            onChange={() => {
              onChange(!isChecked);
              if (extraAction) extraAction(!isChecked);
              onBlur();
            }}
            disabled={disabled}
          />{" "}
          <Label check for={name} style={{ userSelect: "none" }}>
            {title}
          </Label>
          <FieldFeedback name={name} errors={errors} errorText={errorText} />
        </FormGroup>
      )}
    />
  );
};
