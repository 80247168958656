import { getInvoice } from "api/invoice_item";
import { APIData } from "api/types";
import { AxiosError } from "axios";
import { ID, Invoice } from "models";
import { Dispatch, SetStateAction } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";
import { InitialContractParams } from "../components/form";

const useLoadInvoice = (
  id: ID,
  reset: UseFormReset<FieldValues>,
  setAmountFromAllocationsFile: Dispatch<SetStateAction<number | undefined>>
) => {
  const { isLoading, error, data } = useQuery<APIData<Invoice>, AxiosError>({
    queryKey: ["invoice_item", id],
    queryFn: () => getInvoice(id),
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    onSuccess: ({ data, status }) => {
      if (status) {
        reset({ ...data, noNumber: !data.invoice_number });

        const allocated_amount = data?.extra_fields?.allocated_amount;
        setAmountFromAllocationsFile(allocated_amount ? +allocated_amount : undefined);
      }
    }
  });

  return {
    isLoading,
    error,
    invoiceNumber: data?.data?.invoice_number,
    initialParams: {
      client: data?.data?.extra_fields?.invoice_contract?.client,
      contractor: data?.data?.extra_fields?.invoice_contract?.contractor,
      contractNumber: data?.data?.extra_fields?.invoice_contract?.number
    } as InitialContractParams,
    data
  };
};

export default useLoadInvoice;
