import { getContractList } from "api/contract_list";
import { ID } from "models";
import { useMutation } from "react-query";
import { TContractFilter } from "types/TContractFilter";

const useSearchFinalContractMutation = (
  searchContractsValue?: string,
  clientId?: ID | null,
  contractorId?: ID | null
) =>
  useMutation({
    mutationFn: () => {
      const filter: TContractFilter = {};
      if (clientId) filter.client = clientId;
      if (contractorId) filter.contractor = contractorId;
      return getContractList(searchContractsValue ? { search: searchContractsValue } : { filter });
    }
  });

export default useSearchFinalContractMutation;
