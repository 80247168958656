import { TextAreaField } from "ui/TextArea";
import { FieldProps } from "./types";

const CommentField = ({ control, errors, contractLoading, isShown, isRequired }: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <TextAreaField
          name="comment"
          title="Комментарий к договору"
          control={control}
          errors={errors}
          disabled={contractLoading}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default CommentField;
