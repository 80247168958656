import ContractActionTypeOptions from "constants/selectOptions/ContractActionTypeOptions";
import { SelectField } from "ui/Select";
import { FieldProps } from "./types";

const ActionTypeField = ({ control, errors, contractLoading, isShown, isRequired }: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row">
      <div className="col-sm-9">
        <SelectField
          name="action_type"
          title="Вид деятельности"
          control={control}
          errors={errors}
          options={ContractActionTypeOptions}
          disabled={contractLoading}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default ActionTypeField;
