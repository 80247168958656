import axios from "axios";
import Cookies from "js-cookie";
import { Invoice } from "models";

export const addInvoice = ({ invoice }: { invoice: Invoice }) => {
  return axios
    .post(`/api/v1/invoice/`, invoice, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      }
    })
    .then((res) => {
      return res;
    });
};
