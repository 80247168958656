import { CreativeAddForm } from "Components/CreativeAddForm";
import CreativeEditForm from "Components/CreativeEditForm";
import { useParams } from "react-router-dom";

const CreativeFormPage = () => {
  const { id } = useParams();

  return (
    <>
      {id === "add" ? <CreativeAddForm /> : null}
      {id && +id ? <CreativeEditForm id={+id} /> : null}
    </>
  );
};

export default CreativeFormPage;
