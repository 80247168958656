import cn from "classnames";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { FaFileArrowUp } from "react-icons/fa6";

const AttachmentArea = ({
  getRootProps,
  getInputProps,
  disabled,
  message,
  isExampleLink,
  isErrors
}: {
  getRootProps: <T extends DropzoneRootProps>(_props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(_props?: T | undefined) => T;
  message?: string;
  disabled?: boolean;
  isExampleLink?: boolean;
  isErrors?: boolean;
}) => {
  return (
    <div
      {...getRootProps()}
      aria-disabled={disabled}
      className={cn(
        "dropzone-wrapper",
        "dropzone-wrapper-sm",
        "flex-column",
        "flex-md-row",
        "gap-3",
        "justify-content-center",
        "py-3",
        {
          "w-75": isExampleLink,
          "dropzone-wrapper-error": isErrors,
          "pe-none": disabled
        }
      )}
    >
      <input {...getInputProps()} />
      <p className="dropzone-content w-75">
        {message || "Прикрепите файлы"}
        <FaFileArrowUp size={14} style={{ marginLeft: "5px", marginTop: "-4px" }} />
      </p>
    </div>
  );
};

export default AttachmentArea;
