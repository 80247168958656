import cn from "classnames";
import { ID } from "models";
import { MouseEventHandler, ReactNode } from "react";
import { Button } from "reactstrap";

type Props = {
  text: string;
  icon: ReactNode;
  isFullWidth?: boolean;
  isNoWrap?: boolean;
  type?: "button" | "submit" | "reset";
  outline?: boolean;
  color?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  href?: string;
  download?: boolean | string;
  id?: ID;
  disabled?: boolean;
  dir?: "itt" | "tti"; // icon-to-text | text-to-icon
};

const IconButton = ({
  text,
  icon,
  isFullWidth,
  isNoWrap,
  type,
  outline,
  color,
  className,
  onClick,
  href,
  download,
  id,
  disabled,
  dir
}: Props) => {
  return (
    <Button
      id={id?.toString()}
      type={type || "button"}
      outline={outline}
      disabled={disabled}
      color={color || "primary"}
      className={cn(
        "d-flex",
        "gap-2",
        "justify-content-center",
        "align-items-center",
        {
          "w-100": isFullWidth,
          "text-nowrap": isNoWrap
        },
        className
      )}
      onClick={onClick}
      tag={href ? "a" : "button"}
      href={href}
      download={download}
    >
      {dir === "itt" ? (
        <>
          {icon}
          {text}
        </>
      ) : (
        <>
          {text}
          {icon}
        </>
      )}
    </Button>
  );
};

export default IconButton;
