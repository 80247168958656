import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import useIsOpenControl from "utils/useIsOpenControl";

type Props = {
  sizes: number[];
  currentSize: number;
  setCurrentSize: (_: number) => void;
};

export const PageSizeSelector = ({ sizes, currentSize, setCurrentSize }: Props) => {
  const { isOpen: dropdownOpen, toggle: dropdownOpenToggle } = useIsOpenControl();

  return (
    <Dropdown isOpen={dropdownOpen} toggle={dropdownOpenToggle} direction="up">
      <DropdownToggle caret color="primary">
        {currentSize}
      </DropdownToggle>
      <DropdownMenu>
        {sizes.map((size) => (
          <DropdownItem
            key={size}
            disabled={size === currentSize}
            onClick={() => setCurrentSize(size)}
          >
            {size}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
