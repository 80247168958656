import { ID } from "models";
import { ReactElement } from "react";
import { Label, LabelProps } from "reactstrap";
import QuestionTooltip from "ui/QuestionTooltip";

interface Props extends LabelProps {
  title?: string;
  isRequired?: boolean;
  info?: {
    text: string | ReactElement;
    id: ID;
  };
  isBig?: boolean;
}

const FieldLabel = ({ title, isRequired, info, isBig, ...rest }: Props) => {
  if (!title) return null;

  if (isBig)
    return (
      <p className="fs-4 text-center font-weight-bold">
        {title}
        {isRequired ? <span style={{ color: "var(--bs-red)" }}> *</span> : ""}
      </p>
    );

  return (
    <Label {...rest}>
      {title}
      {isRequired ? <span style={{ color: "var(--bs-red)" }}> *</span> : ""}
      {info ? (
        <QuestionTooltip text={info.text} id={info.id.toString()} style={{ marginLeft: "10px" }} />
      ) : (
        ""
      )}
    </Label>
  );
};

export default FieldLabel;
