import cx from "classnames";
import { useSelector } from "react-redux";
// import ResizeDetector from "react-resize-detector";
import { useResizeDetector } from "react-resize-detector";

import { StateProps } from "reducers";
import AppMain from "../../Layout/AppMain";

const Main = () => {
  const enableMobileMenu = useSelector((state: StateProps) => state.ThemeOptions.enableMobileMenu);
  const enableClosedSidebar = useSelector(
    (state: StateProps) => state.ThemeOptions.enableClosedSidebar
  );

  const { width } = useResizeDetector();

  return (
    <div
      className={cx(
        "app-container",
        "app-theme-white",
        "fixed-header",
        "fixed-sidebar",
        { "closed-sidebar": enableClosedSidebar || (width && width < 768) },
        { "closed-sidebar-mobile": width && width < 768 },
        { "sidebar-mobile-open": enableMobileMenu }
      )}
    >
      <AppMain />
    </div>
  );
};

export default Main;
