import isEqual from "lodash/isEqual";

const createOptions = (
  obj: { [n: string]: string },
  dangerHighlight?: { [n: string]: string | number } | { [n: string]: string | number }[],
  isValueString?: boolean
) => {
  return Object.keys(obj).map((item) => {
    if (dangerHighlight && Array.isArray(dangerHighlight)) {
      const isDisabled = [...dangerHighlight]
        .map((dangerHighlightItem) =>
          isEqual({ ...JSON.parse(obj[item]), ...dangerHighlightItem }, JSON.parse(obj[item]))
        )
        .includes(true);

      return {
        value: isValueString ? item : +item.replace(/[^0-9]/g, ""),
        label: obj[item] ? obj[item] : "(нет)",
        isDisabled: isDisabled
      };
    }

    return {
      value: isValueString ? item : +item.replace(/[^0-9]/g, ""),
      label: obj[item] ? obj[item] : "(нет)",
      isDisabled:
        dangerHighlight &&
        isEqual({ ...JSON.parse(obj[item]), ...dangerHighlight }, JSON.parse(obj[item]))
    };
  });
};

export default createOptions;
