import cn from "classnames";
import { FaBarcode } from "react-icons/fa";
import { Button } from "reactstrap";

const ErrorLayout = ({
  isEmbedded,
  status,
  title,
  subtitle
}: {
  isEmbedded?: boolean;
  status?: number;
  title: string;
  subtitle?: string;
}) => {
  return (
    <div className={cn("app-error", { "app-error__embedded": isEmbedded })}>
      {status ? (
        <>
          <div className="app-error__barcode">
            <FaBarcode size={400} className="app-error__barcode__svg" />
          </div>
          <h3 className="app-error__barcode__status">{status}</h3>
        </>
      ) : null}
      <div className="app-error__text">
        <p className="fs-1 fw-bold">{title}</p>
        {subtitle ? <p className="fs-5">{subtitle}</p> : null}
      </div>
      <Button href="#/" color="primary" size="xl">
        Перейти на главную{" "}
      </Button>
    </div>
  );
};

export default ErrorLayout;
