export const ErrorMessage = ({ error, isEmbedded }: { error?: string; isEmbedded?: boolean }) => {
  if (isEmbedded)
    return <h6 className="fs-2 text-center font-weight-bold mt-5">{error || "Error"}</h6>;

  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <h6 className="mt-5">{error || "Error"}</h6>
      </div>
    </div>
  );
};
