import { getParticipant } from "api/participant_item";
import { APIData } from "api/types";
import { AxiosError } from "axios";
import { ID, Participant } from "models";
import { Dispatch, SetStateAction } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";
import { PosibleParticipantStatuses } from "../types";

const useLoadParticipant = (
  id: ID,
  reset: UseFormReset<FieldValues>,
  setStatus: Dispatch<SetStateAction<PosibleParticipantStatuses>>
) => {
  const { isLoading, error, data } = useQuery<APIData<Participant>, AxiosError>({
    queryKey: ["participant_list", id],
    enabled: !!id,
    queryFn: () => getParticipant(id),
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    onSuccess: ({ status, data }) => {
      if (status) {
        reset(data);

        let st: PosibleParticipantStatuses = "LOADING";

        if (data?.status === 1) {
          st = "ACTIVE";

          const syncStatuses = data.extra_fields?.sync_status.map(({ status }) => status);

          if (
            syncStatuses &&
            (syncStatuses.includes("SYNCHRONIZED") || syncStatuses.includes("IS_SYNCHRONIZING"))
          ) {
            st = "SYNCHRONIZED_OR_SYNCHRONIZING";
          }

          if (syncStatuses && syncStatuses.includes("SYNC_ERROR")) {
            st = "SYNC_ERROR";
          }
        } else if (data?.status === 2) {
          st = "DRAFT";
        }

        setStatus(st);
      }
    }
  });
  return {
    isLoading,
    error,
    data
  };
};

export default useLoadParticipant;
