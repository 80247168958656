import axios from "axios";
import Cookies from "js-cookie";
import { ID, Invoice } from "models";

export const editInvoice = ({ id, invoice }: { id: ID; invoice: Invoice }) => {
  return axios
    .put(`/api/v1/invoice/${id}/`, invoice, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      }
    })
    .then((res) => {
      return res;
    });
};
