import { format } from "date-fns";
import EditorFooter from "Layout/EditorFooter";
import omit from "lodash/omit";
import { Contract } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import TFormAddPossibleStatuses from "types/TFormAddPossibleStatuses";
import { scrollToError } from "utils/scrollToError";
import { ContractForm } from "./components/form";
import useAddContract from "./hooks/useAddContract";

const ContractAddForm = () => {
  const [status, setStatus] = useState<TFormAddPossibleStatuses>("ACTIVE");
  const contractLastSearch = useSelector((state: StateProps) => state.LastSearchParams.contract);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    resetField
  } = useForm();

  const add = useAddContract(contractLastSearch, watch, setError);

  const onSubmit = handleSubmit(
    () => {
      const {
        client,
        contractor,
        subject_type,
        contract_number,
        contract_date,
        creatives_registrar
      } =
        watch("contract_type") == 4
          ? {
              client: watch("self_ad_participant") as Contract["client"],
              contractor: watch("self_ad_participant") as Contract["contractor"],
              subject_type: 1 as Contract["subject_type"], // 'Иное'
              contract_number: "" as Contract["contract_number"],
              contract_date: format(new Date(), "yyyy-MM-dd") as Contract["contract_date"],
              creatives_registrar: 2 as Contract["creatives_registrar"] // 'Исполнитель'
            }
          : {
              client: watch("client") as Contract["client"],
              contractor: watch("contractor") as Contract["contractor"],
              subject_type: watch("subject_type") as Contract["subject_type"],
              contract_number: (watch("contract_number") || "") as Contract["contract_number"],
              contract_date: watch("contract_date") as Contract["contract_date"],
              creatives_registrar: watch("creatives_registrar") as Contract["creatives_registrar"]
            };

      const is_agent_acting_for_publisher =
        watch("is_agent_acting_for_publisher") !== undefined
          ? !!watch("is_agent_acting_for_publisher")
          : undefined;

      add({
        contract: {
          ...omit(watch(), ["noNumber", "self_ad_participant", "contractor_ord", "client_ord"]),
          vat_included: !!(watch("vat_included") as 0 | 1 | undefined),
          is_agent_acting_for_publisher,
          client,
          contractor,
          subject_type,
          contract_number,
          contract_date,
          creatives_registrar,
          status: status === "ACTIVE" ? 1 : 2
        }
      });
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  return (
    <div>
      <h1>Добавление договора</h1>
      <ContractForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={
          <EditorFooter
            left={
              <Button type="submit" color="primary" onClick={() => setStatus("DRAFT")}>
                Сохранить черновик
              </Button>
            }
            right={
              <>
                <Button href={`#/contract/${contractLastSearch}`} tag="a" color="primary" outline>
                  Отменить
                </Button>
                <Button
                  type="submit"
                  color="danger"
                  onClick={() => setStatus("ACTIVE")}
                  // disabled={!watch("allocations_file")}
                >
                  Добавить
                </Button>
              </>
            }
          />
        }
        watch={watch}
        setValue={setValue}
        resetField={resetField}
        isDraft={status === "DRAFT"}
      />
    </div>
  );
};

export default ContractAddForm;
