import { MdOutlineRefresh } from "react-icons/md";
import { components, GroupBase, MenuProps } from "react-select";
import IconButton from "ui/IconButton";
import { TNewVal } from "..";

export const MenuComponent = (
  props: MenuProps<TNewVal, boolean, GroupBase<TNewVal>>,
  isLoadMoreEnabled?: boolean,
  request?: (_?: any) => void,
  optionsLength?: number
) => {
  const numberOfNotSelectedOptions = props.options.length - props.getValue().length;
  const isLoadMoreNeeded = optionsLength && numberOfNotSelectedOptions < optionsLength;

  if (isLoadMoreEnabled && isLoadMoreNeeded)
    return (
      <components.Menu {...props}>
        {props.children}
        <IconButton
          text="Загрузить еще"
          icon={<MdOutlineRefresh />}
          outline
          onClick={request}
          className="my-2 mx-auto"
        />
      </components.Menu>
    );

  return <components.Menu {...props}>{props.children}</components.Menu>;
};
