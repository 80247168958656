import { Button } from "reactstrap";
import Modal from "ui/Modal";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  onContinue: () => void;
};

export const NoAllocationsFileModal = ({ isOpen, toggle, onContinue }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Подтвердите действие"
      footer={
        <>
          <Button color="primary" onClick={onContinue}>
            Продолжить
          </Button>
          <Button onClick={toggle}>Отменить</Button>
        </>
      }
    >
      <p>К акту отсутствуют данные по изначальным договорам с Рекламодателями.</p>
      <p>Продолжить загрузку данных без разаллокации?</p>
    </Modal>
  );
};
