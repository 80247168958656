// import FeedbackFormModal from "Components/FeedbackForm/modal";
import { PiHeadsetLight } from "react-icons/pi";
import { Card, CardBody } from "reactstrap";
import InfoTooltip from "./InfoTooltip";

const SupportService = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="col-12 col-md-4 col-lg-3">
        <Card className="he-100">
          <CardBody className="d-flex gap-2 align-items-center flex-column p-lg-4">
            <InfoTooltip id="supportServiceTooltip" />
            <PiHeadsetLight size={40} />
            <p className="m-0 fs-5 fw-bold">Служба поддержки</p>
            <p className="text-center">
              Появились вопросы или сложности обратитесь за&nbsp;помощью в&nbsp;техподдержку
            </p>
            <a
              href="mailto:support@advmarker.ru"
              target="_blank"
              rel="noreferrer"
              className="btn btn btn-outline-primary mt-auto d-block w-100"
            >
              Отправить обращение
            </a>
            {/* <Button onClick={openModal} outline color="primary" block style={{ marginTop: "auto" }}>
              Отправить обращение
            </Button> */}
          </CardBody>
        </Card>
      </div>
      {/* <FeedbackFormModal isOpen={isModalOpen} toggle={closeModal} /> */}
    </>
  );
};

export default SupportService;
