export const scrollToError = (error: any) => {
  const fields = Object.keys(error);
  const arrayOfTops = fields.map((field) => {
    const element = document.querySelector(`#${field}`)?.getBoundingClientRect();
    if (element) {
      return element?.top;
    }
    return 0;
  });
  window.scrollTo(0, Math.min(...arrayOfTops) + window.scrollY - 100);
};
