import { deleteInvoice } from "api/invoice_delete";
import { ID } from "models";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onDeleteMutation from "utils/onDeleteMutation";

const useDeleteInvoice = (id: ID, invoiceLastSearch: string, n: string | undefined) => {
  const navigate = useNavigate();

  const { mutate: del } = useMutation({
    mutationFn: deleteInvoice,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        successMessage: `Акт ${n ? `№${n}` : `[${id}]`} удален`,
        navigate,
        href: `/invoice/${invoiceLastSearch}`
      })
  });

  return del;
};

export default useDeleteInvoice;
