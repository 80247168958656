import { Dispatch, SetStateAction } from "react";
import { components, GroupBase, MenuProps } from "react-select";
import { Pagination } from "ui/Pagination";
import { TOption } from "..";

export const MenuComponent = (
  props: MenuProps<TOption, boolean, GroupBase<TOption>>,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  lastPageNumber: number
) => (
  <components.Menu {...props} options={props.options.slice(0, 10)}>
    {props.children}
    <div style={{ width: "max-content", marginInline: "auto" }}>
      <Pagination currentPage={page} lastPageNumber={lastPageNumber} setCurrentPage={setPage} />
    </div>
  </components.Menu>
);
