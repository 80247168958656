import axios from "axios";
import Cookies from "js-cookie";
import { Platform } from "../models";

export const addPlatform = ({ body }: { body: Platform }) => {
  return axios
    .post(`/api/v1/platform/`, body, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      }
    })
    .then((res) => {
      return res;
    });
};
