import { TCreativeGroupNewFilter } from "types/TCreativeGroupFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { NEW_CreativeGroup } from "../models";
import { APIListData } from "./types";

interface IProps extends TSearchParamsPropsNoFilter {
  filter?: TCreativeGroupNewFilter;
}

export const getCreativeGroupNewInfo = (
  props?: IProps
): Promise<APIListData<NEW_CreativeGroup>> => {
  return fetch(
    props === undefined
      ? `/api/v1/creative_group_new/`
      : `/api/v1/creative_group_new/?${getSearchParams(props)}`,
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  )
    .then((res) => {
      const result = res.json() as Promise<APIListData<NEW_CreativeGroup>>;
      return result;
    })
    .then((res) => {
      return res;
    });
};
