import { deleteInvoice } from "api/invoice_delete";
import invoice_status from "dictionaries/invoice_status.json";
import participant_client_roles from "dictionaries/participant_client_roles.json";
import participant_contractor_roles from "dictionaries/participant_contractor_roles.json";
import { ID, Invoice } from "models";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import ParticipantInline from "ui/ParticipantDisplay/ParticipantInline";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import WrappedCell from "ui/WrappedCell";
import getDate from "utils/getDate";
import getTitleFromDict from "utils/getTitleFromDict";
import onDeleteMutation from "utils/onDeleteMutation";
import getActions from "./getActions";

interface Props extends IExportRelatedProps {
  invoices: Invoice[];
  refetch: () => Promise<any>;
}

export const InvoiceTable = ({
  invoices,
  exportIds,
  onExportIdCheck,
  pageIds,
  setExportIds,
  refetch
}: Props) => {
  const navigate = useNavigate();

  const { mutate: del } = useMutation({
    mutationFn: deleteInvoice,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        refetch,
        successMessage: "Акт удален"
      })
  });

  const onRowDoubleClick = (id: ID) => navigate(`/invoice/${id}`);
  const actions = getActions(del);

  return (
    <>
      <ListingTable
        theadList={[
          "Заказчик",
          "Роль заказчика",
          "Исполнитель",
          "Роль исполнителя",
          "№&nbsp;договора",
          "Дата договора",
          "№&nbsp;акта",
          "Дата акта",
          "Стоимость услуг",
          "Дата начала оказания услуг",
          "Дата окончания оказания услуг",
          "Статус",
          "Изначальные договоры",
          "Количество креативов",
          "Фактическое число показов",
          "Общее число показов",
          "Стоимость услуг в&nbsp;составе акта",
          "Общая стоимость показов"
        ]}
        pageIds={pageIds}
        exportIds={exportIds}
        setExportIds={setExportIds}
        tbody={invoices.map((invoice: Invoice) => (
          <ListingTableRow
            key={invoice.id}
            id={invoice.id}
            item={invoice}
            onRowDoubleClick={() => invoice.id && onRowDoubleClick(invoice.id)}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            actions={actions}
            list={
              <>
                <ParticipantCell
                  id={`client${invoice.id}`}
                  name={invoice.extra_fields?.invoice_contract?.client?.name}
                  inn={invoice.extra_fields?.invoice_contract?.client?.inn}
                  ord={invoice.extra_fields?.invoice_contract?.client?.ord_accounts}
                />
                <WrappedCell
                  text={getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.client?.role,
                    participant_client_roles
                  )}
                  maxLength={20}
                />
                <ParticipantCell
                  id={`contractor${invoice.id}`}
                  name={invoice.extra_fields?.invoice_contract?.contractor?.name}
                  inn={invoice.extra_fields?.invoice_contract?.contractor?.inn}
                  ord={invoice.extra_fields?.invoice_contract?.contractor?.ord_accounts}
                />
                <WrappedCell
                  text={getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.contractor?.role,
                    participant_contractor_roles
                  )}
                  maxLength={20}
                />
                <td style={{ minWidth: "100px" }}>
                  {invoice.extra_fields?.invoice_contract?.number}
                </td>
                <td style={{ minWidth: "120px" }}>
                  {getDate(invoice.extra_fields?.invoice_contract?.date)}
                </td>
                <td style={{ minWidth: "70px" }}>{invoice.invoice_number}</td>
                <td style={{ minWidth: "120px" }}>{getDate(invoice.invoice_date)}</td>
                <td>{invoice.amount}</td>
                <td style={{ minWidth: "120px" }}>{getDate(invoice.start_date)}</td>
                <td style={{ minWidth: "125px" }}>{getDate(invoice.end_date)}</td>
                <StatusCell data={invoice} dictionary={invoice_status} />
                <td style={{ minWidth: "120px" }}>
                  {invoice.extra_fields?.initial_contracts_count}
                </td>
                <td style={{ minWidth: "120px" }}>{invoice.extra_fields?.creatives}</td>
                <td style={{ minWidth: "120px" }}>{invoice.extra_fields?.impressions_fact}</td>
                <td style={{ minWidth: "120px" }}>{invoice.extra_fields?.impressions_plan}</td>
                <td style={{ minWidth: "130px" }}>{invoice.extra_fields?.allocated_amount}</td>
                <td style={{ minWidth: "140px" }}>
                  {invoice.extra_fields?.creative_statistics_amount}
                </td>
              </>
            }
          />
        ))}
      />
      <ListingCardsWrapper pageIds={pageIds} exportIds={exportIds} setExportIds={setExportIds}>
        {invoices.map((invoice: Invoice) => (
          <ListingCard
            key={invoice.id}
            id={invoice.id}
            item={invoice}
            actions={actions}
            href={`/invoice/${invoice.id}`}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            list={
              <>
                <ListGroupItem>
                  Заказчик:{" "}
                  <ParticipantInline
                    id={`client${invoice.id}`}
                    name={invoice.extra_fields?.invoice_contract?.client?.name}
                    inn={invoice.extra_fields?.invoice_contract?.client?.inn}
                    ord={invoice.extra_fields?.invoice_contract?.client?.ord_accounts}
                  />
                </ListGroupItem>
                <ListGroupItem>
                  Роль заказчика:{" "}
                  {getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.client?.role,
                    participant_client_roles
                  )}
                </ListGroupItem>
                <ListGroupItem>
                  Исполнитель:{" "}
                  <ParticipantInline
                    id={`contractor${invoice.id}`}
                    name={invoice.extra_fields?.invoice_contract?.contractor?.name}
                    inn={invoice.extra_fields?.invoice_contract?.contractor?.inn}
                    ord={invoice.extra_fields?.invoice_contract?.contractor?.ord_accounts}
                  />
                </ListGroupItem>
                <ListGroupItem>
                  Роль исполнителя:{" "}
                  {getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.contractor?.role,
                    participant_contractor_roles
                  )}
                </ListGroupItem>
                <ListGroupItem>
                  № договора: {invoice.extra_fields?.invoice_contract?.number}
                </ListGroupItem>
                <ListGroupItem>
                  Дата договора: {getDate(invoice.extra_fields?.invoice_contract?.date)}
                </ListGroupItem>
                <ListGroupItem>№ акта: {invoice.invoice_number}</ListGroupItem>
                <ListGroupItem>Дата акта: {getDate(invoice.invoice_date)}</ListGroupItem>
                <ListGroupItem>Стоимость услуг: {invoice.amount}</ListGroupItem>
                <ListGroupItem>
                  Дата начала оказания услуг: {getDate(invoice.start_date)}
                </ListGroupItem>
                <ListGroupItem>
                  Дата окончания оказания услуг: {getDate(invoice.end_date)}
                </ListGroupItem>
                <ListGroupItem>
                  Статус: <StatusInline data={invoice} dictionary={invoice_status} />
                </ListGroupItem>
                <ListGroupItem>
                  Изначальные договоры: {invoice.extra_fields?.initial_contracts_count}
                </ListGroupItem>
                <ListGroupItem>
                  Количество креативов: {invoice.extra_fields?.creatives}
                </ListGroupItem>
                <ListGroupItem>
                  Фактическое число показов: {invoice.extra_fields?.impressions_fact}
                </ListGroupItem>
                <ListGroupItem>
                  Общее число показов: {invoice.extra_fields?.impressions_plan}
                </ListGroupItem>
                <ListGroupItem>
                  Стоимость услуг в составе акта: {invoice.extra_fields?.allocated_amount}
                </ListGroupItem>
                <ListGroupItem>
                  Общая стоимость показов: {invoice.extra_fields?.creative_statistics_amount}
                </ListGroupItem>
              </>
            }
          />
        ))}
      </ListingCardsWrapper>
    </>
  );
};
