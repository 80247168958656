import { addInvoice } from "api/invoice_add";
import { AxiosError } from "axios";
import { FieldValues, UseFormSetError, UseFormWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { notVisibleFieldsInvoice } from "../constants";

const useAddInvoice = (
  invoiceLastSearch: string,
  watch: UseFormWatch<FieldValues>,
  setError: UseFormSetError<FieldValues>
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: addInvoice,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/invoice/${invoiceLastSearch}`,
        navigate,
        false,
        `Акт ${
          watch("invoice_number") ? `№${watch("invoice_number")}` : `[${p.data.data.id}]`
        } добавлен`
      ),
    onError: (err) => {
      console.log(err);
      onErrorMutation(err as AxiosError, setError, notVisibleFieldsInvoice);
    }
  });

  return mutate;
};

export default useAddInvoice;
