import { getContractList } from "api/contract_list";
import { ID } from "models";
import { useQuery } from "react-query";

const useLoadInitialContracts = (initialContractsIds: ID[] | undefined) => {
  const { data } = useQuery({
    queryKey: ["contract_list", initialContractsIds],
    queryFn: () =>
      getContractList({
        filter: {
          id: initialContractsIds
        }
      })
  });

  return {
    initialContractsList: data?.data.results
  };
};

export default useLoadInitialContracts;
