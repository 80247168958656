import axios from "axios";
import { NEW_Creative } from "models";
import { TCreativeFilter } from "types/TCreativeFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import getSearchParams from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsCreative extends TSearchParamsPropsNoFilter {
  filter?: TCreativeFilter;
}

export const getCreativeNewList = (props?: IPropsCreative): Promise<APIListData<NEW_Creative>> => {
  return axios
    .get(
      props === undefined
        ? `/api/v1/creative_new/`
        : `/api/v1/creative_new/?${getSearchParams(props)}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        timeout: 1000
      }
    )
    .then((res) => {
      return res.data as Promise<APIListData<NEW_Creative>>;
    });
};
