import get from "lodash/get";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText
} from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";
import FieldLabel from "ui/FieldLabel";
import { splitPrefixFromWord, trimPrefix } from "./helpers";
import { TInputMultipleProps } from "./types";

export const InputMultiple = ({
  name,
  title,
  control,
  watch,
  errors,
  isRequired,
  isStared,
  errorText,
  pattern,
  maxLength,
  minLength,
  disabled,
  prefix,
  keyInObject,
  extraAction
}: TInputMultipleProps) => {
  const isPrefixWord = prefix && typeof prefix === "string";
  const isPrefixArray = prefix && typeof prefix !== "string";

  let firstPrefix: string | undefined = undefined;
  if (isPrefixWord) {
    firstPrefix = prefix;
  }
  if (isPrefixArray) {
    firstPrefix = prefix[0];
  }

  const [valuesArray, setValuesArray] = useState<string[]>([""]);
  const [selectedPrefixes, setSelectedPrefixes] = useState<(string | undefined)[]>([firstPrefix]);
  const [prefixSelectorOpen, setPrefixSelectorOpen] = useState<boolean[]>(prefix ? [false] : []);

  const val = watch(name) as {
    [_: string]: string;
  }[];

  useEffect(() => {
    if (val) {
      const arrOfValues = val.map((v) => v?.[keyInObject]).filter((v) => typeof v === "string");
      if (prefix) {
        const newPrefixesArray: string[] = [];
        const newValuesArray: string[] = [];
        arrOfValues.forEach((val) => {
          const splited = splitPrefixFromWord(val, prefix);
          if (splited.prefix) newPrefixesArray.push(splited.prefix);
          newValuesArray.push(splited.word);
        });
        setSelectedPrefixes(newPrefixesArray);
        setValuesArray(newValuesArray);
        return;
      }
      setValuesArray(arrOfValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  const Prefix = ({ index }: { index: number }) => {
    if (isPrefixWord) {
      return <InputGroupText>{selectedPrefixes[index]}</InputGroupText>;
    }

    if (isPrefixArray) {
      return (
        <ButtonDropdown
          isOpen={prefixSelectorOpen[index]}
          toggle={() =>
            setPrefixSelectorOpen((prev) => {
              const newState = [...prev];
              newState[index] = !prev[index];
              return newState;
            })
          }
        >
          <DropdownToggle caret outline>
            {selectedPrefixes[index]}
          </DropdownToggle>
          <DropdownMenu>
            {/* eslint-disable-next-line react/prop-types */}
            {prefix.map((pr) => (
              <DropdownItem
                key={pr}
                onClick={() =>
                  setSelectedPrefixes((prev) => {
                    const newState = [...prev];
                    newState[index] = pr;
                    return newState;
                  })
                }
              >
                {pr}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      );
    }

    return null;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        pattern,
        maxLength,
        minLength
      }}
      render={({ field: { onChange, onBlur } }) => (
        <FormGroup>
          <FieldLabel title={title} isRequired={isRequired || isStared} />
          {valuesArray.map((v, index) => (
            <InputGroup className="mb-2" key={index}>
              <Prefix index={index} />
              <Input
                placeholder={title}
                value={v || ""}
                onChange={({ target: { value: newVal } }) => {
                  if (prefix && typeof prefix === "string" && newVal.indexOf(prefix) === 0) {
                    const trimmedVal = trimPrefix(newVal, prefix);

                    setValuesArray((prev) => {
                      if (extraAction) extraAction();
                      const newState = [...prev];
                      newState[index] = trimmedVal;
                      return newState;
                    });

                    return;
                  }

                  if (prefix && typeof prefix !== "string") {
                    const splited = splitPrefixFromWord(newVal, prefix);

                    if (splited.prefix) {
                      setSelectedPrefixes((prev) => {
                        const newState = [...prev];
                        newState[index] = splited.prefix;
                        return newState;
                      });
                    }

                    setValuesArray((prev) => {
                      if (extraAction) extraAction();
                      const newState = [...prev];
                      newState[index] = splited.word;
                      return newState;
                    });

                    return;
                  }

                  setValuesArray((prev) => {
                    if (extraAction) extraAction();
                    const newState = [...prev];
                    newState[index] = newVal;
                    return newState;
                  });

                  return;
                }}
                type="text"
                invalid={!!errors && !!get(errors, name)}
                disabled={disabled}
                onBlur={() => {
                  const newValuesArray = [...valuesArray];
                  const newSelectedPrefixes = [...selectedPrefixes];
                  const newPrefixSelectorOpen = [...prefixSelectorOpen];
                  let i = 0;
                  while (i < newValuesArray.length) {
                    if (newValuesArray[i]?.length === 0) {
                      newValuesArray.splice(i, 1);
                      newSelectedPrefixes.splice(i, 1);
                      newPrefixSelectorOpen.splice(i, 1);
                    } else {
                      ++i;
                    }
                  }
                  const newValue = newValuesArray.length
                    ? newValuesArray.map((val, index) => ({
                        [keyInObject]: (newSelectedPrefixes[index] || "") + val
                      }))
                    : [];
                  onChange(newValue);
                  if (prefix) {
                    setSelectedPrefixes(newSelectedPrefixes);
                    setPrefixSelectorOpen(newPrefixSelectorOpen);
                  }
                  onBlur();
                }}
              />
              {valuesArray.length > 1 ? (
                <Button
                  onClick={() => {
                    const newValuesArray = [...valuesArray];
                    const newSelectedPrefixes = [...selectedPrefixes];
                    const newPrefixSelectorOpen = [...prefixSelectorOpen];
                    newValuesArray.splice(index, 1);
                    newSelectedPrefixes.splice(index, 1);
                    newPrefixSelectorOpen.splice(index, 1);
                    const newValue = newValuesArray.length
                      ? newValuesArray.map((val, index) => ({
                          [keyInObject]: (newSelectedPrefixes[index] || "") + val
                        }))
                      : [];
                    onChange(newValue);
                    if (extraAction) extraAction();
                  }}
                  disabled={disabled}
                >
                  -
                </Button>
              ) : null}
            </InputGroup>
          ))}
          <Button
            onClick={() => {
              if (prefix) {
                setPrefixSelectorOpen((prev) => [...prev, false]);
                setSelectedPrefixes((prev) => [...prev, firstPrefix]);
              }
              if (extraAction) extraAction();
              setValuesArray((prev) => [...prev, ""]);
            }}
            disabled={disabled}
            className="d-block"
          >
            +
          </Button>
          <FieldFeedback name={name} errors={errors} errorText={errorText} />
        </FormGroup>
      )}
    />
  );
};
