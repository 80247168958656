import { SelectField } from "ui/Select";
import { FieldProps } from "./types";

const IsAgentActingForPublisherField = ({
  control,
  errors,
  contractLoading,
  isShown,
  isRequired
}: FieldProps) => {
  const options = [
    {
      value: 0, // false
      label: "Рекламодателя",
      isDisabled: false
    },
    {
      value: 1, // true
      label: "Рекламораспространителя",
      isDisabled: false
    }
  ];

  if (!isShown) return null;

  return (
    <div className="row">
      <div className="col-sm-9">
        <SelectField
          name="is_agent_acting_for_publisher"
          title="Агент действует в интересах"
          control={control}
          errors={errors}
          options={options}
          disabled={contractLoading}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default IsAgentActingForPublisherField;
