import { getParticipantList } from "api/participant_list";
import uniqBy from "lodash/uniqBy";
import { ID } from "models";
import { useMutation } from "react-query";

const useSearchClientMutation = (searchClientValue?: string, ids?: ID[]) =>
  useMutation({
    mutationFn: () =>
      Promise.all([
        getParticipantList(searchClientValue ? { search: searchClientValue } : undefined),
        getParticipantList({ filter: { id: ids } })
      ])
        .then(([searchRes, byIdsRes]) => {
          const results = uniqBy([...searchRes.data.results, ...byIdsRes.data.results], "id");
          return {
            status: true,
            data: { count: results.length, next: "", previous: "", results }
          };
        })
        .catch(() => ({ status: false, data: { count: 0, next: "", previous: "", results: [] } }))
  });

export default useSearchClientMutation;
