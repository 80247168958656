import { AxiosError } from "axios";
import omit from "lodash/omit";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { TAPIResponseError } from "types/ResponseTypes";
import onErrorMutation from "utils/onErrorMutation";
import { notVisibleFieldsCreative } from "./constants";

export const onErrorAdd = (err: AxiosError, setError: UseFormSetError<FieldValues>) => {
  if (err.response?.data) {
    const errorData = err.response.data as TAPIResponseError;
    const formErrorsKeys = Object.keys(errorData.form_errors);
    if (formErrorsKeys.length) {
      return onErrorMutation(
        {
          ...err,
          response: {
            ...err.response,
            data: {
              ...errorData,
              form_errors:
                formErrorsKeys.includes("is_native") || formErrorsKeys.includes("is_social")
                  ? omit(
                      {
                        ...errorData.form_errors,
                        peculiarities: [
                          "Поле 'Особенности рекламной кампании' не может быть пустым"
                        ]
                      },
                      ["is_native", "is_social"]
                    )
                  : errorData.form_errors
            }
          }
        } as AxiosError,
        setError,
        notVisibleFieldsCreative
      );
    }
  }
};
