import Cookies from "js-cookie";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { setPlatformLastSearchParams } from "reducers/LastSearchParams";
import { Dispatch, UnknownAction } from "redux";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import { defaultSearchParams } from "../constants";

const usePlatformsSerchParams = ({
  searchProperties,
  dispatch
}: {
  searchProperties: string;
  dispatch: Dispatch<UnknownAction>;
}) => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizePlatform");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const platform_type = newSearchParams.platform_type
      ? getNumberArrayFromSearchParam(newSearchParams.platform_type)
      : defaultSearchParams.filter?.platform_type;
    const owner = newSearchParams.owner
      ? getNumberArrayFromSearchParam(newSearchParams.owner)
      : defaultSearchParams.filter?.owner;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        ord_account,
        combined_status,
        platform_type,
        owner
      }
    });
    dispatch(setPlatformLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  return searchParams;
};

export default usePlatformsSerchParams;
