import ErrorComponent from "Components/ErrorComponent";
import EditorFooter from "Layout/EditorFooter";
import invoice_status from "dictionaries/invoice_status.json";
import omit from "lodash/omit";
import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { TEditorFooters } from "types/TEditorFooters";
import { AttributesDropdown } from "ui/AttributesDropdown";
import StatusInline from "ui/StatusDisplay/StatusInline";
import { scrollToError } from "utils/scrollToError";
import { InvoiceAttributesBody } from "./components/attributes";
import { InvoiceForm } from "./components/form";
import { NoAllocationsFileModal } from "./components/noAllocationsFileModal";
import useDeleteInvoice from "./hooks/useDeleteInvoice";
import useEditInvoice from "./hooks/useEditInvoice";
import useLoadInvoice from "./hooks/useLoadInvoice";

type Props = {
  id: ID;
};

const InvoiceEditForm = ({ id }: Props) => {
  const invoiceLastSearch = useSelector((state: StateProps) => state.LastSearchParams.invoice);
  const [_amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number | undefined>();
  const [isNoAllocationsFileModalOpen, setIsNoAllocationsFileModalOpen] = useState(false);
  const toggleNotAllocationsFileModal = () => setIsNoAllocationsFileModalOpen((prev) => !prev);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    reset,
    setValue
  } = useForm();

  const { isLoading, error, initialParams, invoiceNumber, data } = useLoadInvoice(
    id,
    reset,
    setAmountFromAllocationsFile
  );
  const edit = useEditInvoice(id, invoiceLastSearch, watch("invoice_number"), setError);
  const del = useDeleteInvoice(id, invoiceLastSearch, watch("invoice_number"));

  const onEdit = (submitStatus: 1 | 2) => {
    edit({
      id,
      invoice: {
        ...omit(watch(), "noNumber"),
        status: submitStatus
      }
    });
  };

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      const submitStatus = +submitter.value as 1 | 2; // 1:Активный, 2:Черновик

      if (!watch("allocations_file") && submitStatus === 1) {
        toggleNotAllocationsFileModal();
      } else {
        onEdit(submitStatus);
      }

      // if (amountFromAllocationsFile && +watch("amount") !== amountFromAllocationsFile)
      //   createNotification(
      //     "error",
      //     `Указанная сумма ${watch(
      //       "amount"
      //     )}. Рассчитанная сумма ${amountFromAllocationsFile}. Значения должны совпадать`
      //   );
      // else {
      // }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  if (error) {
    return <ErrorComponent error={error.message} status={error.response?.status} isEmbedded />;
  }

  const buttons: TEditorFooters<"DRAFT" | "ACTIVE" | "LOADING"> = {
    DRAFT: (
      <EditorFooter
        left={
          <>
            <Button
              type="submit"
              color="danger"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                del(id);
              }}
            >
              Удалить
            </Button>
            <Button type="submit" color="primary" value="2" disabled={isLoading}>
              Сохранить черновик
            </Button>
          </>
        }
        right={
          <>
            <Button
              href={`#/invoice/${invoiceLastSearch}`}
              disabled={isLoading}
              tag="a"
              color="primary"
              outline
            >
              Отменить
            </Button>
            <Button type="submit" color="danger" value="1" disabled={isLoading}>
              Отправить в ЕРИР
            </Button>
          </>
        }
      />
    ),
    ACTIVE: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/invoice/${invoiceLastSearch}`}
              disabled={isLoading}
              tag="a"
              color="primary"
              outline
            >
              Отменить
            </Button>
            <Button type="submit" color="danger" value="1" disabled={isLoading}>
              Отправить в ЕРИР
            </Button>
          </>
        }
      />
    ),
    LOADING: <EditorFooter />
  };

  return (
    <div>
      <h1>Редактирование акта {invoiceNumber}</h1>

      <AttributesDropdown
        title={
          <div className="d-flex justify-content-between align-items-center">
            Статус акта:{" "}
            {data?.data ? StatusInline({ data: data.data, dictionary: invoice_status }) : ""}
          </div>
        }
      >
        <InvoiceAttributesBody data={data?.data} />
      </AttributesDropdown>

      <InvoiceForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={
          isLoading ? buttons["LOADING"] : buttons[data?.data.status === 1 ? "ACTIVE" : "DRAFT"]
        }
        watch={watch}
        isLoading={isLoading}
        initialParams={initialParams}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        isDraft={isLoading}
        setValue={setValue}
        allocationsFileOld={
          watch("allocations_file") ? `/api/v1/invoice/${id}/allocation/` : undefined
        }
        statisticsFileOld={
          watch("creative_statistics_file") ? `/api/v1/invoice/${id}/statistics/` : undefined
        }
      />
      <NoAllocationsFileModal
        isOpen={isNoAllocationsFileModalOpen}
        toggle={toggleNotAllocationsFileModal}
        onContinue={() => onEdit(1)}
      />
    </div>
  );
};

export default InvoiceEditForm;
