import axios from "axios";
import Cookies from "js-cookie";

export type TUserLoginFail = {
  status: false;
  error_message?: string;
  form_errors: {
    email?: string;
    password?: string;
  };
};

export const userLogin = ({ email, password }: { email: string; password: string }) => {
  return axios
    .post(
      "/api/v1/login/",
      {
        email,
        password
      },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(() => {})
    .catch(({ response: { data } }: { response: { data: TUserLoginFail } }) => data);
};
