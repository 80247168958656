import EditorFooter from "Layout/EditorFooter";
import omit from "lodash/omit";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { scrollToError } from "utils/scrollToError";
import { InvoiceForm } from "./components/form";
import { NoAllocationsFileModal } from "./components/noAllocationsFileModal";
import useAddInvoice from "./hooks/useAddInvoice";

const InvoiceAddForm = () => {
  const invoiceLastSearch = useSelector((state: StateProps) => state.LastSearchParams.invoice);
  const [_amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number | undefined>();
  const [isNoAllocationsFileModalOpen, setIsNoAllocationsFileModalOpen] = useState(false);
  const toggleNotAllocationsFileModal = () => setIsNoAllocationsFileModalOpen((prev) => !prev);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue
  } = useForm();

  const add = useAddInvoice(invoiceLastSearch, watch, setError);

  const onAdd = (submitStatus: 1 | 2) => {
    add({
      invoice: {
        ...omit(watch(), "noNumber"),
        status: submitStatus
      }
    });
  };

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      const submitStatus = +submitter.value as 1 | 2; // 1:Активный, 2:Черновик

      if (!watch("allocations_file") && submitStatus === 1) {
        toggleNotAllocationsFileModal();
      } else {
        onAdd(submitStatus);
      }

      // if (amountFromAllocationsFile && +watch("amount") !== amountFromAllocationsFile)
      //   createNotification(
      //     "error",
      //     `Указанная сумма ${watch(
      //       "amount"
      //     )}. Рассчитанная сумма ${amountFromAllocationsFile}. Значения должны совпадать`
      //   );
      // else {
      // }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  return (
    <div>
      <h1>Добавление акта</h1>
      <InvoiceForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={
          <EditorFooter
            left={
              <Button type="submit" color="primary" value="2">
                Сохранить черновик
              </Button>
            }
            right={
              <>
                <Button href={`#/invoice/${invoiceLastSearch}`} tag="a" color="primary" outline>
                  Отменить
                </Button>
                <Button type="submit" color="danger" value="1">
                  Отправить в ЕРИР
                </Button>
              </>
            }
          />
        }
        watch={watch}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        setValue={setValue}
        isDraft={false}
      />
      <NoAllocationsFileModal
        isOpen={isNoAllocationsFileModalOpen}
        toggle={toggleNotAllocationsFileModal}
        onContinue={() => onAdd(1)}
      />
    </div>
  );
};

export default InvoiceAddForm;
