import { IoInformationCircleOutline } from "react-icons/io5";
import Tooltip from "ui/Tooltip";

const InfoTooltip = ({ id }: { id: string }) => {
  return (
    <Tooltip
      id={id}
      alignLeft
      target={
        <IoInformationCircleOutline
          id={id}
          size={18}
          className="position-absolute text-primary"
          style={{ top: "10px", right: "10px" }}
        />
      }
    >
      <p className="fw-bold m-0">Все запросы будут обработаны в рабочее время:</p>
      <p className="m-0">понедельник-пятница с 9:00 до 19:00 (по московскому времени)</p>
    </Tooltip>
  );
};

export default InfoTooltip;
