import { useEffect, useState } from "react";
import { Button, Input, InputGroup } from "reactstrap";

type Props = {
  currentQuery?: string;
  onSearchClick: (_s: string) => void;
  placeholder?: string;
};

export const Search = ({ currentQuery, onSearchClick, placeholder }: Props) => {
  const [str, setStr] = useState("");

  useEffect(() => {
    setStr(currentQuery || "");
  }, [currentQuery]);

  return (
    <InputGroup>
      <Input
        onInput={(e) => setStr((e.target as HTMLInputElement).value)}
        onKeyDown={({ key }) => key === "Enter" && onSearchClick(str || "")}
        value={str}
        placeholder={placeholder}
      />
      <Button onClick={() => onSearchClick(str || "")} color="primary">
        Поиск
      </Button>
    </InputGroup>
  );
};
