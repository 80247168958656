import ContractSubjectTypeOptions from "constants/selectOptions/ContractSubjectTypeOptions";
import { SelectField } from "ui/Select";
import { FieldProps } from "./types";

const SubjectTypeField = ({
  control,
  errors,
  contractLoading,
  isShown,
  isRequired
}: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row">
      <div className="col-sm-9">
        <SelectField
          name="subject_type"
          title="Вид договора"
          control={control}
          errors={errors}
          options={ContractSubjectTypeOptions}
          disabled={contractLoading}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default SubjectTypeField;
