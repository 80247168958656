import { UseMutateFunction } from "react-query";
import { TAPIResponseError } from "types/ResponseTypes";
import { ActionSelectorProps } from "ui/ActionSelector";

const getActions = (
  del: UseMutateFunction<"success" | TAPIResponseError, unknown, string | number, unknown>
): (ActionSelectorProps | null)[] => {
  return [
    {
      title: "Редактировать",
      calcHref: (id) => ({ href: `#/invoice/${id}/` })
    },
    {
      title: "Удалить",
      onClick: (id) => (id ? del(id) : null),
      calcIsDisabled: (invoice) => invoice.status === 1
    }
  ];
};

export default getActions;
