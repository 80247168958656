import axios from "axios";
import { Invoice } from "models";
import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsInvoice extends TSearchParamsPropsNoFilter {
  filter?: TInvoiceFilter;
}

export const getInvoiceList = (props?: IPropsInvoice): Promise<APIListData<Invoice>> => {
  return axios
    .get(props === undefined ? `/api/v1/invoice/` : `/api/v1/invoice/?${getSearchParams(props)}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      },
      timeout: 1000
    })
    .then((res) => {
      return res.data as Promise<APIListData<Invoice>>;
    });
};
