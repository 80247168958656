import Cookies from "js-cookie";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { setContractLastSearchParams } from "reducers/LastSearchParams";
import { Dispatch, UnknownAction } from "redux";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getStringFromSearchParam from "utils/getStringFromSearchParam";
import { defaultSearchParams } from "../constants";

const useContractsSerchParams = ({
  searchProperties,
  dispatch
}: {
  searchProperties: string;
  dispatch: Dispatch<UnknownAction>;
}) => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeContact");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const contract_type = newSearchParams.contract_type
      ? getNumberArrayFromSearchParam(newSearchParams.contract_type)
      : defaultSearchParams.filter?.contract_type;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const client_role = newSearchParams.client_role
      ? getNumberArrayFromSearchParam(newSearchParams.client_role)
      : defaultSearchParams.filter?.client_role;
    const contractor_role = newSearchParams.contractor_role
      ? getNumberArrayFromSearchParam(newSearchParams.contractor_role)
      : defaultSearchParams.filter?.contractor_role;
    const client = newSearchParams.client
      ? +newSearchParams.client
      : defaultSearchParams.filter?.client;
    const contractor = newSearchParams.contractor
      ? +newSearchParams.contractor
      : defaultSearchParams.filter?.contractor;
    const participant = newSearchParams.participant
      ? getStringFromSearchParam(newSearchParams.participant)
      : defaultSearchParams.filter?.participant;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        contract_type,
        ord_account,
        combined_status,
        client_role,
        contractor_role,
        client,
        contractor,
        participant
      }
    });
    dispatch(setContractLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  return searchParams;
};

export default useContractsSerchParams;
