import { getPlatform } from "api/platform_item";
import { APIData } from "api/types";
import { AxiosError } from "axios";
import { ID, Platform } from "models";
import { Dispatch, SetStateAction } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";
import { PosiblePlatformStatuses } from "../types";

const useLoadPlatform = (
  id: ID,
  reset: UseFormReset<FieldValues>,
  setStatus: Dispatch<SetStateAction<PosiblePlatformStatuses>>
) => {
  const {
    isLoading,
    error,
    data: platform
  } = useQuery<APIData<Platform>, AxiosError>({
    queryKey: ["platform_list", id],
    enabled: !!id,
    queryFn: () => getPlatform(id),
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    onSuccess: ({ status, data, error_message }) => {
      if (status) {
        reset({
          ...data,
          no_url: data && data.platform_url !== "" ? false : true,
          is_owner: !!data.owner
        });

        let st: PosiblePlatformStatuses = "LOADING";

        if (data?.status === 1) {
          st = "ACTIVE";

          const syncStatuses = data.extra_fields?.sync_status.map(({ status }) => status);

          if (
            syncStatuses &&
            (syncStatuses.includes("SYNCHRONIZED") || syncStatuses.includes("IS_SYNCHRONIZING"))
          ) {
            st = "SYNCHRONIZED_OR_SYNCHRONIZING";
          }

          if (syncStatuses && syncStatuses.includes("SYNC_ERROR")) {
            st = "SYNC_ERROR";
          }
        } else if (data?.status === 2) {
          st = "DRAFT";
        }

        setStatus(st);
      } else {
        console.log(error_message);
      }
    }
  });

  return {
    isLoading,
    error,
    searchParticipantsValueDefault: platform?.data?.extra_fields?.platform_owner_name,
    searchPlatformsValueDefault: platform?.data.platform_url,
    status
  };
};

export default useLoadPlatform;
