import mime from "mime";
import { Accept } from "react-dropzone";

const getDropzoneAcceptFromArray = (exts: string[] | string | undefined) => {
  if (!exts) return undefined;

  if (typeof exts === "string") {
    const mimeType = mime.getType(exts);
    return mimeType
      ? {
          [mimeType]: []
        }
      : undefined;
  }

  const obj: Accept = {};

  exts.forEach((ext) => {
    const mimeType = mime.getType(ext);
    if (mimeType) obj[mimeType] = [];
  });

  return obj;
};

export default getDropzoneAcceptFromArray;
