import AppFooter from "Layout/AppFooter";
import AppHeader from "Layout/AppHeader";
import AppSidebar from "Layout/AppSidebar";
import { Outlet } from "react-router-dom";

const DefaultLayout = () => {
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Outlet />
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
};

export default DefaultLayout;
