import { ParticipantAddForm, ParticipantEditForm } from "Components/ParticipantForm";
import { useParams } from "react-router-dom";

const ParticipantFormPage = () => {
  const { id } = useParams();

  return (
    <>
      {id === "add" ? <ParticipantAddForm /> : null}
      {id && +id ? <ParticipantEditForm id={+id} /> : null}
    </>
  );
};

export default ParticipantFormPage;
