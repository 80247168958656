import Loader from "react-loaders";
import OrdAccountCheckIcon from "ui/OrdAccountCheckIcon";
import TParticipantDisplay from "./type";

const ParticipantInline = ({ id, name, inn, ord, isLoading }: TParticipantDisplay) => {
  if (isLoading) return <Loader type="ball-beat" active innerClassName="loader-for-table" />;

  if (ord && ord.length)
    return (
      <>
        <span>
          {name} {inn ? `(${inn})` : ""}
        </span>
        <OrdAccountCheckIcon ord={ord.map(({ title }) => title)} id={id} />
      </>
    );

  return (
    <>
      {name} {inn ? `(${inn})` : ""}
    </>
  );
};

export default ParticipantInline;
