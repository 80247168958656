import { Modal as ReactstrapModal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  style?: React.CSSProperties;
  notCentered?: boolean;
};

const Modal = ({ isOpen, toggle, title, children, footer, style, notCentered }: Props) => {
  return (
    <ReactstrapModal
      isOpen={isOpen}
      toggle={toggle}
      className="p-0 modal-shadow-none"
      style={style}
      centered={!notCentered}
    >
      <ModalHeader toggle={toggle} className="border-0 bg-transparent">
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      {footer ? <ModalFooter className="border-0 bg-transparent">{footer}</ModalFooter> : null}
    </ReactstrapModal>
  );
};

export default Modal;
