export const SET_ENABLE_MOBILE_MENU = "THEME_OPTIONS/SET_ENABLE_MOBILE_MENU";
export const SET_ENABLE_CLOSED_SIDEBAR = "THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR";

export interface ThemeOptionsStateProps {
  enableMobileMenu: boolean;
  enableClosedSidebar: boolean;
}

interface ActionProps extends ThemeOptionsStateProps {
  type: string;
}

const defaultValue: ThemeOptionsStateProps = {
  enableMobileMenu: false,
  enableClosedSidebar: false
};

export const setEnableClosedSidebar = (
  enableClosedSidebar: ThemeOptionsStateProps["enableClosedSidebar"]
) => ({
  type: SET_ENABLE_CLOSED_SIDEBAR,
  enableClosedSidebar
});

export const setEnableMobileMenu = (
  enableMobileMenu: ThemeOptionsStateProps["enableMobileMenu"]
) => ({
  type: SET_ENABLE_MOBILE_MENU,
  enableMobileMenu
});

export default function reducer(state = defaultValue, action: ActionProps) {
  switch (action.type) {
    case SET_ENABLE_MOBILE_MENU:
      return {
        ...state,
        enableMobileMenu: action.enableMobileMenu
      };

    case SET_ENABLE_CLOSED_SIDEBAR:
      return {
        ...state,
        enableClosedSidebar: action.enableClosedSidebar
      };
  }
  return state;
}
