import getOrdAccounts from "api/get_ord_accounts";
import { useQuery } from "react-query";

function useOrdAccountsList() {
  const {
    data: ordAccsInfoList,
    isLoading,
    isError
  } = useQuery({
    queryKey: ["ord_accounts"],
    queryFn: getOrdAccounts
  });

  const options =
    ordAccsInfoList?.data.ord_accounts.map(({ id, title }) => ({
      value: id,
      label: title,
      isDisabled: false
    })) || [];

  return { list: ordAccsInfoList?.data.ord_accounts || [], options, isLoading, isError };
}

export default useOrdAccountsList;
