import axios from "axios";
import { ID, NEW_Creative } from "models";
import { APIData } from "./types";

export const getCreativeNewItem = (id?: ID): Promise<APIData<NEW_Creative>> => {
  return axios
    .get(`/api/v1/creative_new/${id}/`, {
      headers: {
        // "X-CSRFToken": Cookies.get("csrftoken"),
        // "Content-Type": "application/json;charset=utf-8",
        "X-Requested-With": "XMLHttpRequest"
      }
    })
    .then((res) => {
      return res.data as Promise<APIData<NEW_Creative>>;
    });
};
