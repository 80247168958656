import { Participant } from "models";

export const ParticipantFormDV: Participant = {
    legal_form: undefined,
    inn: '',
    name: '',
    registration_number: undefined,
    registration_country_code: undefined,
    mobile_phone: undefined,
    epay_number: undefined,
}
