import { deletePlatform } from "api/platform_delete";
import platform_status from "dictionaries/platform_status.json";
import platform_type from "dictionaries/platform_type.json";
import { ID, Platform } from "models";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import ParticipantInline from "ui/ParticipantDisplay/ParticipantInline";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import WrappedCell from "ui/WrappedCell";
import getTitleFromDict from "utils/getTitleFromDict";
import onDeleteMutation from "utils/onDeleteMutation";
import getActions from "./getActions";

interface Props extends IExportRelatedProps {
  platforms: Platform[];
  refetch: () => Promise<any>;
}

export const PlatformTable = ({
  platforms,
  exportIds,
  onExportIdCheck,
  pageIds,
  setExportIds,
  refetch
}: Props) => {
  const navigate = useNavigate();

  const onRowDoubleClick = (id: ID) => navigate(`/platform/${id}`);

  const { mutate: del } = useMutation({
    mutationFn: deletePlatform,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        refetch,
        successMessage: "Платформа удалена"
      })
  });

  return (
    <>
      <ListingTable
        theadList={["Название площадки", "URL", "Тип", "Статус", "Владелец площадки"]}
        pageIds={pageIds}
        exportIds={exportIds}
        setExportIds={setExportIds}
        tbody={platforms.map((platform: Platform) => (
          <ListingTableRow
            key={platform.id}
            id={platform.id}
            item={platform}
            onRowDoubleClick={() => platform.id && onRowDoubleClick(platform.id)}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            actions={getActions(platform, del)}
            list={
              <>
                <WrappedCell text={platform.platform_name} maxLength={20} />
                <td>{platform.platform_url}</td>
                <td>{getTitleFromDict(platform.platform_type, platform_type)}</td>
                <StatusCell data={platform} dictionary={platform_status} />
                <ParticipantCell
                  id={`owner${platform.id}`}
                  name={platform.extra_fields?.platform_owner_name}
                  inn={platform.extra_fields?.platform_owner_inn}
                  ord={platform.extra_fields?.platform_owner_ord_accounts}
                />
              </>
            }
          />
        ))}
      />
      <ListingCardsWrapper pageIds={pageIds} exportIds={exportIds} setExportIds={setExportIds}>
        {platforms.map((platform: Platform) => (
          <ListingCard
            key={platform.id}
            id={platform.id}
            item={platform}
            href={`/platform/${platform.id}`}
            header={`${platform.id} | ${platform.platform_name}`}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            actions={getActions(platform, del)}
            list={
              <>
                <ListGroupItem>URL: {platform.platform_url}</ListGroupItem>
                <ListGroupItem>
                  Тип: {getTitleFromDict(platform.platform_type, platform_type)}
                </ListGroupItem>
                <ListGroupItem>
                  Статус: <StatusInline data={platform} dictionary={platform_status} />
                </ListGroupItem>
                <ListGroupItem>
                  Владелец площадки:{" "}
                  <ParticipantInline
                    id={`owner${platform.id}`}
                    name={platform.extra_fields?.platform_owner_name}
                    inn={platform.extra_fields?.platform_owner_inn}
                    ord={platform.extra_fields?.platform_owner_ord_accounts}
                  />
                </ListGroupItem>
              </>
            }
          />
        ))}
      </ListingCardsWrapper>
    </>
  );
};
