import { ID, NEW_Creative } from "models";
import { FC, ReactElement, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { TAttachmentType } from "types/AttachmentsTypes";
import { Pagination } from "ui/Pagination";
import ProgressLoader from "ui/ProgressLoader";
import getLastPageNumber from "utils/getLastPageNumber";
import Body from "./body";

interface Props {
  creative?: NEW_Creative;
  isOpen: boolean;
  toggle: () => void;
}

export type TItem = {
  data?: string;
  id?: ID;
  type: TAttachmentType;
};

export const CreativePreviewModal: FC<Props> = ({
  creative,
  isOpen,
  toggle
}: Props): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const files = (creative?.media || []).map((item) => ({
    ...item,
    type: "file" as TAttachmentType
  }));
  const texts = (creative?.texts || []).map((item) => ({
    ...item,
    type: "text" as TAttachmentType
  }));
  const items: TItem[] = [...files, ...texts];

  const onClose = () => {
    toggle();
    setCurrentPage(0);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setLoading(true);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered className="p-0 modal-shadow-none">
      <ModalHeader toggle={onClose}>Просмотр креативов</ModalHeader>
      <ModalBody className="d-block justify-content-center overflow-auto p-0">
        {loading ? <ProgressLoader /> : <div style={{ height: "5px", marginBottom: "5px" }} />}
        <div style={{ height: "500px", marginBottom: "10px", paddingInline: "10px" }}>
          <Body
            item={items[currentPage]}
            creativeId={creative?.id}
            onLoad={() => setLoading(false)}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Pagination
          currentPage={currentPage}
          lastPageNumber={getLastPageNumber(items?.length || 0, 1)}
          setCurrentPage={onPageChange}
        />
      </ModalFooter>
    </Modal>
  );
};
