import { getParticipantList } from "api/participant_list";
import ParticipantClientRolesOptions from "constants/selectOptions/ParticipantClientRolesOptions";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import { SelectField } from "ui/Select";
import getErrorText from "utils/getErrorText";
import { FieldProps } from "./types";

const ClientField = ({
  control,
  errors,
  contractLoading,
  watch,
  setValue,
  isShown,
  isRequired
}: FieldProps) => {
  const clientId = watch("client") as number | undefined;
  const contractId = watch("id") as number;

  const [searchClientParticipantsValue, setSearchClientParticipantsValue] = useState<
    string | undefined
  >(undefined);

  const {
    mutate: clientParticipantsRequest,
    isLoading: clientParticipantsLoading,
    data: clientParticipantsResponse
  } = useMutation({
    mutationFn: () =>
      getParticipantList(
        searchClientParticipantsValue
          ? { search: searchClientParticipantsValue }
          : { filter: clientId ? { id: [clientId] } : {} }
      )
  });

  useEffect(() => {
    if (!contractLoading && !!clientId) clientParticipantsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId, contractLoading]);

  const validate = () => {
    const { contract_type, contractor, client } = watch();
    if (contractor && client && contractor === client && contract_type !== 4) {
      return 'Выберите другого контаргента или выберите тип договора "Самореклама"';
    }
    if (contractor && client && contractor !== client && contract_type === 4) {
      return 'Заказчик и Исполнитель должны быть одинаковыми при типе договора "Самореклама"';
    }
  };

  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <PredictiveSearchField
          name="client"
          title="Заказчик"
          optionKey={["name", "inn", "status"]}
          optionKeyLabels={["Название", "ИНН", "hidden"]}
          inputValue={searchClientParticipantsValue}
          setInputValue={setSearchClientParticipantsValue}
          control={control}
          errors={errors}
          errorText={getErrorText(errors, "client", "Должно быть заполнено.")}
          isLoading={clientParticipantsLoading}
          request={clientParticipantsRequest}
          response={clientParticipantsResponse}
          disabled={contractLoading}
          optionDisableCondition={{ status: 2 }}
          infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          validate={validate}
          onChange={(value) => {
            const client = clientParticipantsResponse?.data.results.find((p) => p.id === value);

            if (client) setValue("client_ord", client.extra_fields?.ord_accounts);
          }}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
          clearable
        />
      </div>
      <div className="col-sm-3">
        <SelectField
          name="client_role"
          title="Выберите роль"
          control={control}
          errors={errors}
          disabled={clientParticipantsLoading || contractLoading}
          options={ParticipantClientRolesOptions}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default ClientField;
