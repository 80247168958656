import ContractCreativesRegistrarOptions from "constants/selectOptions/ContractCreativesRegistrarOptions";
import { SelectField } from "ui/Select";
import { FieldProps } from "./types";

const CreativesRegistrarField = ({
  control,
  errors,
  contractLoading,
  isShown,
  isRequired
}: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <SelectField
          name="creatives_registrar"
          title="Необходимо регистрировать креативы"
          control={control}
          errors={errors}
          options={ContractCreativesRegistrarOptions}
          disabled={contractLoading}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default CreativesRegistrarField;
