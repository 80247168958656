import {
  combinedStatusOptions,
  onlyErirOptions
} from "constants/selectOptions/CombinedStatusOptions";
import CreativeFormOptions from "constants/selectOptions/CreativeFormOptions";
import CreativeTypeOptions from "constants/selectOptions/CreativeTypeOptions";
import FilterLayout from "Layout/FilterLayout";
import { FormEvent, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { TCreativeFilter } from "types/TCreativeFilter";
import {
  DatePickerField,
  InputField,
  PredictiveSearchField,
  selectColorSchemes,
  SelectField
} from "ui";
import useOrdAccountsList from "utils/useOrdAccountsList";
import useSearchClientMutation from "../hooks/useSearchClientMutation";
import useSearchGroupMutation from "../hooks/useSearchGroupMutation";

type Props = {
  filter: TCreativeFilter;
  clearFilter: TCreativeFilter;
  onFilterClick: (_: TCreativeFilter) => void;
  onClose: () => void;
};

export const CreativeFilter = ({ filter, clearFilter, onFilterClick, onClose }: Props) => {
  const [statusTitlesOptions, setStatusTitlesOptions] = useState(
    filter.ord_account?.length ? onlyErirOptions : combinedStatusOptions
  );

  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  const { control, watch, reset, setValue } = useForm({
    defaultValues: filter
  });

  const [searchClientValue, setSearchClientValue] = useState<string>();

  const {
    mutate: clientRequest,
    isLoading: clientLoading,
    data: clientResponse
  } = useSearchClientMutation(searchClientValue, watch("initial_contract__client"));

  const [searchGroupValue, setSearchGroupValue] = useState<string>();

  const {
    mutate: groupRequest,
    isLoading: groupLoading,
    data: groupResponse
  } = useSearchGroupMutation(searchGroupValue, watch("group"));

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onFilterClick(watch());
  };

  const onReset = () => {
    reset(clearFilter);
    onFilterClick(clearFilter);
  };

  return (
    <FilterLayout onSubmit={onSubmit} onReset={onReset} onClose={onClose}>
      <InputField
        name="marker"
        title="Токен"
        control={control as unknown as Control<FieldValues, any>}
      />
      <SelectField
        name="ord_account"
        control={control as unknown as Control<FieldValues, any>}
        title="Аккаунт в ОРД"
        isMulti
        options={ordAccountsTitlesOptions}
        disabled={ordAccountsLoading || ordAccountsError}
        onChange={(newVal) => {
          if (newVal && (newVal as (string | number)[]).length) {
            setValue(
              "combined_status",
              watch("combined_status")?.filter((st) => st === 4 || st === 5 || st === 6)
            );
            setStatusTitlesOptions(onlyErirOptions);
          } else {
            setStatusTitlesOptions(combinedStatusOptions);
          }
        }}
      />
      <SelectField
        name="combined_status"
        control={control as unknown as Control<FieldValues, any>}
        title="Статус"
        isMulti
        options={statusTitlesOptions}
        colorSchemes={{
          4: selectColorSchemes.yellow,
          5: selectColorSchemes.green,
          6: selectColorSchemes.red
        }}
      />
      <PredictiveSearchField
        name="initial_contract__client"
        title="ИНН заказчика по изначальному договору"
        optionKey={["name", "inn"]}
        optionKeyLabels={["Название", "ИНН"]}
        inputValue={searchClientValue}
        setInputValue={setSearchClientValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={clientLoading}
        request={clientRequest}
        response={clientResponse}
        isMulti
        clearable
      />
      <PredictiveSearchField
        name="group"
        title="Группа креативов"
        optionKey={["id", "label"]}
        optionKeyLabels={["ID", "Название"]}
        inputValue={searchGroupValue}
        setInputValue={setSearchGroupValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={groupLoading}
        request={groupRequest}
        response={groupResponse}
        isMulti
        isLoadMoreEnabled
      />
      <PredictiveSearchField
        name="group"
        title="Группа креативов"
        optionKey={["id", "label"]}
        optionKeyLabels={["ID", "Название"]}
        inputValue={searchGroupValue}
        setInputValue={setSearchGroupValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={groupLoading}
        request={groupRequest}
        response={groupResponse}
        isMulti
        isLoadMoreEnabled
      />
      <DatePickerField
        name="start_date"
        control={control as unknown as Control<FieldValues, any>}
        title="Дата начала периода размещения"
        returnFormat="yyyy-MM-dd"
      />
      <DatePickerField
        name="end_date"
        control={control as unknown as Control<FieldValues, any>}
        title="Дата окончания периода размещения"
        returnFormat="yyyy-MM-dd"
      />
      <SelectField
        name="advertisement_form"
        control={control as unknown as Control<FieldValues, any>}
        title="Форма РК"
        isMulti
        options={CreativeFormOptions}
      />
      <SelectField
        name="advertisement_type"
        control={control as unknown as Control<FieldValues, any>}
        title="Тип РК"
        isMulti
        options={CreativeTypeOptions}
      />
    </FilterLayout>
  );
};
