import Loader from "react-loaders";

export const LoadingMessage = () => (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type="ball-pulse-rise" active/>
            </div>
            <h6 className="mt-5">
                Loading...
            </h6>
        </div>
    </div>
);
