import { getContractList } from "api/contract_list";
import { ID } from "models";
import { useMutation } from "react-query";
import { TContractFilter } from "types/TContractFilter";

const useSearchInitialContractMutation = (
  searchInitialContractsValue?: string,
  clientInitialId?: ID | null,
  initialContractsIds?: ID[]
) =>
  useMutation({
    mutationFn: () => {
      const filter: TContractFilter = {};
      if (clientInitialId) filter.client = clientInitialId;
      if (initialContractsIds) filter.id = initialContractsIds;
      return getContractList(
        searchInitialContractsValue ? { search: searchInitialContractsValue } : { filter }
      );
    }
  });

export default useSearchInitialContractMutation;
