import { Contract } from "models";
import { UseMutateFunction } from "react-query";
import { TAPIResponseError } from "types/ResponseTypes";
import { ActionSelectorProps } from "ui/ActionSelector";

const getActions = (
  del: UseMutateFunction<"success" | TAPIResponseError, unknown, string | number, unknown>
): (ActionSelectorProps | null)[] => {
  return [
    {
      title: "Редактировать",
      calcHref: (id) => ({ href: `#/contract/${id}/` })
    },
    {
      title: "Удалить",
      onClick: (id) => (id ? del(id) : null),
      calcIsDisabled: (item) => {
        const contract = item as Contract;
        const syncStatuses = contract.extra_fields?.sync_status?.map((st) => st.status) || [];
        // if (syncStatuses.length > 0) return !!contract.extra_fields?.creatives_count;
        // return !!contract.extra_fields?.invoices_count;
        if (syncStatuses.length > 0) return true;
        return !!contract.extra_fields?.creatives_count || !!contract.extra_fields?.invoices_count;
      }
    }
  ];
};

export default getActions;
