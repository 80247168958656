import { uploadFile } from "api/upload_file";
import cn from "classnames";
import { TOKEN_VARIANTS_SUBTITLES } from "constants/CreativesConstants";
import get from "lodash/get";
import { ID } from "models";
import { FC, ReactElement } from "react";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { FaPlus } from "react-icons/fa";
import { Button } from "reactstrap";
import { TAttachment } from "types/AttachmentsTypes";
import { TFieldVariants } from "types/CreativesTypes";
import { CheckBoxField, InputField, InputMultiple, SelectField, TextAreaField } from "ui";
import AttachedFilesTable from "ui/AttachedFilesTable";
import { DropZoneMultipleField } from "ui/DropZoneMultiple";
import IconButton from "ui/IconButton";
import getByteToGigabyte from "utils/getByteToGigabyte";
import getErrorText from "utils/getErrorText";
import { v4 as uuidv4 } from "uuid";
import { ICreativeAddForm } from "../../../types";

interface Props {
  watch: UseFormWatch<ICreativeAddForm>;
  control: Control<ICreativeAddForm, any>;
  errors: FieldErrors<ICreativeAddForm>;
  setValue: UseFormSetValue<ICreativeAddForm>;
  clearErrors: UseFormClearErrors<ICreativeAddForm>;
  isDraft: boolean;
  selectedFileExtensions: string[] | undefined;
  selectedFields: TFieldVariants[] | undefined;
}

export const CreativeFormThirdStep: FC<Props> = ({
  watch,
  control,
  errors,
  setValue,
  clearErrors,
  selectedFileExtensions,
  selectedFields
}): ReactElement => {
  const isAdvertismentFormSelected = !!watch("advertisement_form");

  const attachmentListOnDelete = (toDeleteId: ID, index: number) => {
    const previousValue = watch(`extra_fields.creatives.${index}.attachments`) as TAttachment[];
    setValue(
      `extra_fields.creatives.${index}.attachments`,
      previousValue.filter((f) => f.tempId !== toDeleteId)
    );
    return;
  };

  const creatives = watch("extra_fields.creatives") || [];

  const onDelete = (index: number) => {
    creatives.splice(index, 1);
    setValue("extra_fields.creatives", creatives);
  };

  const onAdd = () => {
    creatives.push({});
    setValue("extra_fields.creatives", creatives);
  };

  const onTextAdd = (index: number) => {
    const newText = watch(`extra_fields.creatives.${index}.text`);
    if (newText) {
      const previoslyAttached = watch(`extra_fields.creatives.${index}.attachments`);
      const newTextAttachment: TAttachment = {
        tempId: uuidv4(),
        type: "text",
        text: newText
      };
      setValue(
        `extra_fields.creatives.${index}.attachments`,
        previoslyAttached ? [...previoslyAttached, newTextAttachment] : [newTextAttachment]
      );
      setValue(`extra_fields.creatives.${index}.text`, "");
    }
  };

  return (
    <>
      <p className="fs-4">Шаг 3 из 3: Добавление медиаданных</p>

      {creatives.map((cr, index) => {
        const FIELDS = {
          ADVERTISER_URLS: {
            KEY: `extra_fields.creatives.${index}.advertiser_urls`,
            WATCH: watch(`extra_fields.creatives.${index}.advertiser_urls`),
            CLEAR_ERROR: () => clearErrors(`extra_fields.creatives.${index}.advertiser_urls`)
          },
          NO_URLS: {
            KEY: `extra_fields.creatives.${index}.no_urls`,
            WATCH: watch(`extra_fields.creatives.${index}.no_urls`)
          },
          MEDIA_DESCRIPTION: {
            KEY: `extra_fields.creatives.${index}.media_description`,
            WATCH: watch(`extra_fields.creatives.${index}.media_description`)
          },
          CREATIVE_PER_FILE_WORD: {
            KEY: `extra_fields.creatives.${index}.creative_per_file_word`,
            WATCH: watch(`extra_fields.creatives.${index}.creative_per_file_word`)
          },
          TEXT: {
            KEY: `extra_fields.creatives.${index}.text`,
            WATCH: watch(`extra_fields.creatives.${index}.text`)
          },
          ATTACHMENTS: {
            KEY: `extra_fields.creatives.${index}.attachments`,
            WATCH: watch(`extra_fields.creatives.${index}.attachments`) as TAttachment[]
          }
        };

        const isNoUrls = !!FIELDS.NO_URLS.WATCH;
        const isFirstBlock = index === 0;
        const isMoreThanOneBlock = creatives.length > 1;
        const isNoAttachments = !FIELDS.ATTACHMENTS.WATCH || !FIELDS.ATTACHMENTS.WATCH.length;
        // const isNoAttachments = !!errors && !!get(errors, FIELDS.ATTACHMENTS.KEY);

        return (
          <div
            key={cr.id || index}
            className={cn("border-bottom", "mb-3", "pb-3", {
              "border-top": isFirstBlock,
              "mt-3": isFirstBlock,
              "pt-3": isFirstBlock
            })}
          >
            <div className="row">
              <div className="col-sm-6">
                <InputMultiple
                  name={FIELDS.ADVERTISER_URLS.KEY}
                  title="Целевая ссылка"
                  isRequired={!isNoUrls}
                  control={control}
                  errors={errors}
                  errorText={getErrorText(
                    errors,
                    FIELDS.ADVERTISER_URLS.KEY,
                    "Добавьте целевые ссылки либо отметьте галочкой 'Нет целевых ссылок'"
                  )}
                  disabled={isNoUrls}
                  watch={watch}
                  prefix={["https://", "http://"]}
                  keyInObject="advertiser_url"
                  maxLength={2000}
                />
              </div>

              <div className="col-sm-6">
                <p>
                  Целевая ссылка – посадочная страница, которая ведет пользователя с креатива на
                  ресурс Рекламодателя на страницу рекламируемого товара. Достаточно указать url
                  конкретной страницы, но если utm метка влияет на вид страницы, то ссылку указываем
                  с utm или добавляем несколько ссылок.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <CheckBoxField
                  name={FIELDS.NO_URLS.KEY}
                  title="Нет целевых ссылок"
                  control={control}
                  errors={errors}
                  isChecked={isNoUrls}
                  extraAction={FIELDS.ADVERTISER_URLS.CLEAR_ERROR}
                />
              </div>
            </div>

            {selectedFields && selectedFields.includes("attachments") ? (
              <div className="row">
                <div className="col-sm-12">
                  <InputField
                    name={FIELDS.MEDIA_DESCRIPTION.KEY}
                    title="Описание изображения креатива"
                    control={control}
                    errors={errors}
                    maxLength={1000}
                  />
                </div>
              </div>
            ) : null}

            {selectedFields && selectedFields.includes("text_data") ? (
              <div className="row align-items-center">
                <div className="col-sm-9">
                  <TextAreaField
                    name={FIELDS.TEXT.KEY}
                    title="Текстовые данные креатива"
                    control={control}
                    errors={errors}
                    maxLength={65000}
                    isRequired={selectedFields.includes("text_data") && isNoAttachments}
                    isStared={selectedFields.includes("text_data")}
                  />
                </div>
                <div className="col-sm-3">
                  <IconButton
                    isFullWidth
                    text="Добавить"
                    disabled={!FIELDS.TEXT.WATCH || !FIELDS.TEXT.WATCH.length}
                    icon={<FaPlus size={14} />}
                    onClick={() => onTextAdd(index)}
                  />
                </div>
              </div>
            ) : null}

            {selectedFields && selectedFields.includes("creative_per_file_word") ? (
              <div className="row">
                <div className="col-sm-12">
                  <SelectField
                    name={FIELDS.CREATIVE_PER_FILE_WORD.KEY}
                    control={control}
                    errors={errors}
                    title="Способ получения токена"
                    errorText={getErrorText(
                      errors,
                      FIELDS.CREATIVE_PER_FILE_WORD.KEY,
                      "Должен быть выбран."
                    )}
                    isRequired={selectedFields.includes("creative_per_file_word")}
                    options={[
                      {
                        value: "false",
                        label: "Получить один токен для всех загруженных файлов"
                      },
                      {
                        value: "true",
                        label: "Получить токен на каждый загруженный файл"
                      }
                    ]}
                  />
                  {FIELDS.CREATIVE_PER_FILE_WORD.WATCH ? (
                    <p className="text-primary" style={{ display: "block", marginTop: "5px" }}>
                      {TOKEN_VARIANTS_SUBTITLES[FIELDS.CREATIVE_PER_FILE_WORD.WATCH]}
                    </p>
                  ) : null}
                </div>
              </div>
            ) : null}

            {selectedFields && selectedFields.includes("attachments") ? (
              <div className="row">
                <div className="col-sm-12">
                  <DropZoneMultipleField
                    uploadFunction={uploadFile}
                    name={FIELDS.ATTACHMENTS.KEY}
                    control={control}
                    errors={errors}
                    title="Прикрепить файлы"
                    message="Выберите файлы, которые хотите загрузить, либо перетащите их в данную область."
                    fileExtensions={selectedFileExtensions}
                    isRequired={selectedFields.includes("attachments") && isNoAttachments}
                    isStared={selectedFields.includes("attachments")}
                    validate={(value: TAttachment[] | undefined) => {
                      if (value) {
                        const sumWeight = getByteToGigabyte(
                          value.reduce((prev, f) => prev + (f.file?.size || f.file_size || 0), 0)
                        );
                        if (sumWeight > 1) {
                          return `Суммарный вес прикрепленных файлов слишком большой (${
                            Math.round(sumWeight * 100) / 100
                          }Gb). Перед сохранением необходимо удалить несколько файлов, чтобы суммарный вес не превышал 1Gb.`;
                        }
                      }
                      return true;
                    }}
                  />
                </div>
              </div>
            ) : null}

            {selectedFields &&
            (selectedFields.includes("attachments") || selectedFields.includes("text_data")) ? (
              <div className="row">
                <div className="col-sm-12">
                  <AttachedFilesTable
                    attachedFiles={FIELDS.ATTACHMENTS.WATCH}
                    isError={isNoAttachments && !!get(errors, FIELDS.ATTACHMENTS.KEY)}
                    errorText="Необходимо добавить креативы"
                    onDelete={(toDeleteId) => attachmentListOnDelete(toDeleteId, index)}
                    descriptionFromOutside={FIELDS.MEDIA_DESCRIPTION.WATCH}
                    extraAction={() => {
                      const files = watch(`extra_fields.creatives.${index}.attachments`);
                      if (files) {
                        const sumWeight = getByteToGigabyte(
                          files.reduce((prev, f) => prev + (f.file?.size || f.file_size || 0), 0)
                        );
                        if (sumWeight <= 1) {
                          clearErrors(`extra_fields.creatives.${index}.attachments`);
                        }
                      }
                    }}
                  />
                </div>
              </div>
            ) : null}

            {isMoreThanOneBlock ? (
              <Button color="primary" outline onClick={() => onDelete(index)}>
                Удалить
              </Button>
            ) : null}
          </div>
        );
      })}
      {isAdvertismentFormSelected ? (
        <>
          <Button color="primary" className="mb-3" onClick={onAdd}>
            Добавить креативы с другой целевой ссылкой
          </Button>
        </>
      ) : (
        <p className="fs-5">
          Необходимо выбрать <b>форму распространения</b> на предыдущем шаге
        </p>
      )}
    </>
  );
};
