import { ContractAddForm, ContractEditForm } from "Components/ContractForm";
import { useParams } from "react-router-dom";

const ContractFormPage = () => {
  const { id } = useParams();

  return (
    <>
      {id === "add" ? <ContractAddForm /> : null}
      {id && +id ? <ContractEditForm id={+id} /> : null}
    </>
  );
};

export default ContractFormPage;
