import { ORDAccount } from "models";
import { SelectField } from "ui/Select";
import useOrdAccountsList from "utils/useOrdAccountsList";
import { FieldProps } from "./types";

const PreferredOrdAccountField = ({ control, contractLoading, watch, isShown }: FieldProps) => {
  const creatives_registrar = watch("creatives_registrar") as undefined | number;
  const contractor_ord = watch("contractor_ord") as ORDAccount[] | undefined;
  const contractor_role = watch("contractor_role") as number | undefined;
  const client_ord = watch("client_ord") as ORDAccount[] | undefined;
  const client_role = watch("client_role") as number | undefined;

  const calcIsShown = () => {
    let bool = false;

    // Необходимо регистрировать креативы -> Никому
    if (creatives_registrar === 3) bool = true;
    // Исполнитель и Заказчик Рекламные агентства
    if (contractor_role === 2 && client_role === 2) bool = true;
    // у Исполнителя и Заказчика есть орд (галочка в листинге)
    if (contractor_ord && contractor_ord.length && client_ord && client_ord.length) bool = true;

    return bool;
  };

  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  if (!calcIsShown() || !isShown) return null;

  return (
    <div className="row">
      <div className="col-sm-9">
        <SelectField
          name="preferred_ord_account"
          control={control}
          title="Выбрать аккаунт ОРД для отправки"
          options={ordAccountsTitlesOptions}
          disabled={ordAccountsLoading || ordAccountsError || contractLoading}
          isRequired
        />
      </div>
    </div>
  );
};

export default PreferredOrdAccountField;
