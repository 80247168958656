import cn from "classnames";
import { ID } from "models";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

type Props = {
  target: ReactNode;
  children: ReactNode;
  id: ID;
  maxWidth?: string;
  alignLeft?: boolean;
};

const domNode = document.createElement("div");
domNode.id = "tooltip-portal";
document.body.appendChild(domNode);

const Tooltip = ({ target, children, id, maxWidth, alignLeft }: Props) => {
  return (
    <>
      {target}
      {createPortal(
        <ReactTooltip place="top" style={{ zIndex: 10000 }} anchorSelect={`#${id}`}>
          <div style={{ maxWidth }} className={cn("fs-6", "lh-sm", { "text-center": !alignLeft })}>
            {children}
          </div>
        </ReactTooltip>,
        domNode
      )}
    </>
  );
};

export default Tooltip;
