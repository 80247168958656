export const DashboardTabs = [
  {
    id: 0,
    slug: "ord",
    name: "Юр.лица"
  },
  {
    id: 1,
    slug: "statistics",
    name: "Статистика"
  }
];
