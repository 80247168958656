import { importFunction, ImportVariant } from "api/import_api";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { TFileUploadFailResponse, TFileUploadResponse } from "types/ResponseTypes";
import { DropZoneField } from "ui/DropZone";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  importVariant: ImportVariant;
  exampleLink: string | string[];
  exampleTitle: string | string[];
  refetch: () => Promise<any>;
};

const defaultValues: {
  file?: FormData;
} = {
  file: undefined
};

const ImportModal = ({
  isOpen,
  toggle,
  importVariant,
  refetch,
  exampleLink,
  exampleTitle
}: Props) => {
  const [currentErrorsObject, setCurrentErrorsObject] = useState<TFileUploadFailResponse | null>(
    null
  );
  const [currentSuccessObject, setCurrentSuccessObject] = useState<TFileUploadResponse | null>(
    null
  );

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  const onSubmit = handleSubmit(() => {
    const file = watch("file");
    if (file) {
      importFunction(file, importVariant)
        .then(async (res) => {
          setCurrentSuccessObject(res);
          setCurrentErrorsObject(null);
          await refetch();
        })
        .catch((err) => {
          setCurrentErrorsObject(err as TFileUploadFailResponse);
          setCurrentSuccessObject(null);
        });
    }
  });

  return (
    <Modal
      className="p-0 modal-shadow-none"
      style={{ maxWidth: "50vw" }}
      isOpen={isOpen}
      toggle={toggle}
      centered
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          void onSubmit();
        }}
      >
        <ModalHeader toggle={toggle}>Импорт данных</ModalHeader>
        <ModalBody>
          <DropZoneField
            name="file"
            exampleLink={exampleLink}
            exampleTitle={exampleTitle}
            buttonText="Скачать отчет по импорту"
            noErrorsNotification="Вы можете скачать и ознакомиться с отчетом по импорту:"
            control={control}
            fileTypes={[".xlsx", ".xls", ".csv"]}
            maxFiles={1}
            responsePrintField="file_path"
            isRequired
            errors={errors}
            currentErrorsObject={currentErrorsObject}
            currentSuccessObject={currentSuccessObject}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={!watch("file")}>
            Загрузить
          </Button>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              toggle();
              reset();
              setCurrentErrorsObject(null);
              setCurrentSuccessObject(null);
            }}
          >
            Отмена
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ImportModal;
