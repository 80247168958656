import ErrorLayout from "./layout";

type TProps = {
  error?: string;
  status?: number;
  isEmbedded?: boolean;
};

const ErrorComponent = ({ error, status, isEmbedded }: TProps) => {
  if (status === 404)
    return (
      <ErrorLayout
        isEmbedded={isEmbedded}
        status={404}
        title="Страница не найдена"
        subtitle="Страница, которую вы запросили, не существует. Возможно, вы ошиблись при вводе адреса или страница была удалена."
      />
    );

  if (status === 403)
    return (
      <ErrorLayout
        isEmbedded={isEmbedded}
        status={403}
        title="Ошибка доступа"
        subtitle="Доступ к странице запрещен или у вас нет разрешения на доступ к данной странице."
      />
    );

  if (status === 500)
    return (
      <ErrorLayout
        isEmbedded={isEmbedded}
        status={500}
        title="Ошибка сервера"
        subtitle="На сервере произошла непредвиденная ошибка. Мы работаем над ее исправлением. Пожалуйста, попробуйте еще раз позже."
      />
    );

  return <ErrorLayout isEmbedded={isEmbedded} title={error || "Ошибка сервера"} />;
};

export default ErrorComponent;
