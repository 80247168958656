const getTextShortened = (text: string | undefined, chars: number) => {
  if (!text) return;

  const length = text.length;

  if (length < chars) return text;

  return text.substring(0, chars) + "...";
};

export default getTextShortened;
