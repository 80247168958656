import axios from "axios";
import Cookies from "js-cookie";
import { Participant } from "models";

export const addParticipant = ({ participant }: { participant: Participant }) => {
  return axios
    .post(`/api/v1/participant/`, participant, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      }
    })
    .then((res) => {
      return res;
    });
};
