import { getDashboardOrdListInfo } from "api/dashboard_list";
import { getParticipantList } from "api/participant_list";
import PageSizes from "constants/PageSizes";
import Cookies from "js-cookie";
import { DashboardOrdData, DashboardOrdResults, ID } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import Loader from "react-loaders";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import { setDashboardOrdLastSearchParams } from "reducers/LastSearchParams";
import { PageSizeSelector } from "ui/PageSizeSelector";
import { Pagination } from "ui/Pagination";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import getLastPageNumber from "utils/getLastPageNumber";
import getOnlyValidObj from "utils/getOnlyValidObj";

const defaultSearchParams = {
  page: 0,
  page_size: 10
};

export const DashboardOrdTable = () => {
  const navigate = useNavigate();
  const [participantIds, setParticipantIds] = useState<ID[]>([]);
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  const {
    isLoading: dashboardLoading,
    error: dashboardError,
    data: dashboardInfoList
  } = useQuery({
    queryKey: ["dashboard_ord_list", searchParams.page, searchParams.page_size],
    queryFn: () =>
      getDashboardOrdListInfo({ page: searchParams.page, size: searchParams.page_size }),
    onSuccess: ({ data }) => setParticipantIds(data?.results?.map(({ id }) => id))
  });

  const { data: participantsInfoList } = useQuery({
    queryKey: ["participant_list", participantIds],
    queryFn: () => getParticipantList({ filter: { id: participantIds }, size: participantIds.length })
  });

  const dashboardData = dashboardInfoList?.data as DashboardOrdData;
  const dashboardResults: DashboardOrdResults[] = dashboardData?.results || [];
  const count = dashboardData?.count || 0;
  const participants = participantsInfoList?.data?.results || [];

  const onRowDoubleClick = (id: ID) => navigate(`/participant/${id}`);

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          page_size: searchParams.page_size,
          page: page + 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeDashboardOrd", page_size.toString());
    const lastPageNumber = getLastPageNumber(count, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          page,
          page_size
        })
      )
    });
  };

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeDashboardOrd");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    setSearchParams({
      page,
      page_size
    });
    dispatch(setDashboardOrdLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  if (dashboardError) {
    return <div>Error</div>;
  }

  return (
    <>
      <Table hover className="mb-0" responsive>
        <thead>
          <tr>
            <th style={{ width: "100px" }}>ID</th>
            <th>Название</th>
            <th style={{ width: "200px" }}>ИНН</th>
            <th style={{ width: "120px" }}>Я</th>
            <th style={{ width: "120px" }}>ВК</th>
            <th style={{ width: "120px" }}>ОЗОН</th>
            <th style={{ width: "120px" }}>Медиаскаут</th>
          </tr>
        </thead>
        <tbody>
          {dashboardLoading ? (
            <tr>
              <td colSpan={7}>
                <Loader type="ball-beat" active innerClassName="loader-for-table" />
              </td>
            </tr>
          ) : (
            <>
              {dashboardResults.map((info: DashboardOrdResults) => {
                const onDoubleClick = () => (info.id ? onRowDoubleClick(info.id) : null);
                const ordAccs = participants.find(({ id }) => id === info.id)?.extra_fields
                  ?.ord_accounts;

                return (
                  <tr key={info.id} onDoubleClick={onDoubleClick}>
                    <td scope="row" style={{ width: "100px" }}>
                      {info.id}
                    </td>
                    <ParticipantCell
                      id={`participant${info.id}`}
                      ord={ordAccs}
                      name={info.name}
                      customText="По данному юр.лицу можно сдавать отчетность в следующих системах:"
                    />
                    <td style={{ width: "200px" }}>{info.inn}</td>
                    <td style={{ width: "120px" }} className="text-success">
                      {info.ord.includes("YANDEX") && <FaRegCircleCheck size={24} />}
                    </td>
                    <td style={{ width: "120px" }} className="text-success">
                      {info.ord.includes("VK") && <FaRegCircleCheck size={24} />}
                    </td>
                    <td style={{ width: "120px" }} className="text-success">
                      {info.ord.includes("OZON") && <FaRegCircleCheck size={24} />}
                    </td>
                    <td style={{ width: "120px" }} className="text-success">
                      {info.ord.includes("MEDIASCOUT") && <FaRegCircleCheck size={24} />}
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center flex-wrap gap-3 pt-3 pb-3">
        <Pagination
          currentPage={searchParams.page}
          lastPageNumber={getLastPageNumber(count, searchParams.page_size)}
          setCurrentPage={onPageChange}
        />
        <PageSizeSelector
          sizes={PageSizes}
          currentSize={searchParams.page_size}
          setCurrentSize={onPageSizeSelect}
        />
      </div>
    </>
  );
};
