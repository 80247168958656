import { deleteParticipant } from "api/participant_delete";
import legal_participant_form from "dictionaries/legal_participant_form.json";
import participant_roles from "dictionaries/participant_roles.json";
import participant_status from "dictionaries/participant_status.json";
import { ID, Participant } from "models";
import { Fragment } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import getTitleFromDict from "utils/getTitleFromDict";
import onDeleteMutation from "utils/onDeleteMutation";
import getActions from "./getActions";

interface Props extends IExportRelatedProps {
  participants: Participant[];
  refetch: () => Promise<any>;
}

export const ParticipantTable = ({
  participants,
  exportIds,
  onExportIdCheck,
  pageIds,
  setExportIds,
  refetch
}: Props) => {
  const navigate = useNavigate();
  const onRowDoubleClick = (id: ID) => navigate(`/participant/${id}`);

  const { mutate: del } = useMutation({
    mutationFn: deleteParticipant,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        refetch,
        successMessage: "Контрагент удален"
      })
  });

  return (
    <>
      <ListingTable
        theadList={["Название", "ИНН", "Тип", "Статус", "Роли", "Договоры"]}
        pageIds={pageIds}
        exportIds={exportIds}
        setExportIds={setExportIds}
        tbody={participants.map((participant: Participant) => (
          <ListingTableRow
            key={participant.id}
            id={participant.id}
            item={participant}
            onRowDoubleClick={() => (participant.id ? onRowDoubleClick(participant.id) : null)}
            actions={getActions(participant, del)}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            list={
              <>
                <ParticipantCell
                  id={`participant${participant.id}`}
                  ord={participant.extra_fields?.ord_accounts}
                  name={participant.name}
                />
                <td>{participant.inn}</td>
                <td>{getTitleFromDict(participant.legal_form, legal_participant_form)}</td>
                <StatusCell data={participant} dictionary={participant_status} />
                <td>
                  {participant.extra_fields?.contracts_roles.map((role) =>
                    role !== null ? (
                      <Fragment key={role}>
                        <span>{participant_roles[role]}</span>
                        <br />
                      </Fragment>
                    ) : null
                  )}
                </td>
                <td>
                  {participant.extra_fields?.contracts_count ? (
                    <a href={`#contract/?participant=${participant.id}`}>
                      {participant.extra_fields?.contracts_count}
                    </a>
                  ) : (
                    participant.extra_fields?.contracts_count
                  )}
                </td>
              </>
            }
          />
        ))}
      />
      <ListingCardsWrapper pageIds={pageIds} exportIds={exportIds} setExportIds={setExportIds}>
        {participants.map((participant: Participant) => (
          <ListingCard
            key={participant.id}
            id={participant.id}
            item={participant}
            onHeaderClick={() => participant.id && onRowDoubleClick(participant.id)}
            header={`${participant.id} | ${participant.name}`}
            actions={getActions(participant, del)}
            href={`#/participant/${participant.id}`}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            list={
              <>
                <ListGroupItem>ИНН: {participant.inn}</ListGroupItem>
                <ListGroupItem>
                  Тип: {getTitleFromDict(participant.legal_form, legal_participant_form)}
                </ListGroupItem>
                <ListGroupItem>
                  Статус: <StatusInline data={participant} dictionary={participant_status} />
                </ListGroupItem>
                <ListGroupItem>
                  Роли:{" "}
                  {participant.extra_fields?.contracts_roles
                    .map((role) => participant_roles[role])
                    .join(", ")}
                </ListGroupItem>
                <ListGroupItem>
                  Договоры:{" "}
                  <a href={`#contract/?participant=${participant.id}`}>
                    {participant.extra_fields?.contracts_count}
                  </a>
                </ListGroupItem>
              </>
            }
          />
        ))}
      </ListingCardsWrapper>
    </>
  );
};
