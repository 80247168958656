import { UseFormSetError } from "react-hook-form";
import { NavigateFunction } from "react-router-dom";
import { TAPIResponse, TAPIResponseError } from "types/ResponseTypes";
import createNotification from "ui/Notification";

type Props = {
  response: TAPIResponse;
  refetch?: () => Promise<any>;
  successMessage?: string;
  href?: string;
  navigate?: NavigateFunction;
};

const onErrorMutation = (response: TAPIResponseError, setError?: UseFormSetError<any>) => {
  const { form_errors, notifications, error_message } = response;

  if (form_errors) {
    Object.keys(form_errors).forEach((key) => {
      if (setError && key !== "non_field_errors")
        setError(key, { type: "custom", message: form_errors[key].join() });
      else createNotification("error", form_errors[key].join());
    });
  }

  if (notifications) notifications.forEach((item) => createNotification(item.tags, item.message));
  else if (error_message) createNotification("error", error_message);
};

const onDeleteMutation = ({ response, refetch, successMessage, href, navigate }: Props) => {
  if (response === "success") {
    createNotification("success", successMessage || "Успешно");
    if (refetch) {
      refetch()
        .then(() => {})
        .catch(() => {});
    }
    if (href && navigate) {
      navigate(href);
    }
  } else {
    onErrorMutation(response);
  }
};

export default onDeleteMutation;
