import { PlatformAddForm, PlatformEditForm } from "Components/PlatformForm";
import { useParams } from "react-router-dom";

const PlatformFormPage = () => {
  const { id } = useParams();

  return (
    <>
      {id === "add" ? <PlatformAddForm /> : null}
      {id && +id ? <PlatformEditForm id={+id} /> : null}
    </>
  );
};

export default PlatformFormPage;
