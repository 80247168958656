import invoice_status from "dictionaries/invoice_status.json";
import { Invoice } from "models";
import { Table } from "reactstrap";
import { AttributesSyncStatus } from "ui/AttributesDropdown/helpers/syncStatus";
import getTitleFromDict from "utils/getTitleFromDict";

export const InvoiceAttributesBody = ({ data }: { data: Invoice | undefined }) => {
  if (!data) {
    return (<></>);
  }

  const sync_status = data.extra_fields?.sync_status;

  return (
    <Table borderless hover className="mb-0">
      <tbody>
        <tr>
          <td style={{ width: "100px" }}>Статус</td>
          <td>{getTitleFromDict(data.status, invoice_status)}</td>
          <td></td>
        </tr>
        <AttributesSyncStatus syncStatus={sync_status} />
      </tbody>
    </Table>
  );
};
