import Cookies from "js-cookie";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { setCreativeLastSearchParams } from "reducers/LastSearchParams";
import { Dispatch, UnknownAction } from "redux";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getStringFromSearchParam from "utils/getStringFromSearchParam";
import { defaultSearchParams } from "../constants";

const useCreativesSearchParams = ({
  searchProperties,
  dispatch
}: {
  searchProperties: string;
  dispatch: Dispatch<UnknownAction>;
}) => {
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeCreative");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const start_date = newSearchParams.start_date
      ? getStringFromSearchParam(newSearchParams.start_date)
      : defaultSearchParams.filter?.start_date;
    const end_date = newSearchParams.end_date
      ? getStringFromSearchParam(newSearchParams.end_date)
      : defaultSearchParams.filter?.end_date;
    const contract = newSearchParams.contract
      ? getNumberArrayFromSearchParam(newSearchParams.contract)
      : defaultSearchParams.filter?.contract;
    const marker = newSearchParams.marker
      ? getStringFromSearchParam(newSearchParams.marker)
      : defaultSearchParams.filter?.marker;
    const initial_contract__client = newSearchParams.initial_contract__client
      ? getNumberArrayFromSearchParam(newSearchParams.initial_contract__client)
      : defaultSearchParams.filter?.initial_contract__client;
    const advertisement_form = newSearchParams.advertisement_form
      ? getNumberArrayFromSearchParam(newSearchParams.advertisement_form)
      : defaultSearchParams.filter?.advertisement_form;
    const advertisement_type = newSearchParams.advertisement_type
      ? getNumberArrayFromSearchParam(newSearchParams.advertisement_type)
      : defaultSearchParams.filter?.advertisement_type;
    const group = newSearchParams.group
      ? getNumberArrayFromSearchParam(newSearchParams.group)
      : defaultSearchParams.filter?.group;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        ord_account,
        combined_status,
        start_date,
        end_date,
        contract,
        marker,
        initial_contract__client,
        advertisement_form,
        advertisement_type,
        group
      }
    });
    dispatch(setCreativeLastSearchParams(searchProperties));
    // void creativesRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  return searchParams;
};
export default useCreativesSearchParams;
