import documentation from "assets/utils/documents/ADVmarker_пользовательская_документация_WIP.pdf";
import { PiBookBookmark, PiFilePdf, PiLink } from "react-icons/pi";
import { Card, CardBody } from "reactstrap";
import LinkWithIcon from "ui/LinkWithIcon";

const KnowledgeBase = () => {
  return (
    <div className="col-12 col-md-12 col-lg-3">
      <Card className="he-100">
        <CardBody className="d-flex flex-column">
          <div className="d-flex gap-2 align-items-center">
            <PiBookBookmark size={40} />
            <p className="m-0 fs-5 fw-bold">База знаний</p>
          </div>
          <div className="d-flex flex-column justify-content-center" style={{ height: "100%" }}>
            <LinkWithIcon
              href={documentation}
              target="_blank"
              text="Руководство пользователя"
              className="mb-2 mb-lg-3 mt-4 mt-lg-0"
              icon={PiFilePdf}
            />
            <LinkWithIcon
              href="https://advmarker.ru/api/v1/swagger/"
              target="_blank"
              text="Swagger ADVmarker"
              className="mb-2 mb-lg-0"
              icon={PiLink}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default KnowledgeBase;
