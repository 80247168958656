import { ActionSelectorProps } from "ui/ActionSelector";

const getActions = (): ActionSelectorProps[] => {
  return [
    {
      title: "Редактировать",
      calcHref: (id) => ({ href: `#/invoice/${id}/` })
    },
    {
      title: "Удалить",
      onClick: (id, item) => {
        console.log(id, item);
      }, // TODO: добавить удаление статистики
      calcIsDisabled: (item) => item.status === 1
    }
  ];
};

export default getActions;
