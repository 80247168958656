const WrappedCell = ({ text, maxLength = 50 }: { text?: string; maxLength?: number }) =>
  text ? (
    <td>
      <div
        style={
          text.length > maxLength
            ? { minWidth: `${maxLength}ch`, display: "inline-block" }
            : { whiteSpace: "nowrap" }
        }
      >
        {text}
      </div>
    </td>
  ) : (
    <td />
  );

export default WrappedCell;
