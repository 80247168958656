import { PiEnvelopeLight } from "react-icons/pi";
import { Button, Card, CardBody } from "reactstrap";
import InfoTooltip from "./InfoTooltip";

const Contacts = () => {
  return (
    <div className="col-12 col-md-4 col-lg-3">
      <Card className="he-100">
        <CardBody className="d-flex gap-2 align-items-center flex-column p-lg-4">
          <InfoTooltip id="contactsTooltip" />
          <PiEnvelopeLight size={40} />
          <p className="m-0 fs-5 fw-bold">Контакты</p>
          <p className="text-center">
            Если у&nbsp;вас возникли вопросы по&nbsp;работе сервиса, напишите на&nbsp;электронный
            адрес
          </p>
          <Button
            href="mailto:support@advmarker.ru"
            tag="a"
            color="link"
            style={{ marginTop: "auto" }}
          >
            support@advmarker.ru
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default Contacts;
