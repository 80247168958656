import { PiQuestion } from "react-icons/pi";
import LinkWithIcon from "ui/LinkWithIcon";

const AppFooter = () => {
  return (
    <div className="app-footer">
      <div className="app-footer__inner">
        <LinkWithIcon
          href="#/help"
          target="_blank"
          text="Справочный центр"
          icon={PiQuestion}
          className="text-muted"
        />
      </div>
    </div>
  );
};

export default AppFooter;
