import StatusInline from "./StatusInline";
import TStatusDisplay from "./type";

const StatusCell = ({ data, dictionary }: TStatusDisplay) => {
  if (data)
    return (
      <td>
        <StatusInline data={data} dictionary={dictionary} />
      </td>
    );

  return <td></td>;
};

export default StatusCell;
