export const MyNav = [
  {
    icon: "folder",
    label: "Дашборд",
    to: "#/dashboard/"
  },
  {
    icon: "folder",
    label: "Контрагенты",
    to: "#/participant/",
    pathName: "participant"
  },
  {
    icon: "folder",
    label: "Договоры",
    to: "#/contract/",
    pathName: "contract"
  },
  {
    icon: "folder",
    label: "Акты",
    to: "#/invoice/",
    pathName: "invoice"
  },
  {
    icon: "folder",
    label: "Статистика",
    to: "#/statistics/",
    pathName: "statistics"
  },
  {
    icon: "folder",
    label: "Креативы",
    to: "#/creative/",
    pathName: "creative"
  },
  {
    icon: "folder",
    label: "Площадки",
    to: "#/platform/",
    pathName: "platform"
  }
];
