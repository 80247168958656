import axios from "axios";
import { Participant } from "models";
import { TParticipantFilter } from "types/TParticipantFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsParticipant extends TSearchParamsPropsNoFilter {
  filter?: TParticipantFilter;
}

export const getParticipantList = (
  props?: IPropsParticipant
): Promise<APIListData<Participant>> => {
  return axios
    .get(
      props === undefined
        ? `/api/v1/participant/`
        : `/api/v1/participant/?${getSearchParams(props)}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        timeout: 1000
      }
    )
    .then((res) => {
      return res.data as Promise<APIListData<Participant>>;
    });
};
