import ErrorComponent from "Components/ErrorComponent";
import { ParticipantFormDV } from "constants/defaultValues/ParticipantFormDV";
import participant_status from "dictionaries/participant_status.json";
import EditorFooter from "Layout/EditorFooter";
import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { TEditorFooters } from "types/TEditorFooters";
import { AttributesDropdown } from "ui/AttributesDropdown";
import StatusInline from "ui/StatusDisplay/StatusInline";
import { scrollToError } from "utils/scrollToError";
import { ParticipantAttributesBody } from "./components/attributes";
import { ParticipantForm } from "./components/form";
import useDeleteParticipant from "./hooks/useDeleteParticipant";
import useEditParticipant from "./hooks/useEditParticipant";
import useLoadParticipant from "./hooks/useLoadParticipant";
import { PosibleParticipantStatuses } from "./types";

type Props = {
  id: ID;
};

const ParticipantEditForm = ({ id }: Props) => {
  const participantLastSearch = useSelector(
    (state: StateProps) => state.LastSearchParams.participant
  );
  const [status, setStatus] = useState<PosibleParticipantStatuses>("LOADING");
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setError
  } = useForm({ defaultValues: ParticipantFormDV });

  const { isLoading, error, data } = useLoadParticipant(id, reset, setStatus);
  const edit = useEditParticipant(participantLastSearch, watch("name"), setError);
  const del = useDeleteParticipant(participantLastSearch, watch("name"));

  const onSubmit = handleSubmit(
    () => {
      edit({
        id,
        participant: {
          ...watch(),
          status: status === "DRAFT" ? 2 : 1
        }
      });
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  if (error) {
    return <ErrorComponent error={error.message} status={error.response?.status} isEmbedded />;
  }

  const buttons: TEditorFooters<PosibleParticipantStatuses> = {
    DRAFT: (
      <EditorFooter
        left={
          <>
            <Button
              type="button"
              color="danger"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                del(id);
              }}
            >
              Удалить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("DRAFT")}
            >
              Сохранить черновик
            </Button>
          </>
        }
        right={
          <>
            <Button
              href={`#/participant/${participantLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Добавить
            </Button>
          </>
        }
      />
    ),
    ACTIVE: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/participant/${participantLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Сохранить
            </Button>
          </>
        }
      />
    ),
    SYNCHRONIZED_OR_SYNCHRONIZING: (
      <EditorFooter
        right={
          <Button
            href={`#/participant/${participantLastSearch}`}
            tag="a"
            color="primary"
            outline
            disabled={isLoading}
          >
            Закрыть
          </Button>
        }
      />
    ),
    SYNC_ERROR: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/participant/${participantLastSearch}`}
              tag="a"
              color="primary"
              outline
              disabled={isLoading}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={() => setStatus("ACTIVE")}
            >
              Сохранить
            </Button>
          </>
        }
      />
    ),
    LOADING: <EditorFooter />
  };

  return (
    <div>
      <h1>Редактирование контрагента {watch("name")}</h1>

      <AttributesDropdown
        title={
          <div className="d-flex justify-content-between align-items-center">
            Статус контрагента:{" "}
            {data?.data ? StatusInline({ data: data.data, dictionary: participant_status }) : ""}
          </div>
        }
      >
        <ParticipantAttributesBody data={data?.data} />
      </AttributesDropdown>

      <ParticipantForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons[status]}
        watch={watch}
        isLoading={isLoading}
        isDraft={status === "DRAFT"}
      />
    </div>
  );
};

export default ParticipantEditForm;
