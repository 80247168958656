import { getParticipantList } from "api/participant_list";
import uniqBy from "lodash/uniqBy";
import { ID } from "models";
import { useMutation } from "react-query";

const useSearchParticipantsMutation = (searchValue?: string, id?: ID | null) =>
  useMutation({
    mutationFn: () =>
      Promise.all([
        getParticipantList(searchValue ? { search: searchValue } : undefined),
        getParticipantList({ filter: id ? { id: [id] } : {} })
      ])
        .then(([searchRes, byIdsRes]) => {
          const results = uniqBy([...searchRes.data.results, ...byIdsRes.data.results], "id");
          return {
            status: true,
            data: { count: results.length, next: "", previous: "", results }
          };
        })
        .catch(() => ({ status: false, data: { count: 0, next: "", previous: "", results: [] } }))
  });

export default useSearchParticipantsMutation;
