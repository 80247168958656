import { AxiosResponse } from "axios";
import { INotification } from "models";
import { ReactElement } from "react";
import { NavigateFunction } from "react-router";
import createNotification from "ui/Notification";

const onSuccessMutation = (
  info: AxiosResponse<any, any>,
  href?: string,
  navigate?: NavigateFunction,
  key?: string | false,
  successMessage?: string | ReactElement
) => {
  if (info) {
    if (info.data?.notifications)
      (info.data?.notifications || []).forEach((item: INotification) => {
        createNotification(item.tags, item.message);
      });
    else createNotification("success", successMessage || "Изменения успешно сохранены");
  }
  if (href && navigate) {
    navigate(`${href}${key !== false ? info.data.data[key || "id"] : ""}`);
  }
};

export default onSuccessMutation;
