import {
  combinedStatusOptions,
  onlyErirOptions
} from "constants/selectOptions/CombinedStatusOptions";
import LegalParticipantFormOptions from "constants/selectOptions/LegalParticipantFormOptions";
import ParticipantRolesOptions from "constants/selectOptions/ParticipantRolesOptions";
import FilterLayout from "Layout/FilterLayout";
import { FormEvent, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { TParticipantFilter } from "types/TParticipantFilter";
import { selectColorSchemes, SelectField } from "ui";
import useOrdAccountsList from "utils/useOrdAccountsList";

type Props = {
  filter: TParticipantFilter;
  filterClear: TParticipantFilter;
  onFilterClick: (_: TParticipantFilter) => void;
  onFilterReset: () => void;
  onClose: () => void;
};

export const ParticipantFilter = ({
  filter,
  filterClear,
  onFilterClick,
  onFilterReset,
  onClose
}: Props) => {
  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  const [statusTitlesOptions, setStatusTitlesOptions] = useState(
    filter.ord_account?.length ? onlyErirOptions : combinedStatusOptions
  );

  const { control, watch, reset, setValue } = useForm({
    defaultValues: filter
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onFilterClick(watch());
  };

  const onReset = () => {
    reset(filterClear);
    onFilterReset();
  };

  return (
    <FilterLayout onSubmit={onSubmit} onReset={onReset} onClose={onClose}>
      <SelectField
        name="legal_form"
        control={control as unknown as Control<FieldValues, any>}
        title="Тип"
        isMulti
        options={LegalParticipantFormOptions}
      />
      <SelectField
        name="ord_account"
        control={control as unknown as Control<FieldValues, any>}
        title="Аккаунт в ОРД"
        isMulti
        options={ordAccountsTitlesOptions}
        disabled={ordAccountsLoading || ordAccountsError}
        onChange={(newVal) => {
          if (newVal && (newVal as (string | number)[]).length) {
            setValue(
              "combined_status",
              watch("combined_status")?.filter((st) => st === 4 || st === 5 || st === 6)
            );
            setStatusTitlesOptions(onlyErirOptions);
          } else {
            setStatusTitlesOptions(combinedStatusOptions);
          }
        }}
      />
      <SelectField
        name="combined_status"
        control={control as unknown as Control<FieldValues, any>}
        title="Статус"
        isMulti
        options={statusTitlesOptions}
        colorSchemes={{
          4: selectColorSchemes.yellow,
          5: selectColorSchemes.green,
          6: selectColorSchemes.red
        }}
      />
      <SelectField
        name="role"
        control={control as unknown as Control<FieldValues, any>}
        title="Роль"
        isMulti
        options={ParticipantRolesOptions}
      />
    </FilterLayout>
  );
};
