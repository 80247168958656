import get from "lodash/get";
import { FieldErrors, FieldValues } from "react-hook-form";
import { CSSObjectWithLabel } from "react-select";
import { TColorSchemes, TOption } from ".";

const Styles = {
  control: (
    styles: CSSObjectWithLabel,
    errors: FieldErrors<FieldValues> | undefined,
    name: string
  ): any =>
    errors && !!get(errors, name)
      ? {
          ...styles,
          borderColor: "#d92550"
        }
      : styles,

  option: (styles: CSSObjectWithLabel, data: TOption, colorSchemes?: TColorSchemes): any =>
    colorSchemes
      ? {
          ...styles,
          ...colorSchemes[data.value]
        }
      : styles,

  singleValue: (styles: CSSObjectWithLabel, data: TOption, colorSchemes?: TColorSchemes): any =>
    colorSchemes
      ? {
          ...styles,
          ...colorSchemes[data.value]
        }
      : styles,

  multiValue: (styles: CSSObjectWithLabel, data: TOption, colorSchemes?: TColorSchemes): any =>
    colorSchemes
      ? {
          ...styles,
          ...colorSchemes[data.value]
        }
      : styles,

  multiValueLabel: (
    styles: CSSObjectWithLabel,
    data: TOption,
    colorSchemes?: TColorSchemes
  ): any =>
    colorSchemes
      ? {
          ...styles,
          ...colorSchemes[data.value]
        }
      : styles,

  menu: (styles: CSSObjectWithLabel): any => ({
    ...styles,
    zIndex: 99
  })
};

export default Styles;
