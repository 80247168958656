import pickBy from "lodash/pickBy";

const getOnlyValidObj = (
  obj: {
    [k: string]: any;
  },
  arr: any[] = [0, null, undefined, "", false, [] as number[]]
) =>
  pickBy(obj, function (value) {
    return !arr.includes(value);
  });

export default getOnlyValidObj;
