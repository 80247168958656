import { TNotVisibleFields } from "utils/onErrorMutation";

export const notVisibleFieldsContract: TNotVisibleFields = [
  {
    name: "subject_type",
    title: "Вид договора"
  },
  {
    name: "action_type",
    title: "Вид деятельности"
  },
  {
    name: "contract_number",
    title: "Номер договора"
  },
  {
    name: "vat_included",
    title: "Наличие НДС"
  },
  {
    name: "amount",
    title: "Сумма договора в рублях"
  },
  {
    name: "contract_date",
    title: "Дата договора"
  },
  {
    name: "creatives_registrar",
    title: "Необходимо регистрировать креативы"
  },
  {
    name: "client",
    title: "Заказчик"
  },
  {
    name: "client_role",
    title: "Роль заказчика"
  },
  {
    name: "contractor",
    title: "Исполнитель"
  },
  {
    name: "contractor_role",
    title: "Роль исполнителя"
  },
  {
    name: "self_ad_participant",
    title: "Контрагент саморекламы"
  },
  {
    name: "parent_contract",
    title: "Договор к которому относится ДС"
  },
  {
    name: "initial_contracts",
    title: "Изначальные договоры"
  },
  {
    name: "is_agent_acting_for_publisher",
    title: "Агент действует в интересах"
  }
];
