import cn from "classnames";
import PageSizes from "constants/PageSizes";
import { ReactNode } from "react";
import { LiaFilterSolid } from "react-icons/lia";
import { Button, Modal } from "reactstrap";
import { ListingActions } from "ui/ListingActions";
import { PageSizeSelector } from "ui/PageSizeSelector";
import { Pagination } from "ui/Pagination";
import { Search } from "ui/Search";
import { OrderingSelector, OrderingVariant } from "ui/SortSelector";
import { TableLoad } from "ui/TableLoad";
import { WrongPageOrFilter } from "ui/WrongPageOrFilter";
import getLastPageNumber from "utils/getLastPageNumber";

type Props = {
  onFilterToggle?: () => void;
  onSearchClick: (_: string) => void;
  onPageChange: (_: number) => void;
  onPageSizeSelect: (_: number) => void;
  onOrderSelect: (_: string) => void;
  onResetFilter?: () => void;
  onResetPage: () => void;
  currentSearchQuery: string;
  addHref: string;
  isDataLoading: boolean;
  isFilterModalOpen?: boolean;
  currentPageSize: number;
  currentPage: number;
  currentFilter?: {
    [key: string]: any;
  };
  count: number | undefined;
  table: ReactNode;
  filter?: ReactNode;
  searchPlaceholder?: string;
  actionsDropdownMenu?: ReactNode;
  orderVariants: OrderingVariant[];
  currentOrder: string;
};

const ListingLayout = ({
  onFilterToggle,
  onSearchClick,
  onPageChange,
  onPageSizeSelect,
  onOrderSelect,
  onResetFilter,
  onResetPage,
  currentSearchQuery,
  addHref,
  isDataLoading,
  isFilterModalOpen,
  currentFilter,
  currentPageSize,
  currentPage,
  count,
  table,
  filter,
  searchPlaceholder,
  actionsDropdownMenu,
  orderVariants,
  currentOrder
}: Props) => (
  <>
    <div className="row">
      <div className="col-12 col-md-12 d-flex justify-content-between flex-wrap">
        <Button className="col-2 col-md-2 order-2 order-md-0" onClick={onFilterToggle}>
          <span className="d-none d-md-inline" style={{ marginRight: "5px" }}>
            Фильтр
          </span>
          <LiaFilterSolid size={16} />
        </Button>
        {actionsDropdownMenu ? (
          <div className="col-2 col-md-1 order-3 order-md-0">
            <ListingActions actionsDropdownMenu={actionsDropdownMenu} />
          </div>
        ) : null}
        <div
          className={cn(
            "col-12",
            { "col-md-6": actionsDropdownMenu, "col-md-7": !actionsDropdownMenu },
            "mb-3",
            "mb-md-0"
          )}
        >
          <Search
            onSearchClick={onSearchClick}
            currentQuery={currentSearchQuery}
            placeholder={searchPlaceholder}
          />
        </div>
        <div
          className={cn(
            { "col-7": actionsDropdownMenu, "col-9": !actionsDropdownMenu },
            "col-md-2",
            "order-4",
            "order-md-0"
          )}
        >
          <Button className="w-100" color="danger" href={addHref} tag="a">
            Добавить
          </Button>
        </div>
      </div>
    </div>
    <div className="row mt-3">
      {isDataLoading || count ? (
        <div className="d-block col-sm-12">
          {isDataLoading ? <TableLoad numberOfRows={currentPageSize} /> : table}
          <div className="d-flex justify-content-between align-items-center flex-wrap gap-3 pt-3 pb-3">
            <Pagination
              currentPage={currentPage}
              lastPageNumber={getLastPageNumber(count || 0, currentPageSize)}
              setCurrentPage={onPageChange}
            />
            <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
              <OrderingSelector
                setCurrentOrder={onOrderSelect}
                currentOrder={currentOrder}
                variants={orderVariants}
              />
              <PageSizeSelector
                sizes={PageSizes}
                currentSize={currentPageSize}
                setCurrentSize={onPageSizeSelect}
              />
            </div>
          </div>
        </div>
      ) : (
        <WrongPageOrFilter
          currentFilter={currentFilter}
          currentPage={currentPage}
          resetFilter={onResetFilter}
          resetPage={onResetPage}
        />
      )}
    </div>
    <Modal
      className="p-0 modal-shadow-none"
      isOpen={isFilterModalOpen}
      toggle={onFilterToggle}
      centered
    >
      {filter}
    </Modal>
  </>
);

export default ListingLayout;
