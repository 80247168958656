import { TNotVisibleFields } from "utils/onErrorMutation";

export const notVisibleFieldsParticipant: TNotVisibleFields = [
  {
    name: "registration_number",
    title: "Регистрационный номер, либо его аналог"
  },
  {
    name: "registration_country_code",
    title: "Код страны (ОКСМ)"
  },
  {
    name: "mobile_phone",
    title: "Номер телефона"
  },
  {
    name: "epay_number",
    title: "Номер электронного средства платежа"
  },
  {
    name: "registration_country_code",
    title: "Код страны (ОКСМ)"
  }
];
