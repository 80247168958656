import Loader from "react-loaders";
import OrdAccountCheckIcon from "ui/OrdAccountCheckIcon";
import TParticipantDisplay from "./type";

const ParticipantCell = ({ id, name, inn, ord, isLoading, customText }: TParticipantDisplay) => {
  const infoBlock = (
    <>
      {name ? (
        <span
          style={
            name.length > 50
              ? { minWidth: "50ch", display: "inline-block" }
              : { whiteSpace: "nowrap" }
          }
        >
          {name}
        </span>
      ) : null}
      {inn ? <br /> : null}
      {inn ? <span style={{ whiteSpace: "nowrap" }}>ИНН: {inn}</span> : null}
    </>
  );

  if (isLoading) return <Loader type="ball-beat" active innerClassName="loader-for-table" />;

  if (ord && ord.length)
    return (
      <td>
        <div className="d-flex align-items-start">
          <div>{infoBlock}</div>
          <OrdAccountCheckIcon
            ord={ord.map(({ title }) => title)}
            id={id}
            customText={customText}
          />
        </div>
      </td>
    );

  return <td>{infoBlock}</td>;
};

export default ParticipantCell;
