import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StateProps } from "reducers";
import { setCloseModalClose, setCloseModalNotNeeded } from "reducers/CloseModalSettings";

const CloseModal = () => {
  const navigate = useNavigate();

  const isOpen = useSelector((state: StateProps) => state.CloseModalSettings.isOpen);
  const destination = useSelector((state: StateProps) => state.CloseModalSettings.destination);

  const dispatch = useDispatch();

  const onClose = () => dispatch(setCloseModalClose());
  const setNotNeeded = () => dispatch(setCloseModalNotNeeded());

  const backToListing = () => {
    if (destination) navigate(destination);
    onClose();
    setNotNeeded();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered className="p-0 modal-shadow-none">
      <ModalHeader>
        <div className="d-flex align-items-center gap-3">
          <FaInfoCircle size={24} />
          <span className="fw-bold">Предупреждение</span>
        </div>
      </ModalHeader>
      <ModalBody>
        Внесенные изменения не будут сохранены. Вы уверены, что хотите закрыть карточку элемента без
        сохранения данных?
      </ModalBody>
      <ModalFooter className="justify-content-center">
        {backToListing ? (
          <Button outline color="primary" onClick={backToListing}>
            Закрыть без сохранения
          </Button>
        ) : (
          <div />
        )}
        <Button color="primary" onClick={onClose}>
          Продолжить редактирование
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CloseModal;
