import { getParticipantList } from "api/participant_list";
import { InvoiceFilterDV } from "constants/defaultValues/InvoiceFilterDV";
import {
  combinedStatusOptions,
  onlyErirOptions
} from "constants/selectOptions/CombinedStatusOptions";
import ParticipantClientRolesOptions from "constants/selectOptions/ParticipantClientRolesOptions";
import ParticipantContractorRolesOptions from "constants/selectOptions/ParticipantContractorRolesOptions";
import FilterLayout from "Layout/FilterLayout";
import { FormEvent, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TStatisticsFilter } from "types/TStatisticsFilter";
import { PredictiveSearchField, selectColorSchemes, SelectField } from "ui";
import useOrdAccountsList from "utils/useOrdAccountsList";

type Props = {
  filter: TStatisticsFilter;
  onFilterClick: (_: TInvoiceFilter) => void;
  onClose: () => void;
};

export const StatisticsFilter = ({ filter, onFilterClick, onClose }: Props) => {
  const [searchClientValue, setSearchClientValue] = useState<string>();
  const [searchContractorValue, setSearchContractorValue] = useState<string>();

  const {
    options: ordAccountsTitlesOptions,
    isLoading: ordAccountsLoading,
    isError: ordAccountsError
  } = useOrdAccountsList();

  const [statusTitlesOptions, setStatusTitlesOptions] = useState(
    filter.ord_account?.length ? onlyErirOptions : combinedStatusOptions
  );

  const { control, watch, reset, setValue } = useForm({
    defaultValues: filter
  });

  const {
    mutate: clientsRequest,
    isLoading: clientsLoading,
    data: clientsResponse
  } = useMutation({
    mutationFn: () => {
      const client = watch("client");
      return getParticipantList(
        searchClientValue
          ? { search: searchClientValue }
          : { filter: client ? { id: [client] } : {} }
      );
    }
  });

  const {
    mutate: contractorsRequest,
    isLoading: contractorsLoading,
    data: contractorsResponse
  } = useMutation({
    mutationFn: () => {
      const contractor = watch("contractor");
      return getParticipantList(
        searchContractorValue
          ? { search: searchContractorValue }
          : { filter: contractor ? { id: [contractor] } : {} }
      );
    }
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onFilterClick(watch());
  };

  const onReset = () => {
    reset(InvoiceFilterDV);
    onFilterClick(InvoiceFilterDV);
  };

  return (
    <FilterLayout onSubmit={onSubmit} onReset={onReset} onClose={onClose}>
      <SelectField
        name="ord_account"
        control={control as unknown as Control<FieldValues, any>}
        title="Аккаунт в ОРД"
        isMulti
        options={ordAccountsTitlesOptions}
        disabled={ordAccountsLoading || ordAccountsError}
        onChange={(newVal) => {
          if (newVal && (newVal as (string | number)[]).length) {
            setValue(
              "combined_status",
              watch("combined_status")?.filter((st) => st === 4 || st === 5 || st === 6)
            );
            setStatusTitlesOptions(onlyErirOptions);
          } else {
            setStatusTitlesOptions(combinedStatusOptions);
          }
        }}
      />
      <SelectField
        name="combined_status"
        control={control as unknown as Control<FieldValues, any>}
        title="Статус"
        isMulti
        options={statusTitlesOptions}
        colorSchemes={{
          4: selectColorSchemes.yellow,
          5: selectColorSchemes.green,
          6: selectColorSchemes.red
        }}
      />
      <SelectField
        name="client_role"
        control={control as unknown as Control<FieldValues, any>}
        title="Выбрать роль заказчика"
        options={ParticipantClientRolesOptions}
        isMulti
        hideArrow
      />
      <SelectField
        name="contractor_role"
        control={control as unknown as Control<FieldValues, any>}
        title="Выбрать роль исполнителя"
        options={ParticipantContractorRolesOptions}
        isMulti
        hideArrow
      />
      <PredictiveSearchField
        name="client"
        title="Заказчик"
        optionKey={["name", "inn"]}
        optionKeyLabels={["Название", "ИНН"]}
        inputValue={searchClientValue}
        setInputValue={setSearchClientValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={clientsLoading}
        request={clientsRequest}
        response={clientsResponse}
        clearable
      />
      <PredictiveSearchField
        name="contractor"
        title="Исполнитель"
        optionKey={["name", "inn"]}
        optionKeyLabels={["Название", "ИНН"]}
        inputValue={searchContractorValue}
        setInputValue={setSearchContractorValue}
        control={control as unknown as Control<FieldValues, any>}
        isLoading={contractorsLoading}
        request={contractorsRequest}
        response={contractorsResponse}
        clearable
      />
    </FilterLayout>
  );
};
