import { editPlatform } from "api/platform_edit";
import { AxiosError } from "axios";
import { ID } from "models";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { notVisibleFieldsPlatform } from "../constants";

const useEditPlatform = (
  id: ID,
  platformLastSearch: string,
  platformName: string | undefined,
  setError: UseFormSetError<FieldValues>
) => {
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: editPlatform,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/platform/${platformLastSearch}`,
        navigate,
        false,
        `Платформа ${platformName || `[${id}]`} изменена`
      ),
    onError: (p) => onErrorMutation(p as AxiosError, setError, notVisibleFieldsPlatform)
  });
  return mutate;
};
export default useEditPlatform;
