import { Row } from "reactstrap";
import Contacts from "./components/Contacts";
import KnowledgeBase from "./components/KnowledgeBase";
import SupportService from "./components/SupportService";
import TGChanel from "./components/TGChanel";

const Help = () => {
  return (
    <Row className="g-2">
      <KnowledgeBase />
      <Contacts />
      <SupportService />
      <TGChanel />
    </Row>
  );
};

export default Help;
