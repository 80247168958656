import { FaChevronRight } from "react-icons/fa";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import ImportModal from "ui/ImportModal";
import useIsOpenControl from "utils/useIsOpenControl";

type Props = {
  onExport: () => void;
  exportLink: string;
  refetch: () => Promise<any>;
};

export const PlatformListingActions = ({ onExport, exportLink, refetch }: Props) => {
  const { isOpen: exportDropdownOpen, toggle: exportDropdownToggle } = useIsOpenControl();
  const { isOpen: importModalOpen, toggle: toggleImportModal } = useIsOpenControl();

  return (
    <>
      <DropdownMenu>
        <Dropdown direction="end" isOpen={exportDropdownOpen} toggle={exportDropdownToggle}>
          <DropdownToggle
            onClick={onExport}
            tag="button"
            type="button"
            className="dropdown-item d-flex justify-content-between align-items-center"
          >
            <span>Экспорт</span>
            <FaChevronRight />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" href={exportLink || ""}>
              Скачать
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <DropdownItem tag="button" onClick={toggleImportModal}>
          Импорт
        </DropdownItem>
      </DropdownMenu>
      <ImportModal
        isOpen={importModalOpen}
        toggle={toggleImportModal}
        exampleLink="/static/core/import_templates/platform.xlsx"
        exampleTitle="Шаблон импорта площадок"
        importVariant="platform"
        refetch={refetch}
      />
    </>
  );
};
