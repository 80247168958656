import { deleteContract } from "api/contract_delete";
import { ID } from "models";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onDeleteMutation from "utils/onDeleteMutation";

const useDeleteContract = (
  id: ID,
  contractLastSearch: string,
  contract_number: string | undefined
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: deleteContract,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        href: `/contract/${contractLastSearch}`,
        navigate,
        successMessage: `Договор ${
          contract_number && contract_number !== "" ? `№${contract_number}` : `[${id}]`
        } удален`
      })
  });

  return mutate;
};

export default useDeleteContract;
