import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "reducers";
import { setEnableClosedSidebar, setEnableMobileMenu } from "reducers/ThemeOptions";
import BurgerButton from "ui/BurgerButton";

const HeaderLogo = () => {
  const dispatch = useDispatch();

  const enableClosedSidebar = useSelector(
    (state: StateProps) => state.ThemeOptions.enableClosedSidebar
  );
  const enableMobileMenu = useSelector((state: StateProps) => state.ThemeOptions.enableMobileMenu);

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar));
  };

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  return (
    <>
      <div className="app-header__logo">
        <div className="logo-src" />
        <div className="header__pane ms-auto">
          <BurgerButton onClick={toggleEnableClosedSidebar} isClosed={!enableClosedSidebar} />
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <BurgerButton onClick={toggleMobileSidebar} isClosed={enableMobileMenu} />
      </div>
    </>
  );
};

export default HeaderLogo;
