import country_code from "dictionaries/country_code.json";
import { CountryCode } from "models";
import createOptions from "utils/createOptions";

export default createOptions(
  (country_code.country_codes as CountryCode[]).reduce(
    (acc, country) => {
      acc[country.country_code] = `${country.full_country_name || country.country_name} (${
        country.country_code
      })`;
      return acc;
    },
    {} as { [key: string]: string }
  ),
  undefined,
  true
);
