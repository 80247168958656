import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import getTextShortened from "utils/getTextShortened";
import DescriptionField from "./DescriptionField";

type Props = {
  name: string;
  watch?: UseFormWatch<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  descriptionFromOutside?: string;
  isNotDraftCreative?: boolean;
  description?: string;
  extraAction?: () => void;
};

const DescriptionCell = ({
  descriptionFromOutside,
  isNotDraftCreative,
  description,
  name,
  watch,
  setValue,
  extraAction
}: Props) => {
  if (descriptionFromOutside) return <span>{getTextShortened(descriptionFromOutside, 50)}</span>;

  if (isNotDraftCreative) return <span>{getTextShortened(description, 50)}</span>;

  return (
    <DescriptionField name={name} watch={watch} setValue={setValue} extraAction={extraAction} />
  );
};

export default DescriptionCell;
