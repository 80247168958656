import ChangeContractModal from "Components/ChangeContractModal";
import { TPecularitiesValue } from "Components/ChangeGroupModal";
import { ErrorMessage } from "Components/ErrorMessage";
import { getCreativeGroupNewItem } from "api/creative_group_new_item";
import { patchCreativeGroupNew } from "api/creative_group_new_patch";
import { APIData } from "api/types";
import { AxiosError } from "axios";
import { ID, NEW_CreativeGroup } from "models";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StateProps } from "reducers";
import IconButton from "ui/IconButton";
import createNotification from "ui/Notification";
import useIsOpenControl from "utils/useIsOpenControl";
import ContractNumber, { TContractInfo } from "./components/contractNumber";
import CreativesModal from "./components/creativesModal";
import CreativeGroupForm from "./components/form";
import { onErrorEdit } from "./helpers";

type Props = {
  id: ID;
};

export interface IDefaultValuesCreativeGroupEditor {
  label: string;
  advertisement_type?: number;
  advertisement_form?: number;
  start_date?: string;
  end_date?: string;
  peculiarities?: TPecularitiesValue;
  eacs?: number[];
  target_audience?: string;
  description?: string;
  initial_contract?: ID;
  final_contract?: ID;
  final_contract_client_legal_form?: number;
}

export interface InitialParamsProps {
  client?: {
    id?: ID;
    name?: string;
  };
  finalClient?: {
    id?: ID;
    name?: string;
  };
  finalContractor?: {
    id?: ID;
    name?: string;
  };
  contractNumber?: string;
  finalContractNumber?: string;
}

const CreativeGroupEditForm = ({ id }: Props) => {
  const creativeLastSearch = useSelector((state: StateProps) => state.LastSearchParams.creative);
  // const [isDraft, setIsDraft] = useState(false);
  const navigate = useNavigate();
  const [contractInfo, setContractInfo] = useState<TContractInfo | undefined>();
  const [isContractEditable, setIsContractEditable] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    defaultValues: {} as IDefaultValuesCreativeGroupEditor
  });

  const { isOpen: isChangeContractModalOpen, toggle: toggleChangeContractModal } =
    useIsOpenControl();
  const { isOpen: isCreativesModalOpen, toggle: toggleCreativesModal } = useIsOpenControl();

  const backToListing = () => navigate(`/creative/${creativeLastSearch}`);

  const { mutate: edit } = useMutation({
    mutationFn: patchCreativeGroupNew,
    onSuccess: () => {
      createNotification(
        "success",
        <>
          <p className="fw-bold">Группа креативов успешно изменена</p>
          <p>
            Креативы со&nbsp;статусами &quot;Активный&quot;, &quot;Ошибка регистрации&quot; или
            &quot;Зарегистрировано в&nbsp;ЕРИР&quot; отправляются автоматически на&nbsp;регистрацию
            в&nbsp;ЕРИР.
          </p>
          <p>
            Для отправки креативов в&nbsp;статусе &quot;Черновик&quot; необходимо выполнить это
            действие вручную из&nbsp;карточки креатива.
          </p>
        </>
      );
      backToListing();
    },
    onError: (err: AxiosError) => onErrorEdit(err, setError)
  });

  const {
    isLoading,
    error,
    data: creativeGroup
  } = useQuery<APIData<NEW_CreativeGroup>, Error>({
    queryKey: ["cg_item", id],
    queryFn: () => getCreativeGroupNewItem(id)
  });

  useEffect(() => {
    if (!isLoading && !error && creativeGroup?.status && creativeGroup.data) {
      reset({
        label: creativeGroup.data.label,
        advertisement_type: creativeGroup.data.advertisement_type,
        advertisement_form: creativeGroup.data.advertisement_form,
        start_date: creativeGroup.data.start_date,
        end_date: creativeGroup.data.end_date,
        eacs: creativeGroup.data.eacs,
        target_audience: creativeGroup.data.target_audience,
        description: creativeGroup.data.description,
        initial_contract: creativeGroup.data.initial_contract,
        final_contract: creativeGroup.data.final_contract,
        peculiarities: creativeGroup.data.is_native
          ? "is_native"
          : creativeGroup.data.is_social
            ? "is_social"
            : undefined,
        final_contract_client_legal_form:
          creativeGroup.data.extra_fields?.final_contract?.client?.legal_form
      });

      const initial_contract = creativeGroup?.data.extra_fields?.initial_contract;
      if (initial_contract?.id) {
        setContractInfo({
          id: initial_contract.id,
          number: initial_contract.number,
          clientId: initial_contract.client?.id,
          clientName: initial_contract.client?.name,
          contractorId: initial_contract.contractor?.id,
          contractorName: initial_contract.contractor?.name
        });
      }

      const creatives = creativeGroup.data.extra_fields?.creatives;
      if (creatives && creatives.length) {
        let isAnyCreativeSync = false;
        let i = 0;
        do {
          isAnyCreativeSync = creatives[i].is_statistics_sent || false;
          i++;
        } while (!isAnyCreativeSync && i < creatives.length);
        setIsContractEditable(!isAnyCreativeSync);
      }
    }
  }, [creativeGroup, error, isLoading, reset]);

  const editCreativeGroupFunction = () => {
    const is_native =
      watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_native";
    const is_social =
      watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_social";

    const result = {
      ...watch(),
      is_native,
      is_social
    };

    edit({ id, group: result });
  };

  const onSubmit = handleSubmit(editCreativeGroupFunction, (err) => {
    console.log(err);
  });

  if (error) return <ErrorMessage error={error.message} isEmbedded />;

  return (
    <div>
      <div className="row align-items-center mb-5">
        <h1 className="col-sm-9">
          Редактирование группы креативов {creativeGroup?.data.label || creativeGroup?.data.id}
        </h1>
        {creativeGroup ? (
          <div className="col-sm-3">
            <p className="form-label">
              Креативов в группе:{" "}
              {creativeGroup.data.extra_fields?.creatives
                ? creativeGroup.data.extra_fields?.creatives.length
                : ""}
            </p>
            <IconButton
              outline
              isFullWidth
              text="Посмотреть"
              icon={<FaMagnifyingGlass size={14} />}
              onClick={toggleCreativesModal}
            />
          </div>
        ) : null}
      </div>

      <ContractNumber
        contract={contractInfo}
        toggle={toggleChangeContractModal}
        isEditable={isContractEditable}
      />

      <CreativeGroupForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        watch={watch}
        creativeLoading={isLoading}
        backToListing={backToListing}
      />

      <CreativesModal
        isOpen={isCreativesModalOpen}
        toggle={toggleCreativesModal}
        creatives={creativeGroup?.data.extra_fields?.creatives}
        label={creativeGroup?.data.label || creativeGroup?.data.id}
      />

      <ChangeContractModal
        isOpen={isChangeContractModalOpen}
        toggle={toggleChangeContractModal}
        groupId={id}
        onSubmit={(
          { initial_contract, final_contract },
          newInitialContractInfo,
          newFinalContractInfo
        ) => {
          setValue("initial_contract", initial_contract);
          setValue("final_contract", final_contract);
          if (newInitialContractInfo && newInitialContractInfo.id) {
            setContractInfo({
              id: newInitialContractInfo.id,
              number: newInitialContractInfo.contract_number,
              clientId: newInitialContractInfo.client,
              clientName: newInitialContractInfo.extra_fields?.client.name,
              contractorId: newInitialContractInfo.contractor,
              contractorName: newInitialContractInfo.extra_fields?.contractor.name
            });
          }
          setValue(
            "final_contract_client_legal_form",
            newFinalContractInfo?.extra_fields?.client.legal_form
          );
          toggleChangeContractModal();
        }}
      />
    </div>
  );
};

export default CreativeGroupEditForm;
