export const SET_CLOSE_MODAL_IS_OPEN = "CLOSE_MODAL_IS_OPEN";
export const SET_CLOSE_MODAL_IS_NEEDED = "CLOSE_MODAL_IS_NEEDED";
export const SET_CLOSE_MODAL_DESTINATION = "CLOSE_MODAL_DESTINATION";
export const SET_CLOSE_MODAL_BODY = "CLOSE_MODAL_BODY";

export interface CloseModalProps {
  destination?: string;
  isOpen?: boolean;
  body?: string;
  isNeeded?: boolean;
}

interface ActionProps extends CloseModalProps {
  type: string;
}

const defaultValue: CloseModalProps = {
  destination: undefined,
  isOpen: false,
  body: "Внесенные изменения не будут сохранены. Вы уверены, что хотите закрыть карточку элемента без сохранения данных?",
  isNeeded: false
};

export const setCloseModalOpen = () => ({
  type: SET_CLOSE_MODAL_IS_OPEN,
  isOpen: true
});

export const setCloseModalClose = () => ({
  type: SET_CLOSE_MODAL_IS_OPEN,
  isOpen: false
});

export const setCloseModalNeeded = () => ({
  type: SET_CLOSE_MODAL_IS_NEEDED,
  isNeeded: true
});

export const setCloseModalNotNeeded = () => ({
  type: SET_CLOSE_MODAL_IS_NEEDED,
  isNeeded: false
});

export const setCloseModalDestination = (destination: CloseModalProps["destination"]) => ({
  type: SET_CLOSE_MODAL_DESTINATION,
  destination
});

export const setCloseModalBody = (body: CloseModalProps["body"]) => ({
  type: SET_CLOSE_MODAL_BODY,
  body
});

export default function reducer(state = defaultValue, action: ActionProps) {
  switch (action.type) {
    case SET_CLOSE_MODAL_IS_OPEN:
      return {
        ...state,
        isOpen: action.isOpen
      };

    case SET_CLOSE_MODAL_IS_NEEDED:
      return {
        ...state,
        isNeeded: action.isNeeded
      };

    case SET_CLOSE_MODAL_DESTINATION:
      return {
        ...state,
        destination: action.destination
      };

    case SET_CLOSE_MODAL_BODY:
      return {
        ...state,
        body: action.body
      };

    default:
  }
  return state;
}
