import { TContractFilter } from "types/TContractFilter";
import { TSearchParamsRequired } from "types/TSearchParams";
import { OrderingVariant } from "ui/SortSelector";

export const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "contract_number",
    title: "№ договора ↑"
  },
  {
    order: "-contract_number",
    title: "№ договора ↓"
  },
  {
    order: "contract_date",
    title: "Дата договора ↑"
  },
  {
    order: "-contract_date",
    title: "Дата договора ↓"
  }
];

export const defaultSearchParams: TSearchParamsRequired<TContractFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: orderVariants[1].order,
  filter: {
    contract_type: [],
    ord_account: [],
    combined_status: [],
    client_role: [],
    contractor_role: [],
    client: [],
    contractor: [],
    participant: undefined
  }
};

export const defaultExportLink = "/api/v1/contract/export/xls";
