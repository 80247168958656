import EditorFooter from "Layout/EditorFooter";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import TFormAddPossibleStatuses from "types/TFormAddPossibleStatuses";
import { scrollToError } from "utils/scrollToError";
import { PlatformForm } from "./components/form";
import useAddPlatform from "./hooks/useAddPlatform";

const PlatformAddForm = () => {
  const [status, setStatus] = useState<TFormAddPossibleStatuses>("ACTIVE");
  const platformLastSearch = useSelector((state: StateProps) => state.LastSearchParams.platform);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    setError
  } = useForm();

  const add = useAddPlatform(platformLastSearch, watch, setError);

  const onSubmit = handleSubmit(
    () => {
      add({
        body: {
          platform_url: watch("no_url") ? "" : (watch("platform_url") as string),
          platform_type: watch("platform_type") as number,
          platform_name: watch("platform_name") as string,
          is_owner: watch("is_owner") as boolean,
          owner: (watch("owner") as number) ?? undefined,
          status: status === "DRAFT" ? 2 : 1
        }
      });
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  return (
    <div>
      <h1>Добавление платформы</h1>

      <PlatformForm
        setValue={setValue}
        control={control}
        watch={watch}
        errors={errors}
        buttons={
          <EditorFooter
            left={
              <Button type="submit" color="primary" outline onClick={() => setStatus("DRAFT")}>
                Сохранить черновик
              </Button>
            }
            right={
              <>
                <Button href={`#/platform/${platformLastSearch}`} tag="a" color="primary" outline>
                  Отменить
                </Button>
                <Button type="submit" color="primary" onClick={() => setStatus("ACTIVE")}>
                  Добавить
                </Button>
              </>
            }
          />
        }
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default PlatformAddForm;
