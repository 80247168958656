import cn from "classnames";
import { VscClose, VscMenu } from "react-icons/vsc";

const BurgerButton = ({ isClosed, onClick }: { isClosed: boolean; onClick: () => void }) => {
  return (
    <button onClick={onClick} className="burger-button">
      <VscMenu
        size={28}
        style={{ top: 14, left: 14 }}
        className={cn("burger-button__icon", { "burger-button__icon_hidden": isClosed })}
      />
      <VscClose
        size={36}
        style={{ top: 10, left: 10 }}
        className={cn("burger-button__icon", { "burger-button__icon_hidden": !isClosed })}
      />
    </button>
  );
};

export default BurgerButton;
