import ReactPaginate from "react-paginate";

type Props = {
  currentPage: number;
  lastPageNumber: number;
  setCurrentPage: (_: number) => void;
};

export const Pagination = ({ currentPage, lastPageNumber, setCurrentPage }: Props) => {
  return (
    <ReactPaginate
      previousLabel="←"
      nextLabel="→"
      pageCount={lastPageNumber || 1}
      onPageChange={(selectedItem: { selected: number }) => setCurrentPage(selectedItem.selected)}
      containerClassName="pagination mb-0"
      disabledClassName="page-item disabled"
      activeClassName="page-item active"
      forcePage={currentPage}
    />
  );
};
