import isArray from "lodash/isArray";
import { components, MultiValueGenericProps } from "react-select";

const MultiFieldMultiValueLabel = (
  { ...props }: MultiValueGenericProps<any>,
  optionKey: string | string[],
  optionKeyLabels?: string[]
) => {
  const parsed = JSON.parse(props.data.label);
  return (
    <components.MultiValueLabel {...props}>
      <div className="py-1 px-2">
        {isArray(optionKey) &&
          optionKey.map((op, index) => {
            const label = optionKeyLabels?.[index];

            if (optionKeyLabels && label === "hidden") return null;

            if (optionKeyLabels && label?.includes("(link)"))
              return (
                <p key={op} className="mb-0">
                  <span className="text-secondary">{label.replace("(link)", "")}: </span>
                  <a href={`${op}/${props.data.value}`} target="_blank" rel="noreferrer">
                    {props.data.value}
                  </a>
                </p>
              );

            return (
              <p key={op} className="mb-0">
                {optionKeyLabels ? <span className="text-secondary">{label}: </span> : null}
                <span>{parsed[op]}</span>
              </p>
            );
          })}
      </div>
    </components.MultiValueLabel>
  );
};

export default MultiFieldMultiValueLabel;
